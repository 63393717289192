import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        dark: '#4d4d4d',
        accent: '#6f3d97',
        purple: '#6f3d97',
        skyblue: '#00b0f0',
        primary: '#00b0f0',
        success: '#8bdf55',
        secondary: '#132D3D',
        anchor: 'rgba(40,63,151,0.5)',
      },
    },
    options: { customProperties: true },
  },
});

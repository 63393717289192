import Vue from 'vue'
import moment from 'moment'
import db from '@/firebase/init'
import { pick } from 'lodash'
import { projectIndex } from '@/algolia/init'
import firebase from 'firebase'


/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  hits: [],
  users: [],
  projects: [],
  templates: [],
  collaborator: false,
  data: {
    folder: 'root'
  },
  folders: [{
    name: 'Root Folder',
    id: 'root',
    owner: null,
  }],
  status: {
    error: null,
    getting: false,
    creating: false,
    firstLoad: false,
    displayRange: 60,
    searching: false,
    publishing: false,
    deletingTemplate: false,
    firstTemplateLoad: false,
  }
}


/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
 const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  /*------------------------------------------------------------------------------
    * PROJECTS
    ----------------------------------------------------------------------------*/
  setProjects(state, payload) {
    state.projects = []

    if (payload.size) {
      payload.forEach(project => {
        let data = project.data()
        data.id = project.id
        data.ref = project.ref
        state.projects.push(data)
      })

      state.status.getting = false
      state.status.firstLoad = true
    }

  },

  /* -------------------------------------------------------------------------- */
  /*             CHANGE USER DROPDOWN LIST TO COLLABORATION - MODAL             */
  /* -------------------------------------------------------------------------- */
  setDialogUserList(state, bol) {
    state.collaborator = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  searchingState(state, bol) {
    state.status.searching = bol
  },

  clearHits(state) {
    state.hits = []
  },

  insertHit(state, payload) {
    let hit = Vue.prototype.$formatData(payload)
    state.hits.push(hit)
  },

  removeProject(state, project) {
    let proj = state.projects.find(p => p.id == project.id)
    state.projects.splice(state.projects.indexOf(proj), 1)
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setTemplates(state, payload) {
    state.templates = []
    
    if (payload.size) {
      payload.forEach(doc => {
        let template = doc.data()
        template.id = doc.id
        template.ref = doc.ref
        state.templates.push(template)
      })
    }

    state.status.getting = false
  },

  removeTemplate(state, payload) {
    let template = state.templates.find(t => t.id == payload.id)
    state.templates.splice(state.templates.indexOf(template), 1)
    state.status.deletingTemplate = false
  },

  addToTemplate(state, payload) {
    if (!state.templates.find(t => t.id == payload.id)) {
      state.templates.push(payload)
    }
  },

  updateTemplate(state, payload) {
    let template = state.templates.find(t => t.id == payload.id)
    Vue.set(state.templates, state.templates.indexOf(template), payload)
  },

  publishStatus(state, template) {
    template.published = true
  },
  
  unpublishStatus(state, template) {
    template.published = false
  },

  addTemplate(state, payload) {
    if (state.templates.find(t => t.id == payload.id)) {
      state.templates.push(payload)
    }
  }
 }

 /*------------------------------------------------------------------------------
  * ACTIONS
  *----------------------------------------------------------------------------*/
 const actions = {

   /*------------------------------------------------------------------------------
    * GET PROJECTS
    *----------------------------------------------------------------------------*/
   getProjects({ commit, dispatch }) {
     commit('gettingState', true)

     let daysAgo = moment().subtract(parseInt(state.status.displayRange), 'days')
     daysAgo = moment(daysAgo).valueOf()

     let projects = db.collection('projects')

     if (state.status.displayRange) {
       projects = projects.where('createdAt', '>=', daysAgo)
     }

     projects
     .where('template', 'in', [false])
     .orderBy('createdAt', 'desc')
     .get()
     .then(snapshot => {
       if (snapshot.size) {
         commit('setProjects', snapshot)
       }
       else {
         commit('setProjects', [])
         commit('gettingState', false)
       }
     })
     .catch(error => {
       console.log(error.message)
       dispatch('showError', error.message, { root: true })
     })
   },

   /*------------------------------------------------------------------------------
    * SEARCH PROJECT
    *----------------------------------------------------------------------------*/
  searchProject({ commit, dispatch }, keyword) {
    commit('searchingState', true)

    projectIndex
      .search(keyword)
      .then(({ hits }) => {
        if (hits.length) {
          let counter = 0

          hits.forEach(hit => {
            db.collection('projects')
              .doc(hit.objectID)
              .get()
              .then(doc => {
                if (doc.exists) {
                  commit('insertHit', doc)
                }
  
                counter++
  
                if (counter >= hits.length) {
                  commit('searchingState', false)
                }
              })
          })
        }
        else {
          dispatch('showError', 'No project found.', { root: true })
          commit('searchingState', false)
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('searchingState', false)
      })
  },
 
  /*------------------------------------------------------------------------------
   * GET PROJECT TEMPLATES
   *----------------------------------------------------------------------------*/
  getProjectTemplates({ commit, dispatch }, published = false) {
    commit('updateStatus', { getting: true })
    if (!published) commit('updateStatus', { firstTemplateLoad: true })
    
    let task = db.collection('projects')
    .where('template', '==', true)

    if (published) task = task.where('published', '==', true)

    task.get()
    .then((snapshot) => {
      commit('setTemplates', snapshot)
    })
    .catch((error) => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE TEMPLATE
   * 
   * @PARAMS 
   * $title
   *----------------------------------------------------------------------------*/
  async deleteTemplate({ commit, dispatch }, template) {
    commit('updateStatus', { deletingTemplate: true })
    
    await template.ref.delete()
    .then(() => {
      commit('removeTemplate', template)
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deletingTemplate: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE TEMPLATE
   *----------------------------------------------------------------------------*/
  async updateTemplate({ commit, dispatch }, template) {
    commit('projects/creatingState', true, { root: true })
    
    await template.ref
    .update(pick(template, ['name', 'category']))
    .then(() => {
      dispatch('showSuccess', 'Template updated', { root: true })
      commit('projects/creatingState', false, { root: true })
      commit('updateTemplate', template)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('projects/creatingState', false, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * PUBLISH TEMPLATE
   *----------------------------------------------------------------------------*/
  publishTemplate({ commit, dispatch }, template) {
    commit('updateStatus', { publishing: true })
    
    template.ref
    .update({ published: true })
    .then(() => {
      commit('publishStatus', template)
      commit('updateStatus', { publishing: false })
      dispatch('showSuccess', `Project template ${template.name} published.`, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { publishing: false })
      dispatch('showError', error.message, { root: true })
    })
  },
  
  /*------------------------------------------------------------------------------
   * UNPUBLISH TEMPLATE
   *----------------------------------------------------------------------------*/
  unpublishTemplate({ commit, dispatch }, template) {
    commit('updateStatus', { publishing: true })
    
    template.ref
    .update({ published: false })
    .then(() => {
      commit('unpublishStatus', template)
      commit('updateStatus', { publishing: false })
      dispatch('showSuccess', `Project template ${template.name} unpublished.`, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { publishing: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE PROJECT FROM TEMPLATE
   *----------------------------------------------------------------------------*/
  async createFromTemplate({ commit, rootState }, template) {
    commit('updateStatus', { creating: true })
    var project = rootState.project.project
    var batch = db.batch()

    // DELETE EXISTING PAGES
    await project.ref
    .collection('pages')
    .get()
    .then(async (snapshot) => {
      if (snapshot.size) {
        for (let doc of snapshot.docs) {
          await doc.ref.delete()

          await doc.ref
          .collection('sections')
          .get()
          .then(async (snapshot) => {
            if (snapshot.size){
              for (let section of snapshot.docs) {
                await section.ref.delete()
              }
            }
          })
        }
      }
    })
    .catch(error => {
      console.log(error.message)
    })
    
    // DELETE EXISTING GLOBALS
    await project.ref
    .collection('globals')
    .get()
    .then(async (snapshot) => {
      if (snapshot.size) {
        for (let doc of snapshot.docs) {
          await doc.ref.delete()
        }
      }
    })
    .catch(error => {
      console.log(error.message)
    })

    // COPY GLOBAL SECTIONS
    await template.ref
    .collection('globals')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        for (let doc of snapshot.docs) {
          let global = project.ref.collection('globals').doc()
          batch.set(global, doc.data())
        }
      }
    })
    .catch(error => {
      console.log(error.message)
    })

    // COPY PAGES
    await template.ref
    .collection('pages')
    .get()
    .then(async (snapshot) => {
      if (snapshot.size) {
        for (let doc of snapshot.docs) {
          let page = project.ref.collection('pages').doc()
          batch.set(page, doc.data())
  
          // COPY SECTIONS
          await doc.ref
          .collection('sections')
          .get()
          .then(sectionSnapshot => {
            if (sectionSnapshot.size) {
              for (let sectionDoc of sectionSnapshot.docs) {
                let section = page.collection('sections').doc()
                batch.set(section, sectionDoc.data())
              }
            }
          })
        }
      }
    })
    .catch(error => {
      console.log(error.message)
    })

    await batch.commit()
    .then(() => {
      commit('updateStatus', { creating: false })
    })
  },

  /*------------------------------------------------------------------------------
   * SAVE PROJECT AS TEMPLATE
   *----------------------------------------------------------------------------*/
  async saveProjectAsTemplate({ commit, dispatch, rootState }) {
    commit('projects/creatingState', true, { root: true })
    let user = firebase.auth().currentUser
    let project = rootState.project.project
    let batch = db.batch()
    let template = rootState.projects.data

    template.owner = user.uid
    template.createdAt = Date.now()
    template.template = true
    template.published = false

    await db.collection('projects')
    .add(template)
    .then((docRef) => {
      template.ref = docRef
      template.id = docRef.id
    })
    .catch(error => {
      console.log(error.message)
    })

     // COPY GLOBAL SECTIONS
     await project.ref
     .collection('globals')
     .get()
     .then(snapshot => {
       if (snapshot.size) {
         for (let doc of snapshot.docs) {
           let global = template.ref.collection('globals').doc()
           batch.set(global, doc.data())
         }
       }
     })
     .catch(error => {
       console.log(error.message)
     })
 
     // COPY PAGES
     await project.ref
     .collection('pages')
     .get()
     .then(async (snapshot) => {
       if (snapshot.size) {
         for (let doc of snapshot.docs) {
           let page = template.ref.collection('pages').doc()
           batch.set(page, doc.data())
   
           // COPY SECTIONS
           await doc.ref
           .collection('sections')
           .get()
           .then(sectionSnapshot => {
             if (sectionSnapshot.size) {
               for (let sectionDoc of sectionSnapshot.docs) {
                 let section = page.collection('sections').doc()
                 batch.set(section, sectionDoc.data())
               }
             }
           })
         }
       }
     })
     .catch(error => {
       console.log(error.message)
     })
 
     await batch.commit()
     .then(() => {
        commit('updateStatus', { creating: false })
        commit('addTemplate', template)
        commit('projects/creatingState', false, { root: true })
        dispatch('showSuccess', 'Project copied as template', { root: true })
     })
  }
 }

 export default {
   namespaced: true,
   state,
   mutations,
   actions
 }

<template>
  <v-dialog
    :value="show"
    @keydown.esc="closeDialog()"
    no-click-animation
    scrollable
    fullscreen 
    persistent
  >
    <v-card>
      <v-card-title >
        <h5 class="font-weight-bold text-h5">
          <v-btn @click="closeDialog()" class="mr-1" fab depressed small>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          
          Select A Panel
        </h5>

        <close-btn @click="closeDialog()" depressed />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-space-between pa-0">
          <v-sheet 
            style="overflow-y: scroll; border-right: 1px solid #ddd !important;"
            width="300"
          >
            <v-card 
              class="fill-height"
              flat
            >
              <v-card-text>
                <v-text-field
                  v-model="searchName"
                  placeholder="Search"
                  append-icon="mdi-layers-search-outline"
                  hide-details
                  class="mb-4 box-shadow"
                  clearable
                  outlined
                  rounded
                  dense
                  light
                ></v-text-field>

                <div class="d-none align-center">
                  <label>Column Count</label>
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="allColumns"
                    @change="toggleAllColumns($event)"
                    label="All"
                    hide-details
                    dense
                  ></v-checkbox>
                </div>
                <!-- <div class="d-flex d-none flex-wrap">
                  <v-checkbox
                    v-for="count in 8"
                    :key="count"
                    :label="`${count}`"
                    :value="count"
                    @change="toggleColumn($event)"
                    v-model="columns"
                    color="dark"
                    class="mr-3"
                    hide-details
                    dense
                  ></v-checkbox>
                </div> -->

                <div v-if="type == 'body'" class="mt-5 pl-3">
                  <a
                    v-for="category in allCategories"
                    @click="setSelected(category.id)"
                    :class="category.id == selectedBlock ? 'primary--text' : 'dark--text'"
                    :key="category.id"
                    class="d-block mb-2 menu-left"
                  >
                    <span>{{ category.name }}</span>

                    <v-tooltip max-width="450" color="primary" right>
                      <template #activator="{ on }">
                        <v-icon v-if="category.description" color="primary" v-on="on" small right>mdi-help-circle</v-icon>
                      </template>
                      <span class="caption" :style="{ lineHeight: '1.3 !important' }">{{ category.description }}</span>
                    </v-tooltip>
                  </a>
                </div>
              </v-card-text>
            </v-card>
          </v-sheet>
          <v-content style="width: calc(100% - 320px); overflow-y: scroll;" class="pa-0 d-inline-flex">
            <v-card flat class="fill-height">
              <v-card-text class="pa-7">
                <preloader v-if="status.getting" message="Please wait..." />
                
                <!-- IF HEADER OR FOOTER TYPE -->

                <div 
                  v-if="['header'].includes(type)"
                  class="d-flex align-center justify-space-between"
                >
                  <h5 class="font-weight-bold title"> 
                    <span>Header</span>
                    <!-- <span class="subtitle-1">({{ getCategoryBlock(category.id).length }})</span>  -->
                  </h5>

                  <v-btn depressed class="pa-0">
                    <v-checkbox
                      v-model="popularity"
                      on-icon="mdi-checkbox-marked-circle-outline" 
                      off-icon="mdi-checkbox-blank-circle-outline" 
                      label="Order by popularity"
                      class="pa-0 px-3 ma-0"
                      hide-details
                      outlined
                      dense
                    ></v-checkbox>
                  </v-btn>
                </div>
                
                <div 
                  v-if="['footer'].includes(type)"
                  class="d-flex align-center justify-space-between"
                >
                  <h5 class="font-weight-bold title"> 
                    <span>Footer</span>
                    <!-- <span class="subtitle-1">({{ getCategoryBlock(category.id).length }})</span>  -->
                  </h5>

                  <v-btn depressed class="pa-0">
                    <v-checkbox
                      v-model="popularity"
                      on-icon="mdi-checkbox-marked-circle-outline" 
                      off-icon="mdi-checkbox-blank-circle-outline" 
                      label="Order by popularity"
                      class="pa-0 px-3 ma-0"
                      hide-details
                      outlined
                      dense
                    ></v-checkbox>
                  </v-btn>
                </div>
                
                <v-divider class="mt-2 mb-5"></v-divider>

                <v-fade-transition 
                  v-if="!status.getting && confBlocks.length && ['header', 'footer'].includes(type)" 
                  name="blocks" 
                  class="row"
                  tag="div" 
                  group
                >
                  <v-col
                    v-for="block in confBlocks"
                    :key="block.id"
                    cols="4"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card 
                        :style="current !== block.id || `border-color: ${ $vuetify.theme.themes.light.success }` "
                        :class="{ 'box-shadow' : hover }"
                        class="transition"
                        outlined 
                        tile 
                      >
                        <v-avatar
                          v-if="current == block.id"
                          color="success"
                          class="float-right mr-2"
                          style="z-index: 10;"
                          size="35"
                          right
                        >
                          <v-icon>mdi-check-bold</v-icon>
                        </v-avatar>
                        <v-card-text>
                          <block-image 
                            :block="block" 
                            @selected="selected"
                            selectable
                            with-name
                          />
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-fade-transition>

                <!-- IF BODY SECTION -->
                <v-row v-if="!status.getting && this.type == 'body'">
                  <v-col v-if="!bodyCategories.length" class="d-flex align-center justify-center fill-height">
                    <v-alert type="info" border="left" text>
                      Select a category on the left hand side to see the available website panels.
                    </v-alert>
                  </v-col>

                  <v-col 
                    v-for="category in bodyCategories" 
                    :key="category.id"
                    :id="`${$options.filters.kebab(category.name)}`" 
                    cols="12"
                  >
                    <div class="d-flex align-center justify-space-between">
                      <h5 class="font-weight-bold title"> {{ category.name }} <span class="subtitle-1">({{ getCategoryBlock(category.id).length }})</span> </h5>

                      <v-btn depressed class="pa-0">
                        <v-checkbox
                          v-model="popularity"
                          on-icon="mdi-checkbox-marked-circle-outline" 
                          off-icon="mdi-checkbox-blank-circle-outline" 
                          label="Filter by popularity"
                          class="pa-0 px-3 ma-0"
                          hide-details
                          outlined
                          dense
                        ></v-checkbox>
                      </v-btn>
                    </div>
                    
                    <v-divider class="mt-2 mb-5"></v-divider>
                    
                    <v-fade-transition tag="div" class="row" name="row" group>
                      <v-col 
                        v-for="block in getCategoryBlock(category.id)" 
                        :key="block.id"
                        cols="4"
                      >
                        <v-hover v-slot="{ hover }">
                          <v-card 
                            :style="current !== block.id || `border-color: ${ $vuetify.theme.themes.light.success }` "
                            :class="{ 'box-shadow' : hover }"
                            class="transition"
                            outlined
                            tile 
                          >
                            <v-avatar
                              v-if="current == block.id"
                              color="success"
                              class="float-right mr-2"
                              style="z-index: 10;"
                              size="35"
                              right
                            >
                              <v-icon>mdi-check-bold</v-icon>
                            </v-avatar>
                            <v-card-text>
                              <block-image 
                                :block="block" 
                                @selected="selected"
                                selectable
                                with-name
                              />
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-content>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import _orderBy from 'lodash/orderBy'
import { mapState, mapActions } from 'vuex'

import BlockImage from '@/components/BlockImageAdvance'

export default {
  /*------------------------------------------------------------------------------
    * PROPS
    *----------------------------------------------------------------------------*/
  props: {
    show: Boolean,
    type: String,
    current: String,
    replace: Object,
    currentCategory: String,
  },

  /*------------------------------------------------------------------------------
    * DATA
    *----------------------------------------------------------------------------*/
  data() {
    return {
      selectAll: true,
      fetching: false,
      firstSet: false,
      searchName: null,
      allColumns: true,
      popularity: false,
      checkCategories: [],
      selectedBlock: null,
      gettingBlocks: false,
      columns: [1, 2, 3, 4, 5, 6, 7, 8],
    }
  },

  /*------------------------------------------------------------------------------
    * COMPONENTS
    *----------------------------------------------------------------------------*/
  components: {
    BlockImage
  },

  /*------------------------------------------------------------------------------
    * COMPUTED
    *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      categories: state => state.blocks.categories,
      blocks: state => state.blocks.blocks,
      status: state => state.blocks.status,
    }),

    bodyCategories: function () {
      let categories = this.categories.filter(category => {
        // return !category.header && !category.footer && this.getCategoryBlock(category.id).length && this.checkCategories.includes(category.id)
        return !category.header && !category.footer && this.selectedBlock && category.id == this.selectedBlock
      })

      return _orderBy(categories, 'name', 'asc')
    },

    confBlocks: function () {
      let typeid = null
      let blocks = []

      // IF HEADER OR FOOTER
      if (['header', 'footer'].includes(this.type)) {
        let type = this.categories.find(category => category[this.type] == true)
        typeid = type.id
      }

      // ENABLED FILTER
      blocks = this.blocks.filter(block => {
        return block.enable && (typeid ? typeid == block.type : true)
      })

      // // NAME FILTER
      // if (this.searchName) {
      //   blocks = blocks.filter(block => {
      //     return block.name.toLowerCase().includes(this.searchName.toLowerCase())
      //   })
      // }

      // COLUMN FILTER
      blocks = blocks.filter(block => {
        return this.columns.includes(block.column)
      })

      if (this.popularity) blocks = _orderBy(blocks, 'useCount', 'desc')
      else blocks = _orderBy(blocks, 'name', 'asc')

      return blocks
    },

    allCategories: function () {
      let categories = this.categories.filter(category => {
        return !category.header && !category.footer && this.getCategoryBlock(category.id).length
      })

      if (this.searchName) {
        categories = categories.filter(category => {
          if (category.name.toLowerCase().includes(this.searchName.toLowerCase())) 
            return true
          
          else if (category.tags && category.tags.length) {
            let hasTag = false
            
            category.tags.forEach(tag => {
              if (tag.toLowerCase().includes(this.searchName.toLowerCase()))
                hasTag = true
            })

            return hasTag
          }
          else {
            return false
          }
        })
      }

      return _orderBy(categories, 'order', 'asc')
    },

    switchAll: function () {
      return this.checkCategories.length == this.categories.length
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'categories': function (val) {
      if (val.length && !this.firstSet) {
        this.firstSet = true
        this.setAllCategories()
      }
    },

    'currentCategory': function () {
      this.checkCurrentCategory()
    }
  },

  /*------------------------------------------------------------------------------
    * METHODS
    *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('blocks', [
      'getBlocks'
    ]),

    closeDialog() {
      this.popularity = false
      this.$emit('close')
    },

    selected(data) {
      data.type = this.type
      data.category = this.selectedBlock
      this.$emit('selected', data)
    },

    getCategoryBlock(category) {
      return this.confBlocks.filter(block => {
        return block.type == category
      })
    },

    setAllCategories() {
      this.checkCategories = []

      this.categories.forEach(category => {
        this.checkCategories.push(category.id)
      })
    },

    toggleSelectAll(e) {
      if (e) this.setAllCategories()
      else this.checkCategories = []
    },

    checkSelected() {
      this.selectAll = this.checkCategories.length == this.categories.length || this.allCategories.length == this.checkCategories.length
    },

    toggleAllColumns: function (e) {
      this.columns = e ? [1, 2, 3, 4, 5, 6, 7, 8] : []
    },

    toggleColumn: function (e) {
      this.allColumns = e.length >= 8
    },

    setSelected(id) {
      this.selectedBlock = id
    },

    checkCurrentCategory() {
      if (this.currentCategory) this.setSelected(this.currentCategory)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.state.blocks.status.firstLoad) this.getBlocks()
    this.checkCurrentCategory()
  }
}
</script>

<style lang="scss">

.select-template-sidebar {
  border-right: 1px solid #ddd;
  overflow-y: scroll;
}

.menu-left {
  &:hover {
    text-decoration: underline;
  }
}

</style>
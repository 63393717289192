import db from '@/firebase/init'
import firebase from 'firebase'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  prices: [],
  status: {
    paying: false,
    getting: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  price: (state) => (id) => {
    if (state.prices.length) {
      return state.prices.find(p => p.id == id) || {}
    }
    else return {}
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setPrices(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        let price = doc.data()
        price.id = doc.id
        price.ref = doc.ref
        state.prices.push(price)
      })
    }
  },

  addPrice(state, payload) {
    let price = state.prices.find(p => p.id == payload.id)
    if (price) Vue.set(state.prices, state.prices.indexOf(price), payload)
    else state.prices.push(payload)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET PRICES
   *----------------------------------------------------------------------------*/
  getPrices({ commit }) {
    commit('updateStatus', { getting: true })
    
    db.collection('stripe_products')
    .where('stripe_metadata_app', '==', 'builder')
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        
        doc.ref
        .collection('prices')
        .where('type', '==', 'one_time')
        .get()
        .then((priceSnapshot) => {
          commit('setPrices', priceSnapshot)
        })
      }
      else {
        commit('updateStatus', { getting: false })
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
    })
  },
  
  /*------------------------------------------------------------------------------
   * GET PRICE
   *----------------------------------------------------------------------------*/
  getPrice({ commit }, id) {
    db.collection('stripe_products')
    .where('stripe_metadata_app', '==', 'builder')
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        
        doc.ref
        .collection('prices')
        .doc(id).get()
        .then((priceDoc) => {
          let data = priceDoc.data()
          data.id = priceDoc.id
          data.ref = priceDoc.ref
          commit('addPrice', data)
        })
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
    })
  },

  /*------------------------------------------------------------------------------
   * ONE TIME PAYMENT
   * @params
   * - Object form
   * - String order_id
   *----------------------------------------------------------------------------*/
  async payOnce({ commit }, order) {
    let user = firebase.auth().currentUser
    commit('updateStatus', { paying: true })

    const docRef = await db
    .collection('customers')
    .doc(user.uid)
    .collection("checkout_sessions")
    .add({
      mode: "payment",
      price: order.price,
      success_url: `${window.location.origin}/orders/${order.id}?view=2`,
      cancel_url: `${window.location.origin}/orders/${order.id}`,
      metadata: {
        order: order.id,
      },
    })
    
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data()
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        console.log(`An error occured: ${error.message}`)
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
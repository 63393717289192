<template>
  <div v-if="project">
    <div v-if="!status.getting && !collaborators.length" class="caption mb-3">
      This project doesn't have any collaborator.
    </div>

    <div v-if="!$store.getters['project/isOwner'] && $store.getters['collaborators/isCollaborator']" class="mb-3">
      <v-btn
        @click="
          $store.commit('collaborators/leaveProjectDialogState', true)
          $store.commit('collaborators/toLeaveState', project)
        "
        color="accent dark--text" 
        small
      >Leave Project</v-btn>
    </div>

    <preloader v-if="status.getting" message="Please wait..." />

    <v-list v-if="!status.getting && actives.length">
      <v-card
        v-for="collab in actives"
        :key="collab.id"
        class="mb-3"
        outlined
        light
      >
        <v-list-item>
          <v-btn 
            v-if="$store.getters['project/isOwner'] || $store.getters['user/isAdmin']" 
            @click="showRemoveDialog(collab)"
            class="mr-n3"
            absolute
            right
            small
            icon 
          >
            <v-icon x-small>mdi-close</v-icon>
          </v-btn>
          <v-list-item-avatar>
            <UserPhoto :size="30" photoSize="thumb" :id="collab.user"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $store.getters['users/user'](collab.user).fullName }}</v-list-item-title>
            <v-list-item-subtitle class="caption">{{ $store.getters['users/user'](collab.user).employeeRole }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-list>

    <div v-if="$store.getters['project/isOwner'] || $store.getters['project/canManage']">
      <v-btn @click="showCollabDialog()" outlined small>Add New</v-btn>
    </div>

    <div v-if="pendings.length && ($store.getters['project/isOwner'] || $store.getters['project/canManage'])" class="mt-5">
      <div class="caption d-flex justify-space-between align-center mb-3">
        <span>Pending Access Requests</span>
        <v-btn 
          @click="acceptAll()" 
          :loading="status.acceptingAll"
          color="white primary--text" 
          x-small
        >Accept All</v-btn>
      </div>

      <v-list v-if="!status.getting">
        <v-card
          v-for="collab in pendings"
          :key="collab.id"
          class="mb-3"
          outlined
          light
        >
          <v-list-item>
            <v-list-item-avatar>
              <UserPhoto :size="30" photoSize="thumb" :id="collab.user"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $store.getters['users/user'](collab.user).fullName }}</v-list-item-title>
              <v-list-item-subtitle class="caption">{{ $store.getters['users/user'](collab.user).employeeRole }}</v-list-item-subtitle>
              <v-list-item-subtitle class="mt-2">
                <v-btn
                  @click="acceptRequest(collab)"
                  :loading="status.accepting.includes(collab.id) || status.acceptingAll"
                  :disabled="status.deleting"
                  class="mr-1" 
                  color="primary" 
                  x-small
                >Accept</v-btn>
                
                <v-btn 
                  @click="deleteCollaborator(collab)"
                  :loading="status.deleting"
                  :disabled="status.accepting.includes(collab.id) || status.acceptingAll"
                  color="warning" 
                  text x-small
                >Reject</v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-list>
    </div>

    <!-- FIND COLLAB DIALOG -->
    <collab-dialog
      ref="dialog"
      :project="project"
    />

    <!-- REMOVE COLLABORATOR -->
    <confirm-delete
      :show="removeDialog"
      :message="dialogMessage"
      :deleting="status.deleting"
      @confirmed="removeConfirmed()"
      @cancel="hideRemoveDialog()"
    />

    <!-- LEAVE PROJECT DIALOG -->
    <confirm-delete
      :show="status.leaveProjectDialog"
      :deleting="status.leavingProject"
      @confirmed="leaveProject()"
      @cancel="$store.commit('collaborators/leaveProjectDialogState', false)"
      message="Are you sure you want to leave this project?"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CollabDialog from './FindCollaboratorsDialog'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      removeDialog: false,
      dialogMessage: null,
      toRemove: null,
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH`
   *----------------------------------------------------------------------------*/
  watch: {
    '$route': function () {
      if (this.project) this.getCollaborators(this.project.id)
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    CollabDialog
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      project: state => state.project.project,
      status: (state) => state.collaborators.status,
      collaborators: (state) => state.collaborators.collaborators,
    }),

    actives: function () {
      return this.collaborators.filter(collab => collab.status == 'active')
    },
    
    pendings: function () {
      return this.collaborators.filter(collab => collab.status == 'pending')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('collaborators', [
      'getCollaborators',
      'deleteCollaborator',
      'acceptRequest',
      'leaveProject',
      'acceptAll',
    ]),

    showCollabDialog() {
      this.$refs.dialog.dialog = true
    },

    showRemoveDialog(collab) {
      this.toRemove = collab
      this.dialogMessage = `Remove <strong>${this.$store.getters['users/user'](collab.user).fullName}</strong> as collaborator from this project?`
      this.removeDialog = true
    },

    hideRemoveDialog() {
      this.removeDialog = false
    },

    removeConfirmed() {
      Promise.all([
        this.deleteCollaborator(this.toRemove)
      ])
      .then(() => {
        this.hideRemoveDialog()
      })
    }
  },
}
</script>
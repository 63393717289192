<template>
  <v-hover v-slot:default="{ hover }" :disabled="!editable">
    <v-card class="block-img-card" flat tile>
      <v-card flat tile>
        <div class="svg-container" v-html="src"></div>
      </v-card>

      <v-fade-transition>
        <v-overlay
          v-if="hover"
          absolute
        >
          <v-card class="d-flex gap-7 pa-1" color="accent" rounded="pill">
            <v-btn
              @click="deleteImage()"
              color="black"
              depressed
              x-small
              fab
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>

            <v-btn
              @click="$refs.imagePopup.showDialog()"
              color="black"
              depressed
              x-small
              fab
            >
              <v-icon small>mdi-aspect-ratio</v-icon>
            </v-btn>
          </v-card>
        </v-overlay>
      </v-fade-transition>

      <confirm-delete
        :deleting="$store.state.blocks.status.deletingImage"
        @confirmed="deleteConfirmed()"
        :message="deleteMessage"
        @cancel="cancelDelete()"
        :show="showDelete"
      />

      <image-popup ref="imagePopup" :src="src"/>
    </v-card>
  </v-hover>
</template>

<script>
import firebase from 'firebase'

export default {
  props: {
    id: String,
    file: String,
    type: String,
    data: Object,
    editable: Boolean,
  },

  data() {
    return {
      src: null,
      url: null,
      attempts: 0,
      loaded: false,
      showPopup: false,
      showDelete: false,
      deleteMessage: null,
    }
  },

  watch: {
    'file': function () {
      this.getUrl()
    }
  },

  methods: {
    deleteImage() {
      this.showDelete = true
      this.deleteMessage = 'Are you sure you want to delete this image?'
    },

    deleteConfirmed() {
      Promise.resolve(
        this.$store.dispatch('blocks/deleteImage', {
          type: this.type,
          data: this.data,
          id: this.data.id,
        })
      )
      .then(() => {
        this.cancelDelete()
      })
    },

    cancelDelete() {
      this.showDelete = false
      this.deleteMessage = null
      this.$store.commit('blocks/setDeletingImageState', false)
    },

    getExtension(file) {
      let data = file.split('.')
      return data[data.length - 1]
    },

    getUrl() {
      if (this.data) {
        this.attempts++

        if (!this.data[`${this.type}Svg`]) {
          var storage  = firebase.storage()
          storage.ref(`blocks/${this.data[this.type]}`)
          .getDownloadURL()
          .then(async (url) => {
            var xhr = new XMLHttpRequest()
            // xhr.responseType = 'blob'
            this.url = url
  
            xhr.onload = () => {
              let response = xhr.response
              this.src = response
              this.data.ref.update({ [`${this.type}Svg`]: response })
              .catch(error => console.log(error.message))
              // var reader = new FileReader()
              // reader.readAsDataURL(response)
              // reader.onloadend = () => {
              //   var base64data = reader.result
              //   this.src = base64data
              //   this.data.ref.update({ [`${this.type}Svg`]: base64data })
              //   .catch(error => console.log(error.message))
              // }
            }
              
            xhr.open('GET', url)
            xhr.send()
          })
          .catch(error => {
            console.log(error.message)
            if (this.attempts < 6) this.getUrl()
          })
        }
        else {
          this.src = this.data[`${this.type}Svg`]
        }
      }
    }
  },

  mounted() {
    this.getUrl()
  },

}

</script>

<style lang="scss">

.svg-container {
  svg {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

</style>
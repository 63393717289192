<template>
  <div>
    <v-alert type="error" :value="!!status.error" border="left" dense>
      {{ status.error }}
    </v-alert>

    <v-sheet color="transparent" class="mb-5 d-flex align-center">
      <span class="caption anchor--text primary--text text--lighten-2">
        Last updated {{ settings.updatedAt || settings.createdAt | formatDate }}
      </span>

      <v-spacer></v-spacer>

      <v-tooltip left>
        <template v-slot:activator="{ on }">    
          <button
            v-clipboard:copy="getRoute()"
            v-clipboard:success="copied"
            class="mr-1"
            v-on="on"
            icon
            small
          >
            <v-icon small>mdi-link-variant</v-icon>
          </button>
        </template>
        <span class="caption">Copy project URL</span>
      </v-tooltip>

      <v-menu 
        v-if="$store.state.user.user"
        transition="slide-x-transition" 
        nudge-bottom="12"
        offset-y
        bottom
        left
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary lighten-2"
            v-on="on"
            small
            icon
          >
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list min-width="120">
          <v-list-item @click="confirmDeleteProject()" class="subtle" dense>
            <v-list-item-title>
              <v-icon small left class="red--text">mdi-delete-outline</v-icon> 
              <span class="red--text">Delete</span> 
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-sheet>

    <v-form v-if="Object.keys(settings).length" ref="projectSettingForm" @submit.prevent="validateSettings()">
      <v-text-field
        v-model="settings.name"
        :rules="[rules.required]"
        label="Project Name"
        class="subtle-outline"
        outlined
        dense
      >
        <template v-slot:append>
          <v-btn 
            v-clipboard:copy="settings.name"
            v-clipboard:success="copied"
            icon
            x-small
          >
            <v-icon color="primary lighten-3">mdi-content-copy</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <template v-if="$store.state.user.user">
        <v-file-input
          v-if="!settings.logo || typeof settings.logo == 'object'"
          v-model="settings.logo"
          @change="readLogoData($event)"
          accept="image/png, image/jpeg, image/svg+xml"
          label="Brand Logo"
          class="subtle-outline"
          prepend-icon=""
          outlined
          dense
        >
          <template v-slot:append>
            <v-icon color="primary lighten-3">mdi-paperclip</v-icon>
          </template>
        </v-file-input>

        <v-progress-linear
          v-show="status.progress"
          v-model="status.progress"
          class="mb-5"
          color="blue-grey"
          height="15"
        >
          <template v-slot="{ value }">
            <strong class="caption">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>

        <v-row align="center" v-if="logoUrl && !status.uploading" class="mb-5">
          <v-col cols="8">
            <v-card class="pa-1" light>
              <v-img 
                :src="logoUrl"
                height="50"
                contain
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="4" class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="downloadlogo()"
                  v-on="on"
                  icon
                  small
                >
                  <v-icon color="primary lighten-3" small>mdi-download</v-icon>
                </v-btn>
              </template>
              <span class="caption">Download</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="deleteLogo()"
                  v-on="on"
                  icon
                  small
                >
                  <v-icon color="primary lighten-3" small>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span class="caption">Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <div class="d-flex align-start caption mb-5" style="line-height: 1.2 !important;">
          <v-icon x-small class="mr-1" style="margin-top: 2px">mdi-information-outline</v-icon> <div>This is for the web developer reference colors only.</div>
        </div>
        
        <v-menu
          bottom
          :close-on-content-click="showPrimary"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="settings.primaryColor"
              label="Primary Color"
              class="subtle-outline"
              v-on="on"
              outlined
              readonly
              dense
            >
              <template v-if="settings.primaryColor" v-slot:append>
                <v-btn 
                  v-clipboard:copy="settings.primaryColor"
                  v-clipboard:success="copied"
                  icon
                  x-small
                >
                  <v-icon :color="settings.primaryColor || 'white'">mdi-square-rounded</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </template>

          <v-card>
            <v-card-text>
              <v-color-picker
                v-model="primary"
                dot-size="25"
                swatches-max-height="200"
                mode="hexa"
                light
              ></v-color-picker>

              <div class="text-center">
                <v-btn
                  @click="
                    showPrimary = true
                    setPrimaryColor()
                  "
                  color="accent dark--text" 
                  small 
                  block
                >Set Color</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-menu
          bottom
          :close-on-content-click="showSecondary"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="settings.secondaryColor"
              label="Secondary Color"
              class="subtle-outline"
              v-on="on"
              outlined
              readonly
              dense
            >
              <template v-if="settings.secondaryColor" v-slot:append>
                <v-btn 
                  v-clipboard:copy="settings.secondaryColor"
                  v-clipboard:success="copied"
                  icon
                  x-small
                >
                  <v-icon :color="settings.secondaryColor || 'white'">mdi-square-rounded</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </template>

          <v-card>
            <v-card-text>
              <v-color-picker
                v-model="secondary"
                dot-size="25"
                swatches-max-height="200"
                mode="hexa"
                light
              ></v-color-picker>

              <div class="text-center">
                <v-btn
                  @click="
                    showSecondary = true
                    setSecondaryColor()
                  "
                  color="accent dark--text" 
                  small 
                  block
                >Set Color</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        
      </template>

      <v-btn
        @click="validateSettings()"
        :loading="status.updating"
        color="accent"
        class="dark--text"
        small
      >Save Changes</v-btn>
    </v-form>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="showDelete"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
    />

    <!-- CONFIRM DELETE PROJECT -->
    <confirm-delete
      :show="showDeleteProject"
      :message="deleteProjectMessage"
      :deleting="status.deletingProject"
      textConfirmation="Project"
      @cancel="closeDeleteProjectDialog()"
      @confirmed="deleteProjectConfirmed()"
      captcha
    />
  </div>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'
import ConfirmDelete from '@/components/ConfirmDelete'
import firebase from 'firebase'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      settings: {},
      logoUrl: null,
      showDelete: false,
      deleteMessage: null,
      tempUrl: null,
      showDeleteProject: false,
      deleteProjectMessage: null,
      primary: '#000000',
      secondary: '#000000',
      showPrimary: false,
      showSecondary: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    ConfirmDelete
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      project: state => state.project.project,
      status: state => state.project.status,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'project': function () {
      this.setValue()
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('project', [
      'getProject',
      'deleteProjectLogo',
      'update',
      'deleteProject'
    ]),

    setValue() {
      this.settings = Object.assign({}, this.project)

      if (this.project) {
        this.primary = this.project.primaryColor || '#000000'
        this.secondary = this.project.secondaryColor || '#000000'
      }
      
      if (this.project && this.project.logo) {
        if (typeof this.project.logo !== 'object') {
          Promise.all([
            this.$getUrl(`logos/${this.project.logo}`)
          ])
          .then(response => {
            this.logoUrl = response[0]
          })
        }
        else {
          this.logoUrl = this.tempUrl
        }
      }
      else {
        this.logoUrl = null
      }
    },

    validateSettings() {
      if (this.$refs.projectSettingForm.validate()) {
        Promise.all([
          this.update(this.settings)
        ])
        .then(response => {
          if (!response[0]) {
            this.settings.logo = true
          }
        })
      }
    },

    copied() {
      this.$store.dispatch('showSuccess', 'Copied to clipboard')
    },

    deleteLogo() {
      this.deleteMessage = 'Delete brand logo?'
      this.showDelete = true
    },

    closeDeleteDialog() {
      this.deleteMessage = null
      this.showDelete = false
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteProjectLogo()
      ])
      .then(() => {
        this.closeDeleteDialog()
        this.setValue()
        this.logoUrl = null
      })
    },

    readLogoData(e) {
      if (typeof e == 'object') {
        this.tempUrl = URL.createObjectURL(e)
      }
    },

    downloadlogo() {
      this.$downloadFile('logos', this.project.logo)
    },

    getRoute() {
      let base = window.location.origin
      let path = this.$router.currentRoute.fullPath
      let fullpath = `${base}${path}`
      
      return fullpath
    },

    confirmDeleteProject() {
      this.showDeleteProject = true
      this.deleteProjectMessage = 'Are you sure you want to delete this project? This action cannot  be undone.'
    },

    closeDeleteProjectDialog() {
      this.showDeleteProject = false
    },

    deleteProjectConfirmed() {
      Promise.all([
        this.deleteProject()
      ])
      .then(() => {
        let user = firebase.auth().currentUser
        if (this.project.owner !== user.uid) this.$router.push({ name: 'AllProjects' })
        else this.$router.push(this.project.folder == 'root' ? { name: 'Projects' } : { name: 'Folder', params: { id: this.project.folder } })
        this.closeDeleteProjectDialog()
      })
    },

    setPrimaryColor() {
      this.settings.primaryColor = this.primary

      setTimeout(() => {
        this.showPrimary = false
      }, 1500)
    },
    
    setSecondaryColor() {
      this.settings.secondaryColor = this.secondary

      setTimeout(() => {
        this.showSecondary = false
      }, 1500)
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.setValue()
  }
}
</script>
import Vue from 'vue'
import _omit from 'lodash/omit'
import db from '@/firebase/init'
import _orderBy from 'lodash/orderBy'
import firebase, { firestore } from 'firebase'

var storageRef = firebase.storage().ref()

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const state = {
  data: {
    enable: true,
    orientations: []
  },
  blocks: [],
  catData: {},
  categories: [],
  status: {
    new: null,
    getting: false,
    creating: false,
    deleting: false,
    uploading: false,
    firstLoad: false,
    showDialog: false,
    gettingAll: false,
    categoryError: null,
    updatingTags: false,
    incrementing: false,
    deletingImage: false,
    firstLoadCats: false,
    creatingCategory: false,
    deletingCategory: false,
    gettingCategories: false,
    updatingDescription: false,
  },
  uploads: [],
  uploading: [],
  uploadErrors: [],
  svgs: [
    {
      name: 'leftDarkImg',
      jpeg: 'leftDarkImgJpeg',
      type: 'left-dark'
    },
    {
      name: 'leftLightImg',
      jpeg: 'leftLightImgJpeg',
      type: 'left-light'
    },
    {
      name: 'leftGreyImg',
      jpeg: 'leftGreyImgJpeg',
      type: 'left-grey'
    },
    {
      name: 'centerDarkImg',
      jpeg: 'centerDarkImgJpeg',
      type: 'center-dark'
    },
    {
      name: 'centerLightImg',
      jpeg: 'centerLightImgJpeg',
      type: 'center-light'
    },
    {
      name: 'centerGreyImg',
      jpeg: 'centerGreyImgJpeg',
      type: 'center-grey'
    },
    {
      name: 'rightDarkImg',
      jpeg: 'rightDarkImgJpeg',
      type: 'right-dark'
    },
    {
      name: 'rightLightImg',
      jpeg: 'rightLightImgJpeg',
      type: 'right-light'
    },
    {
      name: 'rightGreyImg',
      jpeg: 'rightGreyImgJpeg',
      type: 'right-grey'
    },
  ],
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  blocksByType: (state) => (type) => {
    let blocks = state.blocks.filter(block => {
      return block.type == type
    })

    return _orderBy(blocks, ['createdAt'], ['asc'])
  },

  data: (state) => (id) => {
    if (state.blocks) {
      let block = state.blocks.find(b => b.id == id)
      return block || {}
    }
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  setShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  setCreatingState(state, bol) {
    state.status.creating = bol
  },

  setError(state, message) {
    status.status.error = message
  },

  setBlocks(state, payload) {
    state.blocks = []

    payload.forEach(item => {
        let data = item.data()
        data.useCount = data.useCount || 0
        data.id = item.id
        data.ref = item.ref

        state.blocks.push(data)
    })

    state.status.gettingAll = false
  },

  setUploadProgress(state, data) {
    let progress = data[0]
    let name = data[1]

    let upload = state.uploading.find(upload => upload.name == name)

    if (!upload) {
      state.uploading.push({
        progress,
        name
      })
    }
    else {
      upload.progress = progress
    }
  },

  resetData(state) {
    state.data = {
      enable: true,
      orientations: []
    }
  },

  uploadProgress(state, type) {
    state.uploads.push(type)
  },

  removeUploadProgress(state, type) {
    state.uploads.splice(state.uploads.indexOf(type), 1)
  },

  setUploadingState(state, bol) {
    state.status.uploading = bol
  },

  addUploadError(state, error) {
    state.uploadErrors.push(error)
  },

  resetUpload(state) {
    state.status.uploading = false
    state.status.creating = false
    state.uploading = []
    state.uploadErrors = []
  },

  setDeletingState(state, bol) {
    state.status.deleting = bol
  },

  removeBlock(state, block) {
    state.blocks.splice(state.blocks.indexOf(block), 1)
  },

  setNew(state, id) {
    state.status.new = id
  },

  setGettingState(state, bol) {
    state.status.getting = bol
  },

  insertBlock(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref

    let blk = state.blocks.find(block => block.id == data.id)
    if (!blk) state.blocks.push(data)
    else Vue.set(state.blocks, state.blocks.indexOf(blk), data)
  },

  setData(state, payload) {
    state.data = payload
  },

  setDeletingImageState(state, bol) {
    state.status.deletingImage = bol
  },

  removeBlockImg(state, payload) {
    let blk = state.blocks.find(b => b.id == payload.id)
    blk[payload.type] = null
    blk[`${payload.type}Svg`] = null
  },

  setGettingAll(state, bol) {
    state.status.gettingAll = bol
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  createCategoryState(state, bol) {
    state.status.creatingCategory = bol
  },

  setCategoryError(state, message) {
    state.status.categoryError = message
  },

  gettingCategoryState(state, bol) {
    state.status.gettingCategories = bol
  },

  setCategories(state, payload) {
    state.categories = []

    payload.forEach(item => {
      let data = item.data()
      data.id = item.id
      data.ref = item.ref

      state.categories.push(data)
    })

    state.status.gettingCategories = false
    state.status.firstLoadCats = true
  },

  setCategoryData(state, data) {
    if (data.id) Object.assign(state.catData, data)
    else state.catData = data
  },

  insertCategory(state, payload) {
    var category = state.categories.find(category => category.id == payload.id)

    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref

    if (category) state.categories.splice(state.categories.indexOf(category), 1)

    state.categories.push(data)
  },

  deletingCategoryState(state, bol) {
    state.status.deletingCategory = bol
  },

  removeCategory(state, payload) {
    state.categories.splice(state.categories.inqdexOf(payload), 1)
  },

  setDefaultCategory(state, id) {
    state.data.type = id
  },

  changeCategory(state, payload) {
    state.blocks.forEach(block => {
      if (block.id == payload.block) {
        block.type = payload.new
      }
    })
  },

  updateCategories(state, payload) {
    let updatedSections = payload

    updatedSections.forEach((category, i) => {
      let data = category
      data.order = i
      Vue.set(state.categories, i, data)
    })
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE
   *----------------------------------------------------------------------------*/
  create({ state, commit, dispatch }) {
    commit('setCreatingState', true)
    let user = firebase.auth().currentUser

    let data = state.data
    data.user = user.uid
    data.createdAt = Date.now()
    data.updateddAt = Date.now()

    // Add to upload type to upload progress
    // if file is present
    let omit = []

    state.svgs.forEach(item => {
      if (state.data[item.name] || state.data[item.jpeg]) {
        commit('uploadProgress', item.type)
        commit('setUploadingState', true)
      }

      omit.push(item.name)
    })

    db.collection('blocks').add(_omit(data, omit))
    .then((docRef) => {

      commit('setNew', docRef.id)

      state.svgs.forEach(item => {
        if (state.data[item.name]) {
          dispatch('uploadPhoto', [data[item.name], item.type, docRef.id])
          if (state.data[item.jpeg]) dispatch('uploadPhoto', [data[item.jpeg], item.type, docRef.id])
        }
      })

      // If no file is present
      // close dialog show message dialog
      let hasImage = false

      state.svgs.forEach(item => {
        if (state.data[item.name] || state.data[item.jpeg]) hasImage = true
      })

      if (!hasImage) {
        commit('setShowDialog', false)
        dispatch('showSuccess', 'Block successfully created.', { root: true })
        commit('resetUpload')
        dispatch('getBlock', docRef.id)
      }
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setCreatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE BLOCK
   *----------------------------------------------------------------------------*/
  update({ commit, state, dispatch }) {
    commit('setCreatingState', true)

    let omit = []

    state.svgs.forEach(item => {
      if (typeof state.data[item.name] == 'object' || typeof state.data[item.jpeg] == 'object') {
        commit('uploadProgress', item.type)
        commit('setUploadingState', true)
      }

      omit.push(item.name)
      omit.push(item.jpeg)
    })

    omit.push('id')
    omit.push('ref')

    let data = state.data
    data.updateddAt = Date.now()

    state.data.ref.update(_omit(data, omit))
    .then(() => {
      commit('setNew', data.id)
      let promises = []

      for (var item of state.svgs) {
        if (typeof state.data[item.name] == 'object') {
          promises.push(dispatch('uploadPhoto', [data[item.name], item.type, data.id]))
        }

        if (typeof state.data[item.jpeg] == 'object') {
          promises.push(dispatch('uploadPhoto', [data[item.jpeg], item.type, data.id]))
        }
      }

      let hasImage = false

      state.svgs.forEach(item => {
        if (typeof state.data[item.name] == 'object' || typeof state.data[item.jpeg] == 'object') hasImage = true
      })

      if (!hasImage) {
        commit('setShowDialog', false)
        dispatch('showSuccess', 'Block successfully updated.', { root: true })
        commit('resetUpload')
      }

      Promise.all(promises)
      .then(() => {
        dispatch('forceGetBlock', data.id)
      })

    })
    .catch(error => {
      dispatch('setError', error.message)
      commit('setCreatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD BLOCK IMAGE
   *----------------------------------------------------------------------------*/
  uploadPhoto({ state, commit }, data) {
    return new Promise ((resolve, reject) => {
      let file = data[0]
      let type = data[1]
      let id = data[2]
  
      var storageRef = firebase.storage().ref()
      let name = `${Date.now()}_${file.name}`
  
      var metadata = {
        contentType: file.type
      }
  
      var uploadTask  = storageRef.child(`blocks/${name}`).put(file, metadata)
  
      uploadTask.on('state_changed', snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        commit('setUploadProgress', [progress, file.name])
      }, error => {
        commit('setUploadError', error.message)
        reject(error.message)
      }, () => {
        db.collection('blocks')
        .doc(id).get()
        .then(async doc => {
          if (doc.exists) {
            let promises = []
  
            state.svgs.forEach(item => {
              if (type == item.type) {
                var data
                
                if (file.type == 'image/jpeg') data = { [item.jpeg]: name }
                else if (file.type == 'image/svg+xml') data = { [item.name]: name }
                
                promises.push(doc.ref.update(data))
              }
            })
  
            Promise.all(promises)
            .then(() => {
              commit('removeUploadProgress', type)
              resolve('updated')
            })
          }
        })
        .catch(error => {
          commit('addUploadError', error.message)
          reject(error.message)
        })
      })
    })
    
  },

  /*------------------------------------------------------------------------------
   * GET BLOCKS
   *----------------------------------------------------------------------------*/
  getBlocks({ commit, dispatch }) {
    commit('setGettingAll', true)
    commit('setFirstLoad', true)

    db.collection('blocks').get()
    .then(snapshot => {
      if (snapshot.size) commit('setBlocks', snapshot)
      else commit('setGettingAll', false)
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('setGettingAll', false)
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET BLOCK
   *----------------------------------------------------------------------------*/
  async getBlock({ commit, dispatch }, id) {
    commit('setGettingState', true)

    if (!state.blocks.find(b => b.id == id)) {
      await db.collection('blocks')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          commit('insertBlock', doc)
        }

        commit('setGettingState', false)
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        commit('setGettingState', false)
        console.log(error.message)
      })
    }
    else commit('setGettingState', false)

  },
  
  /*------------------------------------------------------------------------------
   * GET BLOCK
   *----------------------------------------------------------------------------*/
  async forceGetBlock({ commit, dispatch }, id) {
    commit('setGettingState', true)

    await db.collection('blocks')
    .doc(id).get()
    .then(doc => {
      if (doc.exists) {
        commit('insertBlock', doc)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })
    .finally(() => {
      commit('setGettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE BLOCK
   *----------------------------------------------------------------------------*/
  async delete({ state, commit, dispatch }, block) {
    commit('setDeletingState', true)
    let hasError = false

    await block.ref.delete()
    .then(() => {
      state.svgs.forEach(item => {
        if (block[item.name]) {
          storageRef.child(`blocks/${block[item.name]}`).delete()
          .catch(error => console.log(error.message))
        }
      })

      commit('removeBlock', block)
      commit('setDeletingState', false)
      dispatch('showSuccess', 'Block deleted', { root: true })
    })
    .catch(error => {
      commit('setDeletingState', false)
      dispatch('showError', error.message, { root: true })
      console.log('blocks.js', error.message)
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * DELETE IMAGE
   *----------------------------------------------------------------------------*/
  deleteImage({ commit, dispatch }, file) {
    return new Promise((resolve, reject) => {
      commit('setDeletingImageState', true)
      
      storageRef.child(`blocks/${file.data[file.type]}`)
      .delete().then(() => {
        return db.collection('blocks')
        .doc(file.id).get()
        .then(doc => {
          if (doc.exists) {
            return doc.ref.update(
              { 
                [file.type] : firebase.firestore.FieldValue.delete(),
                [`${file.type}Svg`] : firebase.firestore.FieldValue.delete(),
                [`${file.type}Base64`] : firebase.firestore.FieldValue.delete(),
              },
            )
            .then(() =>  {
              commit('setDeletingImageState', false)
              commit('removeBlockImg', { id: file.id, type: file.type })
              dispatch('showSuccess', 'Image successfully deleted.', { root: true })
              resolve('deleted')
            })
          }
        })
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        commit('setDeletingImageState', false)
        console.log('blocks.js', error.message)
        reject(error.message)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * GET BLOCK CATEGORY
   *----------------------------------------------------------------------------*/
  getCategories({ commit, dispatch }) {
    commit('gettingCategoryState', true)

    db.collection('blockCategories')
    .orderBy('order', 'asc')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setCategories', snapshot)
      }
      else {
        commit('gettingCategoryState', false)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('gettingCategoryState', false)
      console.log('blocks.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE BLOCK CATEGORY
   *----------------------------------------------------------------------------*/
   async createCategory({ state, commit, dispatch }) {
    commit('createCategoryState', true)
    if (state.status.categoryError) commit('setCategoryError', null)

    let user = firebase.auth().currentUser
    let hasError = false

    let data = state.catData
    data.createdAt = Date.now()
    data.createdBy = user.uid

    await db.collection('blockCategories').add(data)
    .then((docRef) => {
      commit('createCategoryState', false)
      dispatch('getCategory', docRef.id)
    })
    .catch(error => {
      commit('createCategoryState', false)
      commit('setCategoryError', error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * UPDATE CATEGORY
   *----------------------------------------------------------------------------*/
   async updateCategory({ state, commit, dispatch }) {
    commit('createCategoryState', true)
    if (state.status.categoryError) commit('setCategoryError', null)

    let user = firebase.auth().currentUser
    let hasError = false

    let data = state.catData
    data.updatedAt = Date.now()
    data.updatedBy = user.uid

    await data.ref.update(_omit(data, ['id', 'ref']))
    .then(() => {
      commit('createCategoryState', false)
      dispatch('getCategory', data.id)
    })
    .catch(error => {
      commit('createCategoryState', false)
      commit('setCategoryError', error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * GET CATEGORY BY ID
   *----------------------------------------------------------------------------*/
  getCategory({ commit }, id) {
    db.collection('blockCategories')
    .doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        commit('insertCategory', doc)
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE CATEGORY
   *----------------------------------------------------------------------------*/
  async deleteCategory({ getters, commit, dispatch }, data) {
    commit('deletingCategoryState', true)

    await data.category.ref.delete()
    .then(() => {
      commit('removeCategory', data.category)
      commit('deletingCategoryState', false)
      let blocks = getters.blocksByType(data.category.id)

      if (blocks) {
        blocks.forEach(block => {
          block.ref.update({
            type: data.newCategory
          })
          .then(() => {
            commit('changeCategory', {
              block: block.id,
              new: data.newCategory
            })
          })
        })
      }
    })
    .catch(error => {
      commit('deletingCategoryState', false)
      dispatch('showError', error.message, { root: true })
      console.log('blocks.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * REORDER CATEGORIES
   *----------------------------------------------------------------------------*/
  reorderCategories({ state, dispatch }) {
    state.categories.forEach((category, i) => {
      category.ref.update({ order: i })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    })
  },

  /*------------------------------------------------------------------------------
   * SET AS HEADER
   *----------------------------------------------------------------------------*/
  async setHeader({ state, dispatch }, category) {
    dispatch('showUpdating', true, { root: true })

    let currentHeader = state.categories.find(category => category.header)

    if (currentHeader) {
      await currentHeader.ref.update({ header: false })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        console.log('blocks.js', error.message)
      })
    }

    category.ref.update({ header: true })
    .then(() => {
      dispatch('showUpdating', false, { root: true })
      dispatch('showSuccess', 'Category header set.', { root: true })
      dispatch('getCategories')
    })
    .catch(error => {
      dispatch('showUpdating', false, { root: true })
      dispatch('showError', error.message, { root: true })
      console.log('blocks.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * SET AS FOOTER
   *----------------------------------------------------------------------------*/
  async setFooter({ state, dispatch }, category) {
    dispatch('showUpdating', true, { root: true })

    let currentHeader = state.categories.find(category => category.footer)

    if (currentHeader) {
      await currentHeader.ref.update({ footer: false })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        console.log('blocks.js', error.message)
      })
    }

    category.ref.update({ footer: true })
    .then(() => {
      dispatch('showUpdating', false, { root: true })
      dispatch('showSuccess', 'Category footer set.', { root: true })
      dispatch('getCategories')
    })
    .catch(error => {
      dispatch('showUpdating', false, { root: true })
      dispatch('showError', error.message, { root: true })
      console.log('blocks.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE CATEGORY TAGS
   *----------------------------------------------------------------------------*/
  updateCategoryTags({ commit, dispatch }, category) {
    commit('updateStatus', { updatingTags: true })
    
    category.ref
    .update({ tags: category.tags })
    .then(() => {
      commit('updateStatus', { updatingTags: false })
      dispatch('showSuccess', 'Category tags updated', { root: true })
    })
    .catch(error => {
      commit('updateStatus', { updatingTags: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE DESCRIPTION
   *----------------------------------------------------------------------------*/
  updateDescription({ commit, dispatch }, category) {
    if (category.description) {
      commit('updateStatus', { updatingDescription: true })
      
      category.ref
      .update({ description: category.description })
      .then(() => {
        commit('updateStatus', { updatingDescription: false })
        dispatch('showSuccess', 'Category description updated', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('updateStatus', { updatingDescription: false })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * UPDATE BLOCK USE COUNT
   *----------------------------------------------------------------------------*/
  incrementUseCount({ commit }, block) {
    commit('updateStatus', { incrementing: true })

    block.ref
    .update({ useCount: firestore.FieldValue.increment(1) })
    .then(() => {
      commit('updateStatus', { incrementing: false })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { incrementing: false })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

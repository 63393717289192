<template>
  <div class="white--text pa-1" :style="{ fontSize: '12px' }">
    &copy; {{ copyrightDate }} Vivacity Team by
    <a
      href="https://www.lightningsites.com/"
      target="_blank"
      class="text-decoration-none info--text text--lighten-2"
      >Lightning Sites</a
    >
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "Copyright",
  data() {
    return {
      copyrightDate: moment().format("YYYY"),
    }
  }
}
</script>

import Vue from 'vue'
import { omit } from 'lodash'
import firebase from 'firebase'
import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
 const state = {
   data:{},
   types: [],
   status: {
     saving: false,
     getting: false,
     deleting: false,
     firstLoad: false,
   }
 }

 /*------------------------------------------------------------------------------
  * GETTERS
  *----------------------------------------------------------------------------*/
 const getters = {
   type: (state) => (id) => {
    if (state.types) {
      let type = state.types.find(t => t.id == id)
      return type || {}
    }
   }
 }

 /*------------------------------------------------------------------------------
  * MUTATIONS
  *----------------------------------------------------------------------------*/
 const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  addType(state, payload) {
    let type = state.types.find(t => t.id == payload.id)

    if (type) Vue.set(state.types, state.types.indexOf(type), payload)
    else state.types.push(payload)
  },

  setTypes(state, payload) {
    state.types = []

    if (payload.size) {
      payload.forEach(doc => {
        if (!state.types.find(t => t.id == doc.id)) {
          let type = doc.data()
          type.id = doc.id
          type.ref = doc.ref
          state.types.push(type)
        }
      })
    }
  },

  setData(state, payload) {
    state.data = payload
  },

  removeType(state, payload) {
    let type = state.types.find(t => t.id == payload.id)
    if (type) state.types.splice(state.types.indexOf(type), 1)
  }
 }

 /*------------------------------------------------------------------------------
  * ACTIONS
  *----------------------------------------------------------------------------*/
 const actions = {
   /*------------------------------------------------------------------------------
    * SAVE ORDER TYPE
    *----------------------------------------------------------------------------*/
   async saveType({ state, commit, dispatch }){
    commit('updateStatus', { saving: true })
    let user = firebase.auth().currentUser
    let docRef = db.collection('order_types')
    let data = {}

    Object.assign(data, state.data)
    data.created = firebase.firestore.Timestamp.now()
    data.user = user.uid
    
    if (data.id) docRef = docRef.doc(data.id) 
    else docRef = docRef.doc() 
    
    await docRef.set(omit(data, ['ref', 'id']))
    .then(() => {
      data.id = docRef.id
      data.ref = docRef
      commit('addType', data)
      commit('updateStatus', { saving: false })
      dispatch('showSuccess', 'Order type saved', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { saving: false })
      dispatch('showError', error.message, { root: true })
    })
   },

   /*------------------------------------------------------------------------------
    * GET TYPES
    *----------------------------------------------------------------------------*/
   getTypes({ commit }) {
    commit('updateStatus', { getting: true })
    commit('updateStatus', { firstLoad: true })
    
    db.collection('order_types')
    .get()
    .then(snapshot => {
      commit('setTypes', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
    })
   },

   /*------------------------------------------------------------------------------
    * DELETE ORDER TYPE
    *----------------------------------------------------------------------------*/
  async deleteOrderType({ commit, dispatch }, type) {
    commit('updateStatus', { deleting: true })
    
    await type.ref
    .delete()
    .then(() => {
      commit('removeType', type)
      commit('updateStatus', { deleting: false })
      dispatch('showSuccess', 'Order type deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deleting: false })
      dispatch('showError', error.message, { root: true })
     })
   },

  /*------------------------------------------------------------------------------
   * GET ORDER TYPE
   *----------------------------------------------------------------------------*/
  getType({ commit }, id) {
    db.collection('order_types')
    .doc(id).get()
    .then(doc => {
      if (doc.exists) {
        let type = doc.data()
        type.id = doc.id
        type.ref = doc.ref
        commit('addType', type)
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  }
 }

 export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
 }
<template>
    <v-card flat>
      <v-card-title v-if="withName" class="pl-0 pt-0">
        <span class="font-weight-bold body-1 dark--text"> {{ block.name }} </span>
      </v-card-title>
      <v-hover v-slot:default="{ hover }">
        <v-card tile flat>
          <v-img
            :src="placeholder.svg"
            min-height="80"
            aspect-ratio
            contain
          >
            <template v-slot:placeholder>
              <preloader-circular message="Loading image..." />
            </template>
          </v-img>

          <v-fade-transition>
            <v-overlay
              z-index="2"
              v-if="hover"
              absolute
            >
              <v-card class="d-flex gap-7 pa-1" color="accent" rounded="pill">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="$refs.blockPopup.show = true"
                      v-on="on"
                      color="black"
                      depressed
                      x-small
                      fab
                    >
                      <v-icon small>mdi-aspect-ratio</v-icon>
                    </v-btn>
                  </template>
                  <span>Preview Section</span>
                </v-tooltip>
              </v-card>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </v-hover>

      <div class="d-flex justify-space-around mt-1">

        <!-- ORIENTATIONS -->
        <v-btn-toggle v-model="placeholder.orientation" dark mandatory style="position: absolute; left: 0;">
           <v-btn
            @click="setPlaceholderByOrientation('left')"
            v-if="block.orientations.includes('left')"
            value="left"
            x-small
            icon
           >
             <v-icon x-small>mdi-format-align-left</v-icon>
           </v-btn>

           <v-btn
            @click="setPlaceholderByOrientation('center')"
            v-if="block.orientations.includes('center')"
            value="center"
            x-small
            icon
           >
             <v-icon x-small>mdi-format-align-center</v-icon>
           </v-btn>

           <v-btn
            @click="setPlaceholderByOrientation('right')"
            v-if="block.orientations.includes('right')"
            value="right"
            x-small
            icon
           >
             <v-icon x-small>mdi-format-align-right</v-icon>
           </v-btn>
        </v-btn-toggle>


        <!-- COLOR -->
        <v-btn-toggle v-model="placeholder.color" class="grey lighten-2" mandatory>
          <v-btn
            v-if="checkColor('dark')"
            @click="setPlaceholderByColor('dark')"
            value="dark"
            x-small icon
          >
            <v-icon x-small color="primary">mdi-brightness-1</v-icon>
          </v-btn>

          <v-btn
            v-if="checkColor('grey')"
            @click="setPlaceholderByColor('grey')"
            value="grey"
            x-small icon
          >
            <v-icon x-small color="grey">mdi-brightness-1</v-icon>
          </v-btn>

          <v-btn
            v-if="checkColor('light')"
            @click="setPlaceholderByColor('light')"
            value="light"
            x-small icon
          >
            <v-icon x-small color="white">mdi-brightness-1</v-icon>
          </v-btn>
        </v-btn-toggle>

        <v-btn 
          v-if="selectable"
          @click="selectBlock()"
          color="accent dark--text"
          style="right: 0"
          class="mr-0"
          x-small
          absolute
        >
          <v-icon x-small class="mr-1">mdi-box-shadow</v-icon> Add
        </v-btn>
      </div>

      <div v-if="urls.length && $store.state.user.user && $store.state.user.user.role == 'admin'" class="mt-2">
        <v-btn v-for="url in urls" :key="url" :href="url" class="mr-1" target="_blank" x-small>View SVG</v-btn>
      </div>

      <block-popup ref="blockPopup" :src="placeholder.svg" />
    </v-card>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import firebase from 'firebase'

export default {
  data() {
    return {
      left: [],
      urls: [],
      right: [],
      center: [],
      svgTxt: null,
      blockPhotos: [],
      showPopup: false,
      selectedColor: null,
      hasPlaceholder: false,
      selectedOrientation: null,
      placeholder: {
        url: require('@/assets/placeholder.svg')
      },
    }
  },

  computed: {
    ...mapState({
      svgs: state => state.blocks.svgs
    }),
  },

  props: {
    data: Object,
    withName: Boolean,
    selectable: Boolean,
    block: {
      type: Object,
      required: true,
    },
  },

  watch: {
    'blockPhotos': function (photos) {
      if (photos.length) {

        if (this.block.orientations.includes('left')) {
          this.left.forEach(item => {
            photos.forEach(photo => {
              if (photo.name == item && !this.hasPlaceholder) {
                this.placeholder = photo
                this.hasPlaceholder = true
              }
            })
          })
        }
        else if (this.block.orientations.includes('center')) {
          this.center.forEach(item => {
            photos.forEach(photo => {
              if (photo.name == item && !this.hasPlaceholder) {
                this.placeholder = photo
                this.hasPlaceholder = true
              }
            })
          })
        }
        else {
          this.right.forEach(item => {
            photos.forEach(photo => {
              if (photo.name == item && !this.hasPlaceholder) {
                this.placeholder = photo
                this.hasPlaceholder = true
              }
            })
          })
        }
      }
    },
  },

  methods: {
    ...mapActions('blocks', [
      'incrementUseCount'
    ]),

    checkImages() {
      var storage  = firebase.storage()

      if (this.block.orientations) {
        this.block.orientations.forEach(orientation => {
          this.svgs.forEach(svg => {
            if(svg.name.includes(orientation)) {
              if (this.block[svg.name]) {
                let fileSvg = this.$store.getters['images/getBlockSvg'](this.block[svg.name])

                if (fileSvg) {
                  this.blockPhotos.push({
                    svg: fileSvg,
                    name: svg.name,
                    file: this.block[svg.name],
                    color: this.getColor(svg.type),
                    orientation: this.getOrientation(svg.name)
                  })
                }
                else {
                  storage.ref(`blocks/${this.block[svg.name]}`)
                  .getDownloadURL()
                  .then(url => {
                    var xhr = new XMLHttpRequest()
                    xhr.responseType = 'blob'
                    this.urls.push(url)
                
                    xhr.onload = () => {
                      let svgText = xhr.response
  
                      var reader = new FileReader()
                      reader.readAsDataURL(svgText)
                      reader.onloadend = () => {
                        var base64data = reader.result
                        
                        this.blockPhotos.push({
                          svg: base64data,
                          name: svg.name,
                          file: this.block[svg.name],
                          color: this.getColor(svg.type),
                          orientation: this.getOrientation(svg.name)
                        })

                        this.$store.commit('images/addBlock', { file: this.block[svg.name], svg: base64data, url })
                      }
                    }
                      
                    xhr.open('GET', url)
                    xhr.send()
                  })
                  .catch(error => {
                    console.log(error.message)
                  })
                }

              }
            }
          })
        })
      }
    },

    /*------------------------------------------------------------------------------
     * CHECK IF BLOCK HAS TYPES
     *----------------------------------------------------------------------------*/
    getColor(blockType) {
      if (blockType.toLowerCase().includes('dark'))  return 'dark'
      if (blockType.toLowerCase().includes('grey'))  return 'grey'
      if (blockType.toLowerCase().includes('light'))  return 'light'
    },

    /*------------------------------------------------------------------------------
     * CHECK IF BLOCK HAS ORIENTATIONS
     *----------------------------------------------------------------------------*/
    getOrientation(orientation) {
      if (orientation.toLowerCase().includes('left'))  return 'left'
      if (orientation.toLowerCase().includes('center'))  return 'center'
      if (orientation.toLowerCase().includes('right'))  return 'right'
    },

    /*------------------------------------------------------------------------------
     * CHECK IF COLOR EXISTS
     *----------------------------------------------------------------------------*/
    checkColor(color) {
      return !!this.blockPhotos.find(item => {
        return item.orientation == this.placeholder.orientation && item.color == color
      })
    },

    /*------------------------------------------------------------------------------
     * SET PLACEHOLDER IF COLOR SELECTED
     *----------------------------------------------------------------------------*/
    setPlaceholderByColor(color) {
      this.selectedColor = color

      this.blockPhotos.forEach(item => {
        if (item.orientation == this.placeholder.orientation && item.color == color) {
          this.placeholder = item
        }
      })
    },

    /*------------------------------------------------------------------------------
     * SET PLACEHOLDER IF ORIENTATION IS SELECTED
     *----------------------------------------------------------------------------*/
    setPlaceholderByOrientation(orientation) {
      let set = false
      this.selectedOrientation = orientation

      this.blockPhotos.forEach(item => {
        if (item.orientation == orientation && !set) {
          this.placeholder = item
          set = true
        }
      })
    },

    /*------------------------------------------------------------------------------
     * IF BLOCK IS SELECTED
     *----------------------------------------------------------------------------*/
    selectBlock() {
      let data = this.placeholder
      data.block = this.block.id
      this.incrementUseCount(this.block)
      this.$emit('selected', data)
    }
  },

  created() {
    this.svgs.forEach(item => {
      if (item.name.toLowerCase().includes('left')) {
        this.left.push(item.name)
      }
      else if (item.name.toLowerCase().includes('center')) {
        this.center.push(item.name)
      }
      else {
        this.right.push(item.name)
      }
    })

    this.checkImages()
  }
}

</script>

<style lang="scss">

.block-svg {
  svg {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

</style>
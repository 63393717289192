<template>
  <div>
    <v-form 
      v-if="!!settings" 
      ref="pageForm" 
      @submit.prevent="validateForm()"
    >
      <v-text-field
        v-model="settings.name"
        label="Page Name"
        :rules="[rules.required]"
        class="subtle-outline"
        outlined
        dense
      >
        <template v-slot:append>
          <v-btn 
            v-clipboard:copy="settings.name"
            v-clipboard:success="copied"
            icon
            x-small
          >
            <v-icon color="primary lighten-3">mdi-content-copy</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <template v-if="$store.state.user.user">
        <v-select
          v-if="pages.length && !settings.home && !settings.nonNavigation"
          v-model="settings.parent"
          label="Select Parent Page"
          class="subtle-outline"
          :items="filtered"
          item-text="name"
          item-value="id"
          clearable
          outlined
          dense
        ></v-select>

        <v-checkbox 
          v-model="settings.nonNavigation"
          label="Non-Navigation Page"
          class="mt-n2"
          dense
        ></v-checkbox>
        
        <v-checkbox 
          v-if="!settings.parent"
          v-model="settings.home"
          label="Set as homepage"
          class="mt-n2"
          dense
        ></v-checkbox>

        <v-select
          v-model="settings.header"
          :items="['global', 'custom', 'none']"
          @change="selectTemplateFunc($event, 'header')"
          class="subtle-outline"
          label="Header"
          outlined
        ></v-select>

        <v-card v-if="customHeader" class="mb-7" light>
          <v-btn
            @click="removeCustom('header')"
            class="mr-n6 mt-2"
            width="20"
            height="20"
            absolute
            x-small
            right
            fab
            top
          >
            <v-icon small>mdi-close</v-icon>  
          </v-btn>

          <v-card-text class="pa-0">
            <block-image
              :file="customHeader.file"
            />
          </v-card-text>
        </v-card>
        
        <v-select
          v-model="settings.footer"
          :items="['global', 'custom', 'none']"
          @change="selectTemplateFunc($event, 'footer')"
          class="subtle-outline"
          label="Footer"
          outlined
        ></v-select>

        <v-card v-if="customFooter" class="mb-7" light>
          <v-btn
            @click="removeCustom('footer')"
            class="mr-n6 mt-2"
            width="20"
            height="20"
            absolute
            x-small
            right
            fab
            top
          >
            <v-icon small>mdi-close</v-icon>  
          </v-btn>

          <v-card-text class="pa-0">
            <block-image
              :file="customFooter.file"
            />
          </v-card-text>
        </v-card>
      </template>


      <v-btn
        @click="validateForm()"
        :loading="status.creating"
        outlined
        small
      >Save Changes</v-btn>
    </v-form>

    <!-- SELECT TEMPLATE -->
    <select-template
      v-if="showSelectTemplate"
      :type="type"
      :show="showSelectTemplate"
      @close="closeSelectTemplate()"
      @selected="blockSelected"
    />
  </div>
</template>

<script>

import SelectTemplate from '@/components/SelectTemplateDialog'
import BlockImage from '@/components/BlockImage'
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import _camelCase from 'lodash/camelCase'
import _omit from 'lodash/omit'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    show: Boolean
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    SelectTemplate,
    BlockImage
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      type: null,
      showSelectTemplate: false,
      customHeader: null,
      customFooter: null,
      children: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.page.status,
      selected: state => state.page.selected,
      pages: state => state.page.pages,
    }),

    filtered: function () {
      let pages = []

      if (this.pages.length) {
        
        this.pages.forEach(page => {
          if (page.id !== this.selected.id && page.parent !== this.selected.id && !this.children.includes(page.id)) {
            pages.push(_omit(page, ['header']))
          }
        })
      }

      return pages
    },

    settings: function () {
      let data = null

      if (this.selected) {
        data = Object.assign({}, this.selected)
        data.header = data.header || 'global'
        data.footer = data.footer || 'global'
      }
      
      return data
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'selected': function () {
      this.type = null
      this.showSelectTemplate = false
      this.customHeader = null
      this.customFooter = null
      this.checkCustomHeaderAndFooter()
      this.children = []
      
      if (this.pages.length) {
        this.getChildren(this.pages.find(p => p.id == this.selected.id))
      }
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('page', [
      'update'
    ]),

    validateForm() {
      if (this.$refs.pageForm.validate()) {

        if (this.customHeader) {
          this.settings.customHeader = {
            type: 'header',
            block: this.customHeader.block,
            color: this.customHeader.color,
            orientation: this.customHeader.orientation,
          }
        }

        if (this.customFooter) {
          this.settings.customFooter = {
            type: 'footer',
            block: this.customFooter.block,
            color: this.customFooter.color,
            orientation: this.customFooter.orientation,
          }
        }

        let data = {
          id: this.$route.params.id,
          page: this.settings
        }

        this.update(data)
      }
    },

    closeSelectTemplate() {
      if (!this.customHeader) this.settings.header = this.selected.header
      if (!this.customFooter) this.settings.footer = this.selected.footer
     
      this.type = null
      this.showSelectTemplate = false
    },

    selectTemplateFunc(val, type) {
      if (val == 'custom') {
        this.type = type
        this.showSelectTemplate = true
      }
      else if (val == 'global') {
        this.customHeader = null
      }
    },

    blockSelected(block) {
      if (this.type == 'header') this.customHeader = block
      else this.customFooter = block
      
      this.closeSelectTemplate()
    },

    removeCustom(type) {
      if (type == 'header') {
        this.customHeader = null
        this.settings.header = this.selected.header == 'custom' ? 'global' : this.selected.header
      }
      else if (type == 'footer') {
        this.customFooter = null
        this.settings.footer = this.selected.footer == 'custom' ? 'global' : this.selected.footer
      }
    },

    checkCustomHeaderAndFooter() {
      // IF CUSTOM HEADER
      if (this.selected && this.selected.header == 'custom') {
        let header = this.selected.customHeader
        
        Promise.all([
          this.$getBlock(header.block)
        ])
        .then(response => {
          let block = response[0]
          let index = _camelCase(`${header.orientation}-${header.color}-img`)
          this.customHeader = header
          this.customHeader.file = block[index]
        })
      }

      // IF CUSTOM FOOTER
      if (this.selected && this.selected.footer == 'custom') {
        let footer = this.selected.customFooter
        
        Promise.all([
          this.$getBlock(footer.block)
        ])
        .then(response => {
          let block = response[0]
          let index = _camelCase(`${footer.orientation}-${footer.color}-img`)
          this.customFooter = footer
          this.customFooter.file = block[index]
        })
      }
    },

    getChildren(page) {
      if (page.children.length) {
        page.children.forEach(child => {
          this.children.push(child.id)

          if (child.children.length) {
            this.getChildren(child)
          }
        })
      }
    },

    copied() {
      this.$store.dispatch('showSuccess', 'Copied to clipboard')
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.selected) {
      this.checkCustomHeaderAndFooter()
    }

    if (this.pages.length && this.selected) {
      this.getChildren(this.pages.find(p => p.id == this.selected.id))
    }
  }
}

</script>
import db from '@/firebase/init'
import firebase from 'firebase'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  templates: [],
  categories: [],
  status: {
    saving: false,
    getting: false,
    deleting: false,
    creating: false,
    firstLoad: false,
    addingCategory: false,
    deletingCategory: false,
    gettingCategories: false,
    deletingTemplate: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  addingCategoryState(state, bol) {
    state.status.addingCategory = bol
  },

  addCategoryCollection(state, payload) {
    let category = state.categories.find(c => c.id == payload.id)
    if (!category) state.categories.push(payload)
    else Vue.set(state.categories, state.categories.indexOf(category), payload)
  },

  gettingCategoriesState(state, bol) {
    state.status.gettingCategories = bol
  },

  setCategories(state, payload) {
    state.categories = []

    if (payload.size) {
      payload.forEach(doc => {
        let category = doc.data()
        category.id = doc.id
        category.ref = doc.ref
        
        if (!state.categories.find(c => c.id == category.id))
          state.categories.push(category)
      })
    }

    state.status.gettingCategories = false
  },

  deletingCategoryState(state, bol) {
    state.status.deletingCategory = bol
  },

  removeCategory(state, payload) {
    state.categories.splice(state.categories.indexOf(payload), 1)
  },

  savingState(state, bol) {
    state.status.saving = bol
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  setTemplates(state, payload) {
    state.templates = []

    if (payload.size) {
      payload.forEach(doc => {
        let template = doc.data()
        template.id = doc.id
        template.ref = doc.ref
        state.templates.push(template)
      })
    }

    state.status.getting = false
    state.status.firstLoadState = true
  },

  addTemplate(state, payload) {
    if (!state.templates.find(t => t.id == payload.id))
      state.templates.push(payload)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeTemplate(state, payload) {
    let template = state.templates.find(t => t.id == payload.id)
    state.templates.splice(state.templates.indexOf(template), 1)
  },

  creatingState(state, bol) {
    state.status.creating = bol
  },

  mutateTemplate(state, payload) {
    let template = state.templates.find(t => t.id == payload.id)
    Vue.set(state.templates, state.templates.indexOf(template), payload)
  },

  firstLoadState(state, bol) {
    state.status.firstLoad = bol
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * ADD CATEGORY
   *
   * @PARAMS
   * $title
   *----------------------------------------------------------------------------*/
  async addCategory({ commit, dispatch }, category) {
    commit('addingCategoryState', true)
    let user = firebase.auth().currentUser
    let data = {
      name: category.name,
      created: firebase.firestore.Timestamp.now(),
      user: user.uid
    }

    let categoryRef = db.collection('categories')
    .doc('projectTemplates')
    .collection('categories')

    if (category.id) categoryRef = categoryRef.doc(category.id)
    else categoryRef = categoryRef.doc()
    
    await categoryRef
    .set(data)
    .then(() => {
      data.ref = categoryRef
      data.id = categoryRef.id
      commit('addingCategoryState', false)
      commit('addCategoryCollection', data)
      dispatch('showSuccess', `Category ${ category.id ? 'updated' : 'added' }`, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('addingCategoryState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET CATEGORIES
   *----------------------------------------------------------------------------*/
  getCategories({ commit }) {
    commit('gettingCategoriesState', true)
    
    db.collection('categories')
    .doc('projectTemplates')
    .collection('categories')
    .get()
    .then((snapshot) => {
      commit('setCategories', snapshot)
    })
    .catch(error => {
      commit('gettingCategoriesState', false)
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE CATEGORY
   *
   * @PARAMS
   * $category
   *----------------------------------------------------------------------------*/
  async deleteCategory({ commit, dispatch }, category) {
    commit('deletingCategoryState', true)
    
    await category.ref.delete()
    .then(() => {
      commit('removeCategory', category)
      commit('deletingCategoryState', false)
      dispatch('showSuccess', 'Category deleted', { root: true }) 
    })
    .catch(error => {
      console.log(error.message)
      commit('deletingCategoryState', false)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
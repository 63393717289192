import firebase from 'firebase'
import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const state = {
  status: {
    creating: false
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * CREATE FEEDBACK
 *----------------------------------------------------------------------------*/
const actions = {
  async createFeedback({ commit, dispatch }, data) {
    commit('updateStatus', { creating: true })
    let user = firebase.auth().currentUser
    
    data.created = firebase.firestore.Timestamp.now()
    data.user = user.uid
    
    await db.collection('feedbacks')
    .doc(data.order).set(data)
    .then(() => {
      commit('updateStatus', { creating: false })
      dispatch('showSuccess', 'Thank you! Your feedback has been submitted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { creating: false })
      dispatch('showError', error.message, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
import db from '@/firebase/init'
import firebase from 'firebase'
import { omit } from 'lodash'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  terms: [],
  data: {},
  status: {
    adding: false,
    getting: true,
    deleting: false,
    firstLoad: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  term: (state) => (id) => {
    let term = state.terms.find(t => t.id == id)
    return term || {}
  },

  publishedTerms: (state) => {
    return state.terms.filter(t => {
      return t.published
    })
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  addTerm(state, payload) {
    let term = state.terms.find(t => t.id == payload.id)
    if (!term) state.terms.push(payload)
    else Vue.set(state.terms, state.terms.indexOf(term), payload)

    state.status.adding = false
  },

  setTerms(state, payload) {
    state.terms = []

    if (payload.size) {
      payload.forEach(doc => {
        let term = doc.data()
        term.id = doc.id
        term.ref = doc.ref
        if (!state.terms.find(t => t.id == term.id))
          state.terms.push(term)
      })
    }

    state.status.getting = false
  },

  setData(state, payload) {
    Object.assign(state.data, payload)
  },

  mutateTermStatus(state, payload) {
    payload.term.published = payload.status
  },

  removeTerm(state, payload) {
    let term = state.terms.find(t => t.id == payload.id)
    state.terms.splice(state.terms.indexOf(term), 1)
  },

  addData(state, payload) {
    let term = state.terms.find(t => t.id == payload.id)
    if (!term) state.terms.push(payload)
    else Vue.set(state.terms, state.terms.indexOf(term), payload)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE TERM
   *----------------------------------------------------------------------------*/
  async createOrderTerm({ state, commit, dispatch }) {
    commit('updateStatus', { adding: true })
    let user = firebase.auth().currentUser
    let docRef = db.collection('order_terms')
    let data = {}
    Object.assign(data, state.data)

    if (data.id) {
      data.updated = firebase.firestore.Timestamp.now()
      data.last_updated_by = user.uid
    }
    else {
      data.created = firebase.firestore.Timestamp.now()
      data.published = false
      data.user = user.uid
    }

    if (data.id) docRef = docRef.doc(data.id)
    else docRef = docRef.doc()

    await docRef
    .set(omit(data, ['id', 'ref']))
    .then(() => {
      data.ref = docRef
      data.id = docRef.id
      commit('addTerm', data)
      dispatch('showSuccess', 'Term saved', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { adding: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET TERMS
   *----------------------------------------------------------------------------*/
  getTerms({ commit, dispatch }, status = null) {
    commit('updateStatus', { getting: true })
    if (status === null) commit('updateStatus', { firstLoad: true })
    
    let taskRef = db.collection('order_terms')

    if (status === true ||  status === false) {
      taskRef = taskRef.where('published', '==', status)
    }
    
    taskRef.get()
    .then(snapshot => {
      commit('setTerms', snapshot)

      if (snapshot.size) {
        snapshot.forEach(doc => {
          dispatch('users/getUserUid', doc.data().user, { root: true })
          if (doc.data().last_updated_by) dispatch('users/getUserUid', doc.data().last_updated_by, { root: true })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * PUBLISH TERM
   *----------------------------------------------------------------------------*/
  updatePublishStatus({ commit, dispatch }, data) {
    let term = data.term
    let status = data.status

    term.ref
    .update({ published: status })
    .then(() => {
      commit('mutateTermStatus', data)
      dispatch('showSuccess', 'Term status updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE TERM
   *----------------------------------------------------------------------------*/
  async deleteTerm({ commit, dispatch }, term) {
    commit('updateStatus', { deleting: true })
    
    await term.ref
    .delete()
    .then(() => {
      commit('updateStatus', { deleting: false })
      commit('removeTerm', term)
      dispatch('showSuccess', 'Term deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deleting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET TERM BY ID
   *----------------------------------------------------------------------------*/
  getTerm({ state, commit, dispatch }, id) {
    if (!state.terms.find(t => t.id == id)) {
      db.collection('order_terms')
      .doc(id).get()
      .then((doc) => {
        if (doc.exists) {
          let term = doc.data()
          term.id = doc.id
          term.data = doc.data
          commit('addData', term)
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
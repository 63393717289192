import db from '@/firebase/init'
import Vue from 'vue'
import { omit } from 'lodash'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const initialState = () => {
  return {
    header: null,
    footer: null,
    task: null,
    sections: [],
    actionType: null,
    appendPrepend: null,
    selected: null,
    shortcodes: {
      header: null,
      footer: null,
      customHeader: null,
      customFooter: null,
      body: [],
    },
    csscodes: {
      header: null,
      footer: null,
      customHeader: null,
      customFooter: null,
      body: [],
    },
    status: {
      creating: false,
      deleting: false,
      drawer: false,
    },
  }
}

const state = initialState()

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  creatingState(state, bol) {
    state.status.creating = bol
  },

  setTaskType(state, type) {
    state.task = type
  },

  setHeaderFooter(state, payload) {
    state[payload.type] = payload
  },

  setHeader(state, payload) {
    let data
    
    if (payload) {
      try { data = Vue.prototype.$formatData(payload) }
      catch { data = payload }
      state.header = data
    }
    else {
      state.header = null
    }
  },

  setFooter(state, payload) {
    let data

    if (payload) {
      try { data = Vue.prototype.$formatData(payload) }
      catch { data = payload  }
      state.footer = data
    }
    else {
      state.footer = null
    }
  },

  setBody(state, payload) {
    let data = payload
    
    if (state.actionType) {
      let index = state.sections.indexOf(state.appendPrepend)
      if (state.actionType == 'append') state.sections.splice(index + 1, 0, data)
      else if (state.actionType == 'prepend') {
        if (index == 0) state.sections.unshift(data)
        else state.sections.splice(index, 0, data)
      }
    }
    else {
      state.sections.push(data)
    }

    // RESET VALUES
    state.appendPrepend = null
    state.actionType = null
  },

  resetState(state) {
    Object.assign(state, initialState())
  },

  deleting(state, bol) {
    state.status.deleting = bol
  },

  removeSection(state, payload) {
    if (['header', 'footer'].includes(payload.type)) {
      state[payload.type] = null
      state.csscodes[payload.type] = null
      state.shortcodes[payload.type] = null
    }
    else {
      state.sections.splice(state.sections.indexOf(payload), 1)
    }
  },

  setSections(state, payload) {
    state.sections = []

    payload.forEach(section => {
      let data = Vue.prototype.$formatData(section)
      
      if (data.type == 'header') state.header = data
      if (data.type == 'footer') state.footer = data
      else state.sections.push(data)
    })
  },

  clearSections(state) {
    state.sections = []
  },

  updateSection(state, data) {
    let id = data.id
    let newData = data.newData
    let newCss = Vue.prototype.$cssCodes(data.block, newData)
    let newShortcode = Vue.prototype.$shortcodes(data.block, newData)

    if (['header', 'footer'].includes(newData.type)) {
      newData.id = newData.type == 'header' ? state.header.id : state.footer.id
      newData.ref = newData.type == 'header' ? state.header.ref : state.footer.ref
      
      state[newData.type] = newData
      state.csscodes[newData.type] = newCss.data
      state.shortcodes[newData.type] = newShortcode.data
    }
    else {
      state.sections.forEach((section, i) => {
        if (section.id == id) {
          newData.id = section.id
          newData.ref = section.ref
          Vue.set(state.sections, i, newData)
          
          Vue.set(state.csscodes.body, i, newCss)
          Vue.set(state.shortcodes.body, i, newShortcode)
        }
      })
    }

    state.task = null
  },

  setActionType(state, payload) {
    state.actionType = payload.action
    state.appendPrepend = payload.section
  },

  updateSections(state, payload) {
    state.sections = payload

    state.sections.forEach((section, i) => {
      let data = section
      data.order = i
      Vue.set(section, i, data)
    })

  },

  setShortcodes(state, payload) {
    if(['header', 'footer', 'customHeader', 'customFooter'].includes(payload.type)) {
      state.shortcodes[payload.type] = payload.data
    }
    else {
      if (!state.shortcodes.body.find(body => body.id == payload.id)) {
        state.shortcodes.body.push(payload)
      }
    }
  },
  
  setCssCodes(state, payload) {
    if(['header', 'footer', 'customHeader', 'customFooter'].includes(payload.type)) {
      state.csscodes[payload.type] = payload.data
    }
    else {
      if (!state.csscodes.body.find(body => body.id == payload.id)) {
        state.csscodes.body.push(payload)
      }
    }
  },

  reorderBodyShortcodeCss(state) {
    // REORDER SHORTCODES and CSS
    state.shortcodes.body.forEach((shortcode, i) => {
      let section = state.sections.find(section => section.id == shortcode.id)

      if (section) {
        shortcode.order = section.order
        Vue.set(state.shortcodes.body, i, shortcode)

        let css = state.csscodes.body.find(css => css.id == section.id)
        
        if (css) {
          css.order = section.order
          Vue.set(state.csscodes.body, i, css)
        }
      }
      else {
        state.shortcodes.body.splice(i, 1)
        state.csscodes.body.splice(i, 1)
      }

    })
  },

  emptyCodes(state) {
    state.shortcodes.body = []
    state.csscodes.body = []
  },

  drawerState(state, type) {
    state.status.drawer = type
  },

  setSelected(state, payload) {
    state.selected = payload
  },

  mutateSection(state, payload) {
    if (payload.type == 'header') state.header = payload
    else if (payload.type == 'footer') state.footer = payload
    else {
      let section = state.sections.find(s => s.id == payload.id)
      Vue.set(state.sections, state.sections.indexOf(section), payload)
    }
  }

}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE PAGE SECTION
   *----------------------------------------------------------------------------*/
  async create({ state, commit, dispatch, rootState }, data) {
    dispatch('showUpdating', true, { root: true })
    commit('setTaskType', 'create')
    let project = rootState.project.project
    let page = rootState.page.selected
    let isHeaderFooter = ['header', 'footer'].includes(data.type)
    let ref
    
    let sectionData = {
      block: data.block,
      orientation: data.orientation,
      color: data.color,
      createdAt: Date.now(),
      order: state.sections.length,
      type: data.type,
      category: data.category,
    }

    if (isHeaderFooter) ref = project.ref.collection('globals').add(sectionData)
    else ref = page.ref.collection('sections').add(sectionData)
    
    ref.then((docRef) => {
      dispatch('showSuccess', 'Panel selected', { root: true })
      dispatch('showUpdating', false, { root: true })
      
      let data = sectionData
      data.id = docRef.id
      data.ref = docRef
      
      if (isHeaderFooter) commit('setHeaderFooter', data)
      else commit('setBody', data)
    })
    .catch(error => {
      commit('creatingState', false)
      dispatch('showUpdating', false, { root: true })
      dispatch('showError', error.message, { root: true })
      console.log('sections.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * REPLACE SECTION
   *----------------------------------------------------------------------------*/
  async replace({ commit, dispatch }, data) {
    dispatch('showUpdating', true, { root: true })
    commit('setTaskType', 'replace')
    let section = data.section
    let toReplace = data.toReplace

    let sectionData = {
      type: section.type,
      block: section.block,
      orientation: section.orientation,
      color: section.color,
      order: toReplace.order,
      updatedAt: Date.now()
    }

    if (toReplace) {
      await toReplace.ref.update(sectionData)
      .then(async () => {
        dispatch('showSuccess', 'Section updated', { root: true })
        dispatch('showUpdating', false, { root: true })
  
        await db.collection('blocks')
        .doc(section.block)
        .get()
        .then(doc => {
          let block = doc.data()
          block.id = doc.id
          block.ref = doc.ref
          let newData = sectionData
          newData.id = toReplace.id
          newData.order = toReplace.order
  
          commit('updateSection', {
            id: toReplace.id,
            newData,
            block,
          })
        })
  
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        dispatch('showUpdating', false, { root: true })
        console.log('sections.js', error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * GET SECTION AFTER ADD
   *----------------------------------------------------------------------------*/
  async getSection({ commit, dispatch, rootState }, id) {
    let page = rootState.page.selected
    
    await page.ref.collection('sections')
    .doc(id).get()
    .then(doc => {
      if (doc.exists) {
        if (doc.data().type == 'header') commit('setHeader', doc)
        else if (doc.data().type == 'footer') commit('setFooter', doc)
        else commit('setBody', doc)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log('sections.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET SECTIONS ON LOAD
   *----------------------------------------------------------------------------*/
  async getSections({ commit, dispatch }, page) {
    let hasSections = false
    
    await page.ref.collection('sections')
    .orderBy('order', 'asc')
    .get().then(snapshot => {
      if (snapshot.size) {
        hasSections = true
        commit('setSections', snapshot)
      }
      else {
        commit('clearSections')
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log('sections.js', error.message)
    })

    return hasSections
  },
  
  /*------------------------------------------------------------------------------
   * GET SECTIONS ON LOAD
   *----------------------------------------------------------------------------*/
  async getHeaderSection({ commit, dispatch, rootState }) {
    let globalHeader = null
    
    if (rootState.project.project) {
      await db.collection('projects')
      .doc(rootState.project.project.id)
      .collection('globals')
      .where('type', '==', 'header')
      .limit(1).get()
      .then(snapshot => {
        if (snapshot.size) {
          if (rootState.page.selected && rootState.page.selected.header !== 'custom') {
            commit('setHeader', snapshot.docs[0])
            globalHeader = true
          }
          else {
            let doc = snapshot.docs[0]
            let data = Vue.prototype.$formatData(doc)
            globalHeader = data
          }
        }
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        console.log('sections.js', error.message)
      })
    }

    return globalHeader
  },

  /*------------------------------------------------------------------------------
   * GET SECTIONS ON LOAD
   *----------------------------------------------------------------------------*/
  async getFooterSection({ commit, dispatch, rootState }) {
    let globalFooter = null

    if (rootState.project.project) {
      await db.collection('projects')
      .doc(rootState.project.project.id)
      .collection('globals')
      .where('type', '==', 'footer')
      .limit(1).get()
      .then(snapshot => {
        if (snapshot.size) {
          if (rootState.page.selected && rootState.page.selected.footer !== 'custom') {
            commit('setFooter', snapshot.docs[0])
            globalFooter = true
          }
          else {
            let doc = snapshot.docs[0]
            let data = Vue.prototype.$formatData(doc)
            globalFooter = data
          }
        }
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        console.log('sections.js', error.message)
      })
    }

    return globalFooter
  },

  /*------------------------------------------------------------------------------
   * DELETE SECTION
   *----------------------------------------------------------------------------*/
  async delete({ commit, dispatch }, section) {
    commit('deleting', true)
    commit('setTaskType', 'delete')

    await section.ref.delete()
    .then(() => {
      commit('deleting', false)
      commit('removeSection', section)
      dispatch('showSuccess', 'Page section deleted.', { root: true })
    })
    .catch(error => {
      commit('deleting', false)
      dispatch('showError', error.message, { root: true })
      console.log('sections.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE SECTIONS
   *----------------------------------------------------------------------------*/
  deleteSections({ dispatch }, page) {
    db.collection('sections')
    .where('page', '==', page)
    .where('type', '==', 'body')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        snapshot.forEach(doc => {
          dispatch('delete', doc)
        })
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log('sections.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE SECTION ORDERING
   *----------------------------------------------------------------------------*/
  updateSectionOrder({ state, dispatch }) {
    if (state.sections.length) {
      state.sections.forEach((section, i) => {
        section.ref.update({ order: i })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
          console.log('sections.js', error.message)
        })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * DUPLICATE SECTION
   *----------------------------------------------------------------------------*/
  duplicate({ commit, dispatch }, section) {
    commit('setTaskType', 'duplicate')

    commit('setActionType', {
      action: 'append',
      section: section
    })

    let sectionData = {
      type: 'body',
      block: section.block,
      orientation: section.orientation,
      color: section.color,
    }

    dispatch('create', sectionData)
  },

  /*------------------------------------------------------------------------------
   * SELECT SECTION
   *----------------------------------------------------------------------------*/
  select({ commit }, data) {
    commit('drawerState', data.view)
    if (data.section) commit('setSelected', data.section)
  },

  /*------------------------------------------------------------------------------
   * CLOSE DRAWER
   *----------------------------------------------------------------------------*/
  closeDrawer({ commit }) {
    commit('drawerState', false)
    commit('setSelected', null)
  },

  /*------------------------------------------------------------------------------
   * DELETE SECTIONS
   *----------------------------------------------------------------------------*/
  async deletePageSections({ state, commit }) {
    commit('page/creatingFromTemplateState', true, { root: true })

    if (state.sections.length) {
      let batch = db.batch()

      state.sections.forEach(section => {
        batch.delete(section.ref)
      })

      await batch.commit()
    }
  },

  /*------------------------------------------------------------------------------
   * UPDATE SECTION DATA
   *----------------------------------------------------------------------------*/
  updateSectionData({ commit, dispatch }, section) {
    section.ref.update(omit(section, ['id', 'ref']))
    .then(() => {
      commit('mutateSection', section)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
<template>
  <div class="mt-4">
    <v-card
      v-if="false"
      class="mt-3 mb-1 mx-auto"
    >
      <v-card-text class="px-7 py-2">
        <v-list-item :to="{ name: 'AccountSettings' }" class="pa-0">
          <v-list-item-avatar size="70" style="overflow: visible">
            <v-badge
              color="success"
              offset-x="14"
              offset-y="14"
              :value="user.organization"
              bordered
              overlap
              bottom
              dot
            >
              <UserPhoto :size="70" photoSize="thumb" :id="user.userid" />
            </v-badge>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="subtitle-1">{{ user.fullName }}</span>
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              {{ user.organization ? user.employeeRole : user.role }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
    
    <v-sheet 
      v-if="$store.state.user.user && $store.state.user.user.role == 'client'"
      :style="{ paddingLeft: '13px', paddingRight: '13px' }"
      color="transparent"
      class="mb-3" 
    >
      <v-btn
        :to="{ name: 'SelectRequestType' }"
        class="skyblue white--text"
        block
        raised
      >
        Create A Request
      </v-btn>
    </v-sheet>

    <v-card
      class="mx-3"
    >
      <v-card-text class="px-7 py-2">
        <v-list class="admin-menu">
          <v-list-item-group class="align-center">
            <template v-for="(item, i) in guardedItems">
              <v-list-item
                :key="i"
                :to="{ name: item.route }"
                class="pl-0"
                :exact="item.exact"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon size="20">mdi-{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="admin-item-title">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>

            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AdminMenu',

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      items: [
        {
          name: 'Users',
          route: 'Admin',
          icon: 'account-group-outline',
          roles: ['admin'],
          manage: 'manageUsers',
          exact: true,
        },
        // {
        //   name: 'All Projects',
        //   route: 'AllProjects',
        //   icon: 'folder-table-outline',
        //   roles: ['admin'],
        //   manage: 'manageProjects',
        //   exact: true,
        // },
        // {
        //   name: 'My Projects',
        //   route: 'Projects',
        //   icon: 'folder-account-outline',
        //   roles: ['all'],
        //   exact: true,
        // },
        // {
        //   name: 'Orders',
        //   route: 'InProgressOrders',
        //   icon: 'form-select',
        //   roles: ['admin', 'client'],
        //   manage: 'manageOrders',
        //   exact: false,
        // },
        {
          name: 'Requests',
          route: 'OpenRequests',
          icon: 'ticket-confirmation-outline',
          roles: ['admin', 'client'],
          organization: true,
          exact: false,
        },
        {
          name: 'Company Profiles',
          route: 'BrandProfiles',
          icon: 'palette-swatch-variant',
          roles: ['client', 'admin'],
          organization: true,
          exact: false,
        },
        // {
        //   name: 'Page Templates',
        //   route: 'PageTemplates',
        //   icon: 'page-layout-header-footer',
        //   roles: ['admin'],
        //   manage: 'managePageTemplates',
        //   exact: true,
        // },
        // {
        //   name: 'Project Templates',
        //   route: 'ProjectTemplates',
        //   icon: 'chart-tree',
        //   roles: ['admin'],
        //   manage: 'manageProjectTemplates',
        //   exact: true,
        // },
        // {
        //   name: 'Panels',
        //   route: 'Panels',
        //   icon: 'cube-unfolded',
        //   roles: ['admin'],
        //   manage: 'manageBlocks',
        //   exact: true,
        // },
        {
          name: 'Statistics',
          route: 'RequestStats',
          icon: 'chart-bell-curve-cumulative',
          roles: ['admin'],
          exact: false,
        },
        {
          name: 'Account Settings',
          route: 'AccountSettings',
          icon: 'account-cog-outline',
          roles: ['all'],
          exact: true,
        },
        {
          name: 'General Settings',
          route: 'GeneralSettings',
          icon: 'cog-outline',
          roles: ['admin'],
          exact: true,
        },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user
    }),

    guardedItems: function () {
      return this.items.filter(item => {
        return item.separator 
               || item.roles.includes('all') 
               || item.roles.includes(this.user ? this.user.role : '')
               || (this.user && this.user.organization && this.user[item.manage])
               || item.organization && (this.user && this.user.organization)
      })
    }
  },
}

</script>

<style type="text" lang="scss">
  $defaultColor: rgba(19,45,61,0.5);
  $activeColor: rgba(19,45,61,1);

  .admin-menu {
    .v-icon {
      color: $defaultColor;
    }

    .v-list-item__title.admin-item-title {
      font-size: 13px !important;
      color: $defaultColor;
      transition: all 0.3s;
      -moz-transition: all 0.3s;
      -webkit-transition: all 0.3s;

    }

    .v-item--active:before,
    .v-item:before {
      background: transparent;
    }
    
    .v-list-item {
      &:hover {
        .v-list-item__title.admin-item-title ,
        .v-icon {
          color: $activeColor !important;
        }
      }

      &.v-item--active {
        .v-list-item__title.admin-item-title {
          color: $activeColor !important;
        }
      }
    }

    // .v-divider {
    //   border-width: 1px !important;
    // }

    // .v-list-item:not(:last-child) {
    //   border-bottom: 1px solid #ebebeb !important;
    // }

    .v-list-item--link:before {
      display: none !important;
    }
  }
</style>

<template>
  <v-card-text class="pt-5">
    <h5 class="text-center text-h5 font-weight-bold mb-5">Reset Password</h5>

    <v-form class="mb-3" ref="resetForm">
      <v-text-field
        v-model="data.email"
        :rules="[rules.required, rules.email]"
        label="Email Address"
        hide-details
        outlined
      ></v-text-field>
    </v-form>

    <v-btn
      @click="validateForm()"
      :loading="status.resetting"
      color="accent"
      class="gradient"
      block
    >Submit</v-btn>

    <div class="text-center mt-5 caption">Back to <a href="#" @click.prevent="$emit('login')">login</a> page.</div>
  </v-card-text>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  data() {
    return {
      rules,
    }
  },

  computed: {
    ...mapState({
      status: state => state.user.status,
      data: state => state.user.data
    })
  },

  methods: {
    ...mapActions('user', [
      'resetPassword'
    ]),

    validateForm() {
      if (this.$refs.resetForm.validate()) {
        this.resetPassword()
      }
    }
  }
}

</script>

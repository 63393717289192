import Vue from 'vue'
import App from './App.vue'
import VueCompositionAPI from '@vue/composition-api'

import './filters'
import './prototypes'
import './components'
import '@/firebase/init'
import store from './store'
import router from './router'
import 'intro.js/introjs.css'
import VueMeta from 'vue-meta'
import firebase from 'firebase/app'
import wysiwyg from "vue-wysiwyg"
import VueIntro from 'vue-introjs'
import VueCookies from 'vue-cookies'

// import * as Sentry from "@sentry/vue"
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'
import 'intro.js/themes/introjs-modern.css'
// import { Integrations } from "@sentry/tracing"

import 'prismjs/themes/prism-tomorrow.css'
import "vue-wysiwyg/dist/vueWysiwyg.css"

Vue.use(VueMeta)
Vue.use(VueCookies)
Vue.use(wysiwyg, { hideModules: { "image": true } })
Vue.use(VueClipboard)
Vue.$cookies.config('2d')
Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true
Vue.use(VueIntro, { disableInteraction: true })
Vue.use(VueCompositionAPI)

// Sentry.init({
//   Vue,
//   dsn: "https://eccf18bacb324957b55d3df874aeba04@o1038223.ingest.sentry.io/6037423",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "builder.lightningsites.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

let app = null

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})

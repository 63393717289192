<template>
  <v-card-text class="pt-5">
    <h5 class="text-center text-h5 font-weight-bold mb-1">Login To Your Account</h5>
      <p class="text-center grey--text body-1">Welcome back! Login below to view your saved work.</p>

      <v-form ref="loginForm" class="my-2" @submit.prevent="validateSignin()">
        <v-row dense>
          <v-col md="6" cols="12">
            <v-text-field
              v-model.trim="data.email"
              :rules="[rules.required, rules.email]"
              label="Email Address"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="data.password"
              @keydown.enter="validateSignin()"
              @click:append="showPassword = !showPassword"
              :rules="[rules.required]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-row dense>
        <v-col cols="12">
          <v-btn
            @click="validateSignin()"
            :loading="status.signingIn"
            color="accent"
            class="mt-2 gradient"
            block
          >Log In</v-btn>
        </v-col>
        <v-col>
          <v-btn
            @click="socialSignin('facebook')"
            :disabled="status.signingIn"
            color="#3B5999"
            class="mt-2 white--text"
            single-line
            block
          >
            <v-icon left>mdi-facebook</v-icon>
            Facebook Login
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            @click="socialSignin('google')"
            :disabled="status.signingIn"
            color="white"
            class="mt-2"
            block
          >
            <v-avatar size="16" class="mr-2">
              <v-img :src="require('@/assets/google-icon.svg')"></v-img>
            </v-avatar>
            <span class="primary--text">Google Login</span>
          </v-btn>
        </v-col>
      </v-row>

      <div class="text-center my-3">
        <a href="#" class="body-1" @click.prevent="$emit('reset')">Forgot your password?</a>
      </div>
      <div class="text-center grey--text caption body-1">Don’t have an account yet? Signup <a href="#" @click.prevent="$emit('signup')">here</a>.</div>
  </v-card-text>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  data() {
    return {
      rules,
      showPassword: false,
    }
  },

  computed: {
    ...mapState({
      data: state => state.user.data,
      status: state => state.user.status,
    })
  },

  methods: {
    ...mapActions('user', [
      'signin',
      'socialSignin',
    ]),

    validateSignin() {
      if (this.$refs.loginForm.validate()) {
        Promise.resolve(this.signin())
          .then(() => {
            this.$router.push({ name: 'Requests' })
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  },
}

</script>

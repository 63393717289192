import Vue from 'vue'
import firebase from 'firebase'
import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  response: {},
  responses: [],
  status: {
    getting: false,
    deleting: false,
    creating: false,
    updating: false,
    firstLoad: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setCannedResponses(state, payload) {
    state.responses = []

    if (payload.size) {
      payload.forEach(doc => {
        let response = doc.data()
        response.id = doc.id
        response.ref = doc.ref
        state.responses.push(response)
      })
    }

    state.status.getting = false
  },

  setResponseData(state, payload) {
    state.response = Object.assign({}, payload)
  },

  addResponse(state, payload) {
    let response = state.responses.find(r => r.id == payload.id)

    if (!response) state.responses.push(payload)
    else Vue.set(state.responses, state.responses.indexOf(response), payload)
  },

  removeCannedResponse(state, payload) {
    let response = state.responses.find(r => r.id == payload.id)
    if (response) state.responses.splice(state.responses.indexOf(response), 1)
  },

  updateField(state, payload) {
    Vue.set(payload.response, payload.field, payload.value)
  },
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE RESPONSE
   *----------------------------------------------------------------------------*/
  async createResponse({ state, commit, dispatch }, type) {
    commit('updateStatus', { creating: true })
    let data = state.response
    data.type = type
    let user = firebase.auth().currentUser

    var docRef = db.collection('canned_responses')
    docRef = data.id ? docRef.doc(data.id) : docRef.doc()

    if (state.response.id) {
      data.updated = firebase.firestore.Timestamp.now()
      data.updatedBy = user.uid
    } 
    else {
      data.created = firebase.firestore.Timestamp.now()
      data.createdBy = user.uid
    }

    await docRef
    .set(data)
    .then(() => {
      data.ref = docRef
      data.id = docRef.id
      commit('addResponse', data)
      dispatch('showSuccess', 'Canned response created', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
    .finally(() => {
      commit('updateStatus', { creating: false })
    })
  },

  /*------------------------------------------------------------------------------
   * GET CANNED RESPONSES
   *----------------------------------------------------------------------------*/
  getCannedResponses({ commit }, type) {
    commit('updateStatus', { getting: true })
    commit('updateStatus', { firstLoad: true })
    
    db.collection('canned_responses')
    .where('type', '==', type).get()
    .then(snapshot => {
      commit('setCannedResponses', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE CANNED RESPONSE
   *----------------------------------------------------------------------------*/
  async deleteCannedResponse({ commit, dispatch }, response) {
    commit('updateStatus', { deleting: true })
    
    await response.ref.delete()
    .then(() => {
      commit('removeCannedResponse', response)
      dispatch('showSuccess', 'Canned response deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
    .finally(() => {
      commit('updateStatus', { deleting: false })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE RESPONSE FIELD
   * 
   * @params
   *  Object
   *    response: Object
   *    field: String
   *    value: Any
   *    message: String (optional)
   *    silent: Boolean (optional)
   *----------------------------------------------------------------------------*/
  async updateResponseField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    
    await data.response.ref
    .update({
      [data.field]: data.value,
      updated: firebase.firestore.Timestamp.now()
    })
    .then(() => {
      commit('updateField', data)
      if (!data.silent) dispatch('showSuccess', data.message || 'Canned response updated', { root: true })
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
    .finally(() => {
      commit('updateStatus', { updating: false })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
import firebase from "firebase"
import db from '@/firebase/init'
import { omit } from 'lodash'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  forms: [],
  fields: [],
  datas: {
    termsData: [],
  },
  status: {
    adding: false,
    getting: false,
    deleting: false,
    updating: false,
    firstLoad: false,
    uploading: false,
    settingAssignee: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  form: (state) => (id) => {
    if (state.forms) {
      let form = state.forms.find(f => f.id == id)
      return form || {}
    }
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },
  
  updateDatas(state, payload) {
    state.datas[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setForms(state, payload) {
    state.forms = []

    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.forms.push(data)
      })
    }

    state.status.getting = false
  },

  addForm(state, payload) {
    if (!state.forms.find(f => f.id == payload.id))
      state.forms.push(payload)
  },

  removeForm(state, payload) {
    let form = state.forms.find(f => f.id == payload.id)
    state.forms.splice(state.forms.indexOf(form), 1)
  },

  resetData(state) {
    state.data = {}
  },

  publishForm(state, form) {
    form.published = true
  },
  
  unpublishForm(state, form) {
    form.published = false
  },

  insertField(state, payload) {
    if (!state.fields.find(f => f.id == payload.id))
      state.fields.push(payload)
  },

  setFields(state, payload) {
    state.fields = []

    if (payload.size) {
      payload.forEach(doc => {
        let field = doc.data()
        field.id = doc.id
        field.ref = doc.ref
        state.fields.push(field)
      })
    }

    state.status.getting = false
  },

  updateFields(state, payload) {
    state.fields = payload

    state.fields.forEach((field, i) => {
      let data = field
      data.order = i
      Vue.set(state.fields, i, data)
    })
  },

  removeField(state, payload) {
    let field = state.fields.find(f => f.id == payload.id)
    state.fields.splice(state.fields.indexOf(field), 1)
  },

  setFormAssignee(state, payload) {
    Vue.set(payload.form, 'assignee', payload.id)
  },

  updateFormField(state, payload) {
    let form = payload.form
    let field = payload.field
    let value = payload.value
    Vue.set(form, field, value)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE FORM
   *----------------------------------------------------------------------------*/
  async createForm({ state, commit, dispatch }) {
    commit('updateStatus', { adding: true })
    
    let id = null
    let user = firebase.auth().currentUser
    let data = state.data
    data.created = firebase.firestore.Timestamp.now()
    data.user = user.uid
    data.published = false

    await db.collection('order_forms')
    .add(data)
    .then((docRef) => {
      data.ref = docRef
      data.id = docRef.id
      commit('addForm', data)
      commit('updateStatus', { adding: false })
      id = docRef.id
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { adding: false })
      dispatch('showError', error.message, { root: true })
    })

    return id
  },

  /*------------------------------------------------------------------------------
   * GET FORMS
   *----------------------------------------------------------------------------*/
  getForms({ commit }) {
    commit('updateStatus', { getting: true })
    commit('updateStatus', { firstLoad: true })
    
    db.collection('order_forms')
    .get()
    .then(snapshot => {
      commit('setForms', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE FORM
   *----------------------------------------------------------------------------*/
  async deleteForm({ commit, dispatch }, form) {
    commit('updateStatus', { deleting: true })

    let deleteFn = firebase.functions().httpsCallable('recursiveDelete')
    
    await deleteFn({ path: form.ref.path })
    .then(() => {
      commit('removeForm', form)
      commit('updateStatus', { deleting: false })
      dispatch('showSuccess', 'Form deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('updateStatus', { deleting: false })
    })
  },

  /*------------------------------------------------------------------------------
   * PUBLISH FORM
   *----------------------------------------------------------------------------*/
  publishForm({ commit, dispatch }, form) {
    form.ref.update({ published: true })
    .then(() => {
      dispatch('showSuccess', 'Form published', { root: true })
      commit('publishForm', form)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },
  
  /*------------------------------------------------------------------------------
   * UNPUBLISH FORM
   *----------------------------------------------------------------------------*/
  unpublishForm({ commit, dispatch }, form) {
    form.ref.update({ published: false })
    .then(() => {
      dispatch('showSuccess', 'Form unpublished', { root: true })
      commit('unpublishForm', form)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET FORM
   *----------------------------------------------------------------------------*/
  getForm({ state, commit, dispatch }, id) {
    if (!state.forms.find(f => f.id == id)) {
      db.collection('order_forms')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          commit('addForm', data)
          if (data.orderType) dispatch('order_types/getType', data.orderType, { root: true })
          if (data.assignee) dispatch('users/getEmployee', data.assignee, { root: true })
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * ADD ORDER FORM FIELD
   * @params
   * - Object $form
   * - String $type
   *----------------------------------------------------------------------------*/
  addField({ state, commit, dispatch }, formData) {
    commit('updateStatus', { adding: true })
    let form = formData.form
    let type = formData.type
    
    let data = {
      type,
      label: null,
      options: null,
      required: true,
      description: null,
      order: state.fields.length,
      created: firebase.firestore.Timestamp.now()
    }

    form.ref
    .collection('fields')
    .add(data)
    .then((docRef) => {
      data.ref = docRef
      data.id = docRef.id
      commit('insertField', data)
      commit('updateStatus', { adding: false })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('updateStatus', { adding: false })
    })
  },

  /*------------------------------------------------------------------------------
   * GET FIELDS
   *----------------------------------------------------------------------------*/
  getFields({ commit, dispatch }, form) {
    commit('updateStatus', { getting: true })
    
    if (Object.keys(form).length) {
      form.ref
      .collection('fields')
      .orderBy('order', 'asc')
      .get()
      .then(snapshot => {
        commit('setFields', snapshot)
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { getting: false })
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * UPDATE FIELDS
   *----------------------------------------------------------------------------*/
  updateFields({ state, commit, dispatch }, form) {
    commit('updateStatus', { updating: true })
    let batch = db.batch()
    batch.set(form.ref, omit(form, ['id', 'ref']))
    
    state.fields.forEach(field => {
      let data = omit(field, ['id', 'ref'])
      batch.set(field.ref, data)
    })
    
    batch.commit()
    .then(() => {
      dispatch('showSuccess', 'Fields updated', { root: true })
      commit('updateStatus', { updating: false })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE FIELD
   *----------------------------------------------------------------------------*/
  async deleteField({ commit, dispatch }, field) {
    commit('updateStatus', { deleting: true })
    
    await field.ref
    .delete()
    .then(() => {
      commit('removeField', field)
      commit('updateStatus', { deleting: false })
      dispatch('showSuccess', 'Field deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deleting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE TERMS
   *----------------------------------------------------------------------------*/
  updateFormTerms({ state, dispatch }, form) {
    form.ref
    .update({ terms: state.datas.termsData })
    .then(() => {
      dispatch('showSuccess', 'Term selected', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE ORDER TYPE
   *----------------------------------------------------------------------------*/
  updateOrderType({ dispatch }, data) {
    let form = data.form
    let type = data.type

    form.ref
    .update({ orderType: type })
    .then(() => {
      dispatch('showSuccess', 'Order type updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * SET ASSIGNEE
   *----------------------------------------------------------------------------*/
  async setAssignee({ commit, dispatch }, data) {
    commit('updateStatus', { settingAssignee: true })
    let form = data.form
    let user = data.id
    
    await form.ref
    .update({ assignee: user })
    .then(() => {
      commit('setFormAssignee', data)
      commit('updateStatus', { settingAssignee: false })
      dispatch('showSuccess', 'Project manager assigned', { root: true })
    })
    .catch(error => {
      commit('updateStatus', { settingAssignee: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE PRODUCT PHOTO
   *----------------------------------------------------------------------------*/
  uploadProductPhoto({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      commit('updateStatus', { uploading: true })
      let photo = data.photo
      let form = data.form
  
      Promise.resolve(
        dispatch('file_upload/uploadFile', {
          file: photo,
          folder: 'forms'
        }, { root: true })
      )
      .then((newFile) => {
        form.ref
        .update({ productImage: newFile.file })
        .then(() => {
          commit('updateFormField', {
            form, 
            field: 'productImage',
            value:  newFile.file
          })
          
          commit('updateStatus', { uploading: false })
          dispatch('showSuccess', 'Image uploaded', { root: true })
          resolve( newFile.file)
        })
        .catch(error => {
          console.log(error.message)
          commit('updateStatus', { uploading: false })
          dispatch('showError', error.message, { root: true })
          reject(error.message)
        })
      })
    })
    
  },

  /*------------------------------------------------------------------------------
   * CLEAR FORM DATA
   * @params
   * field name
   * form Object
   *----------------------------------------------------------------------------*/
  clearFormData({ commit }, data) {
    let form = data.form
    let field = data.field

    form.ref
    .update({ [field] : null })
    .then(() => {
      commit('updateFormField', {
        form, 
        field,
        value: null
      })
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE FORM DATA
   *----------------------------------------------------------------------------*/
  updateFormData({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    
    data.form.ref
    .update({ 
      updated: firebase.firestore.Timestamp.now(),
      [data.field]: data.value, 
    })
    .then(() => {
      commit('updateStatus', { updating: false })
      dispatch('showSuccess', 'Form updated', { root: true })
      commit('updateFormField', {
        form: data.form,
        field: data.field,
        value: data.value
      })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { updating: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * CHECK PRODUCT FIELD
   * @params
   * - Object $form
   *----------------------------------------------------------------------------*/
  checkProductField({ dispatch }, form) {
    form.ref
    .collection('fields')
    .where('type', '==', 'product')
    .limit(1).get()
    .then(snapshot => {
      if (!snapshot.size) {
        dispatch('addField', {
          form,
          type: 'product'
        })
      }
    }) 
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE FORM FIELD
   * @params
   *  Object
   *    form: Object
   *    field: String
   *    value: String
   *    message: String (optional)
   *    silent: Boolean (optional) - turn off notification
   *----------------------------------------------------------------------------*/
  async updateFormField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    
    await data.form.ref
    .update({
      [data.field]: data.value,
      updated: firebase.firestore.Timestamp.now()
    })
    .then(() => {
      commit('updateFormField', data)
      commit('updateStatus', { updating: false })
      if (!data.silent) dispatch('showSuccess', data.message || 'Form updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { updating: false })
      dispatch('showError', error.message, { root: true })
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
import db from '@/firebase/init'
import _omit from 'lodash/omit'
import _orderBY from 'lodash/orderBy'
import _uniqBy from 'lodash/uniqBy'
import { jsPDF } from 'jspdf'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  pages: [],
  originalPages: [],
  selected: null,
  data: {},
  toUpdateID: null,
  isPageTemplate: false,
  status: {
    getting: false,
    creating: false,
    error: null,
    showAdd: false,
    deleting: false,
    downloading: false,
    none: false,
    newPage: null,
    initial: null,
    creatingFromTemplate: false,
  },
  canvas: {
    header: null,
    footer: null,
    body: []
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  parents: (state) => {
    return state.pages.filter(page => {
      return !page.parent
    })
  },

  children: (state) => (id) => {
    let parent = state.pages.find(page => page.id == id)
    return parent.children
  },

  pageMenu: (state) => {
    if (state.pages) {
      let menu = state.pages
  
      menu.forEach((parent) => {
        menu.forEach((child) => {
          if (parent.id == child.parent) {
            parent.children.push(child)
          }
        })
      })
  
      menu = menu.filter(m => {
        return !m.parent && !m.nonNavigation
      })
      
      return menu
    }
    else return []
  },
  
  pageMenuNonNav: (state) => {
    if (state.pages) {
      let menu = state.pages
  
      menu = menu.filter(m => {
        return !!m.nonNavigation
      })
      
      return menu
    }
    else return []
  },

  pageParent: (state) => (id) => {
    return state.originalPages.find(page => page.id == id)
  },

  headerSetting: (state) => {
    return state.selected.header || 'global'
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  creatingState(state, bol) {
    state.status.creating = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  setData(state, data) {
    if (data.id) Object.assign(state.data, data)
    else state.data = data
    state.toUpdateID = data.id
  },

  setPages(state, payload) {
    state.pages = []
    state.originalPages = []

    payload.forEach(page => {
      let data = page.data()
      data.id = page.id
      data.ref = page.ref
      data.icon = data.parent ? 'subdirectory-arrow-right' : 'book-open-page-variant'
      if (data.home) data.icon = 'home'
      data.children = []
      state.pages.push(data)
      state.originalPages.push(data)
    })

    state.status.getting = false
  },

  setSelected(state, payload) {
    if(typeof payload == 'object') {
      state.selected = payload
    }
    else {
      let menu = state.pages.find(p => p.id == payload)
      state.selected = menu
    }
  },

  showAddState(state, bol) {
    state.status.showAdd = bol
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  reorderPages(state, payload) {
    let parent = payload[0].parent

    if (!parent) {
      state.pages = payload
    }
    else {
      state.pages.forEach(page => {
        if (page.id == parent) {
          page.children = payload
        }
      })
    }

  },

  downloadingState(state, bol) {
    state.status.downloading = bol
  },

  setCanvasImage(state, payload) {
    if (['header', 'footer'].includes(payload.type)) {
      state.canvas[payload.type] = {
        image: payload.image,
      }
    }
    else {
      state.canvas.body.push({
        image: payload.image,
        section: payload.section,
      })
    }
  },

  noAction(state) {
    state.status.none = false
  },

  newPage(state, id) {
    state.status.newPage = id
  },

  initialState(state, id) {
    state.status.initial = id
  },

  isPageTemplateState(state, bol) {
    state.isPageTemplate = bol
  },

  creatingFromTemplateState(state, bol) {
    state.status.creatingFromTemplate = bol
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET PAGES
   *----------------------------------------------------------------------------*/
  getPages({ commit, dispatch }, id) {
    commit('gettingState', true)

    db.collection('projects').doc(id)
    .collection('pages')
    .orderBy('order', 'asc')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setPages', snapshot)
        dispatch('setInitialPage')
      }
      else {
        commit('setPages', [])
        commit('gettingState', false)
      }
    })
    .catch(error => {
      console.log('page.js', error.message)
      commit('gettingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE PAGE
   *----------------------------------------------------------------------------*/
  async create({ state, commit, dispatch }, id) {
    commit('creatingState', true)
    if (state.status.error) commit('setError', null)
    let hasError = false
    let pageId = null

    let data = state.data
    data.createdAt = Date.now()
    data.order = state.pages.length
    data.header = 'global'
    data.footer = 'global'
    if (!state.pages.length) data.home = true

    await db.collection('projects').doc(id)
    .collection('pages').add(data)
    .then((docRef) => {
      commit('newPage', docRef.id)
      commit('creatingState', false)
      dispatch('getPages', id)
      pageId = docRef.id
    })
    .catch(error => {
      commit('creatingState', false)
      commit('setError', error.message)
      hasError = true
    })

    return { hasError, pageId }
  },

  /*------------------------------------------------------------------------------
   * UPDATE PAGE
   *----------------------------------------------------------------------------*/
  async update({ state, commit, dispatch }, data) {
    commit('creatingState', true)
    if (state.status.error) commit('setError', null)
    let hasError = false
    let id = data.id
    let page = data.page

    page.parent = page.parent == undefined ? null : page.parent

    if (page.home) {
      dispatch('setHomepage', page)
    }

    await page.ref.update(_omit(page, ['id', 'ref', 'children', 'home']))
    .then(() => {
      dispatch('showSuccess', 'Page updated', { root: true })
      commit('creatingState', false)
      dispatch('getPages', id)
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('creatingState', false)
      console.log(error.message)
      hasError = true
    })

    return { hasError, pageId : page.id }
  },

  /*------------------------------------------------------------------------------
   * DELETE PAGE
   *----------------------------------------------------------------------------*/
  async delete({ state, commit, dispatch }, data) {
    commit('deletingState', true)

    await data.page.ref.delete()
    .then(() => {
      commit('setSelected', null)

      let toUpdate = state.pages.filter(page => {
        return page.parent == data.page.id
      })

      if (toUpdate.length) {
        let count = 0

        toUpdate.forEach((page) => {
          page.ref.update({ parent: null })
          .then(() => {
            count++

            if (toUpdate.length == count) {
              commit('deletingState', false)
              dispatch('showSuccess', 'Page successfully deleted.', { root: true })
              dispatch('getPages', data.project)
            }
          })
        })
      }
      else {
        commit('deletingState', false)
        dispatch('showSuccess', 'Page successfully deleted.', { root: true })
        dispatch('getPages', data.project)
      }
    })
    .catch(error => {
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
      console.log('page.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE PAGE ORDERING
   *----------------------------------------------------------------------------*/
  updatePageOrders({ dispatch }, pages) {
    let count = 0

    pages.forEach((page, i) => {
      page.ref.update({ order: i })
      .then(() => {
        count++

        if (count == pages.length) {
          dispatch('showSuccess', 'Page order updated.', { root: true })
        }
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        console.log('page.js', error.message)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * SET AS HOMEPAGE
   *----------------------------------------------------------------------------*/
  setHomepage({ dispatch, rootState }, page) {
    state.pages.forEach(p => {
      if (p.home && p.id !== page.id) {
        p.ref.update({ home: false })
        .then(() => {
          page.ref.update({ home: true, parent: null })
          .then(() => {
            dispatch('getPages', rootState.project.project.id)
            dispatch('showSuccess', 'Homepage updated', { root: true })
          })
        })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
          console.log('page.js', error.message)
        })
      }
    })
  },

  /*------------------------------------------------------------------------------
   * SET INITIAL PAGE
   *----------------------------------------------------------------------------*/
  setInitialPage({ state, getters, commit }) {
    let menu = getters.pageMenu
    
    if (state.status.newPage) {
      let selected = getters.pageMenu.find(menu => menu.id == state.status.newPage)
      if (selected) commit('setSelected', selected)
      commit('newPage', null)
    }
    else if (state.status.initial) {
      let selected = getters.pageMenu.find(menu => menu.id == state.status.initial)
      if (selected) commit('setSelected', selected)
      commit('initialState', null)
    }
    else if (!state.selected) {
      commit('setSelected', menu[0])
    }
    else {
      let selected = getters.pageMenu.find(menu => menu.id == state.selected.id)
      if (selected) commit('setSelected', selected)
    }
  },

  /*------------------------------------------------------------------------------
   * SET CANVAS
   *----------------------------------------------------------------------------*/
  setCanvas({ commit }, data) {
    let img = new Image
    img.src = data.jpeg

    img.onload = function () {
      commit('setCanvasImage', {
        type: data.type,
        image: img,
        order: data.order,
        section: data.section,
      })
    }
  },

  async downloadPage({ state, commit, rootState }) {
    commit('downloadingState', true)

    let totalHeight = 0
    let header = state.canvas.header
    let footer = state.canvas.footer
    let body = state.canvas.body
    let sections = rootState.sections.sections
    let user = rootState.user.user

    user.ref.update({ downloads: firebase.firestore.FieldValue.increment(1) })

    body = _uniqBy(body, 'section')

    body = body.filter(b => {
      return sections.find(s => s.id == b.section)
    })

    sections.forEach((section, ind) => {
      body.forEach((b, i) => {
        if (b.section == section.id) {
          body[i].order = ind
        }
      })
    })

    if (header && state.selected.header !== 'none') {
      totalHeight += header.image.height
    }

    body = _orderBY(body, 'order', 'asc')

    body.forEach((b, i) => {
      body[i].top = totalHeight
      totalHeight += b.image.width > 1440 ? b.image.height / (b.image.width / 1440) : b.image.height
    })

    if (footer && state.selected.footer !== 'none') totalHeight += footer.image.height

    let doc = new jsPDF('p', 'pt', [1439, totalHeight])
    
    if (header && state.selected.header !== 'none') {
      doc.addImage(header.image, 'JPEG', 0, 0, 1440, header.image.width > 1440 ? header.image.height / (header.image.width / 1440) : header.image.height)
    }

    body.forEach(b => { 
      doc.addImage(b.image, 'JPEG', 0, b.top, 1440, b.image.width > 1440 ? b.image.height / (b.image.width / 1440) : b.image.height) 
    })

    if (footer && state.selected.footer !== 'none') {
      doc.addImage(footer.image, 'JPEG', 0, (totalHeight - footer.image.height), 1440, footer.image.width > 1440 ? footer.image.height / (footer.image.width / 1440) : footer.image.height)
    }

    await doc.save(`${state.selected.name}.pdf`)
    commit('downloadingState', false)
  },
  
  /*------------------------------------------------------------------------------
   * GET PAGE TEMPLATE
   *----------------------------------------------------------------------------*/
  getPageTemplate({ commit }, id) {
    db.collection('templates')
    .doc('pages')
    .collection('templates')
    .doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        commit('setSelected', data)
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE FROM TEMPLATE
   *----------------------------------------------------------------------------*/
  async createFromTemplate({ state, commit, dispatch }, template) {
    commit('creatingFromTemplateState', true)
    
    await template.ref
    .collection('sections')
    .get()
    .then((snapshot) => {
      if (snapshot.size) {
        let batch = db.batch()

        snapshot.forEach(doc => {
          let docRef = state.selected.ref.collection('sections').doc()
          batch.set(docRef, doc.data())
        })

        return batch.commit()
        .then(() => {
          dispatch('sections/getSections', state.selected, { root: true })
          commit('creatingFromTemplateState', false)
          dispatch('showSuccess', 'Template imported successfully', { root: true })
        })
      }
      else {
        commit('creatingFromTemplateState', false)
      }
    })
    .catch((error) => {
      console.log(error.message)
      commit('creatingFromTemplateState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

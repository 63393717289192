import Vue from 'vue'

Vue.component('AdminTitle',
  () => import('@/components/AdminTitle')
)

Vue.component('preloader',
  () => import('@/components/Preloader')
)

Vue.component('PreloaderCircular',
  () => import('@/components/PreloaderCircular')
)

Vue.component('ConfirmPassword',
  () => import('@/components/ConfirmPassword')
)

Vue.component('UserPhoto',
  () => import('@/components/UserPhoto')
)

Vue.component('ConfirmDelete',
  () => import('@/components/ConfirmDelete')
)

Vue.component('BlockImg',
  () => import('@/components/BlockImage')
)

Vue.component('BlockImgAdvance',
  () => import('@/components/BlockImageAdvance')
)

Vue.component('TextDivider',
  () => import('@/components/TextDivider')
)

Vue.component('ImagePopup',
  () => import('@/components/ImagePopup')
)

Vue.component('BlockPopup',
  () => import('@/components/BlockPopup')
)

Vue.component('CodePopup',
  () => import('@/components/CodePopup')
)

Vue.component('ProjectCount',
  () => import('@/components/ProjectCount')
)

Vue.component('back',
  () => import('@/components/Back')
)

Vue.component('owner',
  () => import('@/components/OwnerInline')
)

Vue.component('BackSquare',
  () => import('@/components/BackSquare')
)

Vue.component('CloseBtn',
  () => import('@/components/CloseBtn')
)

Vue.component('UploadProgress',
  () => import('@/components/UploadProgress')
)

Vue.component('RenderImage',
  () => import('@/components/RenderImage')
)

<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
    :persistent="status.adding"
    scrollable
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          placeholder="Search User"
          class="field-shadow"
          append-icon="mdi-account-search-outline"
          hide-details
          clearable
          outlined
          rounded
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <preloader v-if="getting" text="Please wait..." />

        <v-list v-if="!getting && users.length">
          <v-card
            v-for="user in users"
            :key="user.id"
            @click="insertCollaborator(user.userid)"
            class="mb-1"
            outlined
            hover
            light
          >
            <v-list-item>
              <v-list-item-avatar size="30" class="overflow-visible" rounded>
                <UserPhoto :size="30" photoSize="thumb" :id="user.userid" rounded/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.fullName }}</v-list-item-title>
                <v-list-item-subtitle class="caption">{{ user.employeeRole }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon :color="isCollaborator(user.userid) ? 'success': 'grey lighten-3'">mdi-checkbox-marked-circle-outline</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          @click="add()"
          color="accent dark--text"
          :loading="status.adding"
          :disabled="!collaborators.length"
          small
        >Selected ({{ collaborators.length }})</v-btn>
        
        <v-btn 
          @click="closeDialog()" 
          :disabled="status.adding"
          text small
        >Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import db from '@/firebase/init'
import { mapState, mapActions } from 'vuex'
import { orderBy } from 'lodash'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    project: {
      type: Object,
      require: true,
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      collaborators: [],
      dialog: false,
      snapshot: null,
      getting: false,
      search: null,
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'project': function (val) {
      if (val) this.getEmployees()
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.collaborators.status,
      collabs: (state) => state.collaborators.collaborators,
    }),

    users: function () {
      let users = []

      if (this.snapshot && this.snapshot.size) {
        this.snapshot.forEach(doc => {
          let user = doc.data()
          user.id = doc.id
          user.ref = doc.ref
          users.push(user)
        })
      }

      if (this.search) {
        users = users.filter(user => {
          return user.fullName.toLowerCase().includes(this.search.toLowerCase()) || user.email.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      if (this.project) {
        users = users.filter(user => user.userid !== this.project.owner)
      }

      if (this.collabs.length) {
        users = users.filter(user => {
          return !this.collabs.find(collab => collab.user == user.userid)
        })
      }

      return orderBy(users, 'fullName', 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('collaborators', [
      'addCollaborators'
    ]),

    /*------------------------------------------------------------------------------
     * GET EMPLOYEES
     *----------------------------------------------------------------------------*/
    getEmployees() {
      this.getting = true
      var ids = [this.project.owner]
      
      if (this.collabs.length) {
        this.collabs.forEach(c => ids.push(c.user))
      }

      db.collection('users')
      .where('organization', '==', true)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          this.snapshot = snapshot
          this.getting = false
        }
        else this.getting = false
      })
      .catch(error => {
        console.log(error.message)
        this.$store.dispatch('showError', error.message, { root: true })
      })
    },

    /*------------------------------------------------------------------------------
     * INSERT COLLABORATOR
     *----------------------------------------------------------------------------*/
    insertCollaborator(userid) {
      if (!this.collaborators.find(u => u == userid)) this.collaborators.push(userid)
      else this.collaborators.splice(this.collaborators.indexOf(userid), 1)
    },

    /*------------------------------------------------------------------------------
     * IS COLLABORATOR
     *----------------------------------------------------------------------------*/
    isCollaborator(userid) {
      return !!this.collaborators.find(u => u == userid)
    },

    /*------------------------------------------------------------------------------
     * CLOSE DIALOG
     *----------------------------------------------------------------------------*/
    closeDialog() {
      this.dialog = false
      this.collaborators = []
    },

    /*------------------------------------------------------------------------------
     * ADD COLLABORATORS
     *----------------------------------------------------------------------------*/
    add() {
      Promise.all([
        this.addCollaborators(this.collaborators)
      ])
      .then(() => {
        this.closeDialog()
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.project) this.getEmployees()
  }
}
</script>
<template>
   <div v-if="notif">
     <div :style="{ lineHeight: '1.4 !important'}">
       You are invited as a member of company <span class="text-capitalize info--text">{{ $store.getters['brand_profile/profile'](notif.profile).name }}</span>. <span class="grey--text text--lighten-1">{{ notif.created | fromNowComplete }}</span>
     </div>
 
     <div class="d-flex align-center gap-5 mt-2">
       <v-btn 
         @click="acceptInvite()"
         :loading="accepting"
         color="primary" 
         x-small
       >Accept</v-btn>
       
       <v-btn 
         @click="deleteDialog = true"
         :disabled="accepting"
         x-small
       >Reject</v-btn>
     </div>
 
     <!-- CONFIRM DIALOG -->
     <ConfirmDelete
       :show="deleteDialog"
       :deleting="deleting"
       @confirmed="deleteInvite()"
       @cancel="deleteDialog = false"
       message="This invite will be deleted."
     />
   </div>
 </template>
 
 <script>
 import { uniq } from 'lodash'
 import db from '@/firebase/init'
 
 export default {
   props: {
     notif: Object,
   },
 
   data() {
     return {
       deleting: false,
       accepting: false,
       deleteDialog: false,
     }
   },
 
   methods: {
     acceptInvite() {
       this.accepting = true
       var profile = this.$store.getters['brand_profile/profile'](this.notif.profile)
       let members = profile.members || []
       
       db.collection('users')
       .where('email', '==', this.notif.email)
       .limit(1).get()
       .then(async (snapshot) => {
         if (snapshot.size) {
           let doc = snapshot.docs[0]
           let user = doc.data()
           user.id = doc.id
           user.ref = doc.ref
           members.push(user.id)
           members = uniq(members)
           
           await profile.ref
           .update({ members })
           .then(() => {
             this.$store.dispatch('showSuccess', 'Invite accepted')
             this.deleteInvite()
           })
           .catch(error => {
             this.$store.dispatch('showError', error.message)
           })
         }
       })
       .catch(error => {
         console.log(error.message)
         this.$store.dispatch('showError', error.message)
       })
       .finally(() => {
         this.accepting = false
       })
     },
 
     deleteInvite() {
       this.deleting = true
 
       this.notif.ref
       .delete()
       .then(() => {
         this.$emit('deleted')
       })
       .catch(error => {
         console.log(error.message)
       })
       .finally(() => {
         this.deleting = false
         this.deleteDialog = false
       })
     }
   }
 }
 </script>
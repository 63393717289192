<template>
  <div>
    <div class="pa-5" v-if="!$store.state.user.user">
      <v-alert icon="mdi-information" color="accent" class="mb-0" :value="true">
        <p>Create a free account to design multiple pages and save your project with no time limits</p>
        <v-btn
          @click="
            $store.commit('user/setShowDialog', true)
            $store.commit('user/setActionType', 'signup')
          "
          color="dark white--text"
          small
        >Save Project</v-btn>
      </v-alert>
    </div>

    <v-expansion-panels
      v-if="project"
      class="project-panel"
      mandatory
      accordion
      flat
      tile
      hover
      dark
    >
      <v-expansion-panel
        v-for='(panel, i) in confPanels'
        :key="i"
      >
        <v-expansion-panel-header 
          expand-icon="mdi-menu-down" 
          :color="$colors.panelHeader" 
          class="font-weight-bold" 
          ripple
        >
          <div>
            {{ panel.title }}

            <v-chip v-if="panel.title == 'Collaborators' && $store.getters['collaborators/pendings'].length && $store.getters['project/isOwner']" class="ml-2" color="orange" small>
              {{ $store.getters['collaborators/pendings'].length }}
            </v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content :color="$colors.panelContent" class="py-5">
          <page-settings v-if="panel.component == 'page'" />
          <project-settings v-if="panel.component == 'project'" />
          <Collaborators v-if="panel.component == 'collab'" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import PageSettings from './PageSettings'
import ProjectSettings from './ProjectSettings'
import Collaborators from './Collaborators'
import { mapState } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      panels: [
        // {
        //   title: 'Project Settings',
        //   component: 'project'
        // },
        {
          title: 'Page Settings',
          component: 'page',
          forEmployee: false,
          template: 'all',
        },
        {
          title: 'Collaborators',
          component: 'collab',
          forEmployee: true,
          template: false,
        },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      project: (state) => state.project.project,
      isPageTemplate: (state) => state.page.isPageTemplate,
    }),

    confPanels: function () {
      let panels = this.panels.filter(panel => {
        if (this.user && panel.forEmployee && (this.$store.getters['user/isEmployee'] || this.$store.getters['user/isAdmin'])) return true
        else if (!panel.forEmployee) return true
        else return false
      })

      panels = panels.filter(panel => {
        return this.project && (this.project.template == panel.template || panel.template == 'all')
      })

      return panels
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    PageSettings,
    ProjectSettings,
    Collaborators
  }
}
</script>
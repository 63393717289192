import axios from 'axios'

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GOOGLE CHAT WEBHOOK
   * @params
   * webhook_url
   * text
   *----------------------------------------------------------------------------*/
  async googleChat({ dispatch }, data) {
    await axios.post(data.url, {
      text: data.text
    })
    .catch((err) => {
      console.error(err.toJSON())
      dispatch('showError', err.toJSON(), { root: true })
    })
  }
}

export default {
  namespaced: true,
  actions
}
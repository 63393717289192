<template>
  <v-card-text class="pt-5">
    <template v-if="!order">
      <h5 class="text-center text-h5 font-weight-bold mb-2">
        Create A New Account
      </h5>

      <!-- <v-sheet max-width="450" class="mx-auto">
        <p class="text-center grey--text body-1">
          Sign up for a free account to download your designs, build multiple pages, and create additional projects.
        </p>
      </v-sheet> -->
    </template>

    <v-alert v-if="invite == 'error'" type="warning" border="left" text>
      Invalid invitation code.
    </v-alert>

    <v-form ref="signupForm" @submit.prevent="validateSignup()" :class="{ 'mt-7' : !order }">
      <v-row dense>
        <v-col cols="12" :md="order ? '12' : '6'">
          <v-text-field
            v-model="data.fullName"
            :rules="[rules.required]"
            label="Full Name"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" :md="order ? '12' : '6'">
          <v-text-field
            v-model="data.email"
            :rules="[rules.required, rules.email]"
            :readonly="invite === true"
            label="Email Address"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" :md="order ? '12' : '6'">
          <v-text-field
            v-model="data.password"
            :rules="[rules.required, rules.password]"
            @click:append="showPassword = !showPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" :md="order ? '12' : '6'">
          <v-text-field
            v-model="data.repeatPassword"
            :rules="[rules.required, (v) => v == data.password || 'Password and repeat password does not match.']"
            label="Confirm Password"
            type="password"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-btn
            @click="validateSignup()"
            :loading="status.signingUp"
            color="accent"
            class="mt-2 gradient"
            block
          >Sign Up </v-btn>
        </v-col>

        <template v-if="!$route.query.i">
          <v-col>
            <v-btn
              @click="socialSignin('facebook')"
              :disabled="status.signingIn"
              color="#3B5999"
              class="mt-2 white--text"
              single-line
              block
            >
              <v-icon left>mdi-facebook</v-icon>
              Facebook Login
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="socialSignin('google')"
              :disabled="status.signingIn"
              color="white"
              class="mt-2"
              block
            >
              <v-avatar size="16" class="mr-2">
                <v-img :src="require('@/assets/google-icon.svg')"></v-img>
              </v-avatar>
              <span class="primary--text">Google Login</span>
            </v-btn>
          </v-col>
        </template>
      </v-row>
    </v-form>

    <template v-if="!signupOnly">
      <div class="text-center grey--text caption mt-5">Have an account already? Login <a href="#" @click.prevent="$emit('login')" >here</a>.</div>
    </template>
  </v-card-text>
</template>

<script>

import rules from '@/rules'
import db from '@/firebase/init'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    signupOnly: Boolean,
    order: Boolean
  },
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      invite: false,
      showPassword: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.user.data,
      status: state => state.user.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', [
      'socialSignin',
      'signup',
    ]),

    validateSignup() {
      if (this.$refs.signupForm.validate()) {
        Promise.resolve(this.signup())
        .then(() => {
          if (this.invite) {
            db.collection('user_invites')
            .doc(this.$route.query.i).delete()
          }
        })
      }
    },

    getInvite(i) {
      db.collection('user_invites')
      .doc(i).get()
      .then(doc => {
        if (doc.exists) {
          this.invite = true
          this.$store.commit('user/setData', {
            email: doc.data().email,
            role: doc.data().role,
            package: doc.data().package
          })
        }
        else {
          this.invite = 'error'
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.$route.query.email) 
      this.$store.commit('user/setData', { email: this.$route.query.email })
    
    if (this.$route.query.i)
      this.getInvite(this.$route.query.i)
  }
}

</script>

<template>
  <div>
    <div v-if="uploading.length" class="mb-2 caption">Uploading...</div>

    <v-alert
      v-for="(error, i) in uploadErrors"
      :key="i"
      type="error"
      border="left"
    >
      {{ error }}
    </v-alert>

    <template v-if="uploading.length">
      <v-card
        v-for="(upload, i) in filtered"
        :key="i"
        class="pa-3 mb-2"
        transition="fade"
        outlined
      >
        <v-progress-linear :value="upload.progress" class="mb-2" rounded></v-progress-linear>
        <div class="d-flex justify-space-between caption">
          <span>{{ upload.name }}</span>
          <span>{{ Math.round(upload.progress) }}%</span>
        </div>
      </v-card>
    </template>

  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      uploading: state => state.blocks.uploading,
      uploadErrors: state => state.blocks.uploadErrors,
    }),

    filtered: function () {
      return this.uploading.filter(upload => {
        return upload.progress
      })
    }
  },
}

</script>

import db from '@/firebase/init'
import firebase from 'firebase'
import _omit from 'lodash/omit'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  folders: [{
    name: 'Root Folder',
    id: 'root'
  }],
  data: {},
  status: {
    getting: false,
    creating: false,
    error: null,
    firstLoad: false,
    deleting: false,
    displayRange: 60,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setFolders(state, payload) {
    state.folders = [{
      name: 'Root Folder',
      id: 'root'
    }]

    payload.forEach(folder => {
      let data = folder.data()
      data.id = folder.id
      data.ref = folder.ref

      state.folders.push(data)
    })

    // state.status.firstLoad = true
  },

  creatingFolder(state, bol) {
    state.status.creating = bol
  },

  errorMessage(state, message) {
    state.status.error = message
  },

  insertFolder(state, payload) {
    if (!state.folders.find(folder => folder.id == payload.id)) {
      let data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
      state.folders.push(data)
    }
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setData(state, payload) {
    state.data = payload
  },

  deletingFolder(state, bol) {
    state.status.deleting = bol
  },

  removeFolder(state, folder) {
    state.folders.splice(state.folders.indexOf(folder), 1)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET FOLDERS
   *----------------------------------------------------------------------------*/
  getFolders({ commit, dispatch }) {
    let user = firebase.auth().currentUser

    db.collection('folders')
    .where('owner', '==', user.uid)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setFolders', snapshot)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log('folders.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE FOLDER
   *----------------------------------------------------------------------------*/
  async create({ state, commit, dispatch }) {
    commit('creatingFolder', true)
    let hasError = false
    let user = firebase.auth().currentUser
    let data = state.data
    data.owner = user.uid
    data.createdAt = Date.now()

    await db.collection('folders').add(data)
    .then((docRef) => [
      Promise.all([
        dispatch('getFolder', docRef.id)
      ])
      .then(() => {
        commit('creatingFolder', false)
        dispatch('showSuccess', 'Folder successfully created.', { root: true })
      })
    ])
    .catch(error => {
      commit('creatingFolder', false)
      commit('errorMessage', error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * RENAME FOLDER
   *----------------------------------------------------------------------------*/
  async update({ state, commit, dispatch }) {
    commit('creatingFolder', true)
    let hasError = false

    await state.data.ref.update(_omit(state.data, ['ref', 'id']))
    .then(() => {
      commit('creatingFolder', false)
      dispatch('showSuccess', 'Folder successfully updated.', { root: true })
    })
    .catch(error => {
      commit('creatingFolder', false)
      commit('errorMessage', error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * DELETE
   *----------------------------------------------------------------------------*/
  async delete({ commit, dispatch }, folder) {
    commit('deletingFolder', true)
    let hasError = false

    await folder.ref.delete()
    .then(() => {
      commit('deletingFolder', false)
      commit('removeFolder', folder)
      dispatch('showSuccess', 'Folder successfully deleted', { root: true })
    })
    .catch(error => {
      commit('deletingFolder', false)
      dispatch('showError', error.message, { root: true })
      console.log('folders.js', error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * GET FOLDER
   *----------------------------------------------------------------------------*/
  async getFolder({ commit, dispatch }, id) {
    await db.collection('folders').doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        commit('insertFolder', doc)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log('folders.js', error.message)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import db from '@/firebase/init'
import firebase from 'firebase'
import Vue from 'vue'
import { omit } from 'lodash'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  templates: [],
  categories: [],
  status: {
    addingCategory: false,
    gettingCategories: false,
    deletingCategory: false,
    saving: false,
    getting: false,
    deleting: false,
    creating: false,
    firstLoad: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  addingCategoryState(state, bol) {
    state.status.addingCategory = bol
  },

  addCategoryCollection(state, payload) {
    let category = state.categories.find(c => c.id == payload.id)
    if (!category) state.categories.push(payload)
    else Vue.set(state.categories, state.categories.indexOf(category), payload)
  },

  gettingCategoriesState(state, bol) {
    state.status.gettingCategories = bol
  },

  setCategories(state, payload) {
    state.categories = []

    if (payload.size) {
      payload.forEach(doc => {
        let category = doc.data()
        category.id = doc.id
        category.ref = doc.ref
        
        if (!state.categories.find(c => c.id == category.id))
          state.categories.push(category)
      })
    }

    state.status.gettingCategories = false
  },

  deletingCategoryState(state, bol) {
    state.status.deletingCategory = bol
  },

  removeCategory(state, payload) {
    state.categories.splice(state.categories.indexOf(payload), 1)
  },

  savingState(state, bol) {
    state.status.saving = bol
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  setTemplates(state, payload) {
    state.templates = []

    if (payload.size) {
      payload.forEach(doc => {
        let template = doc.data()
        template.id = doc.id
        template.ref = doc.ref
        state.templates.push(template)
      })
    }

    state.status.getting = false
    state.status.firstLoadState = true
  },

  addTemplate(state, payload) {
    if (!state.templates.find(t => t.id == payload.id))
      state.templates.push(payload)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeTemplate(state, payload) {
    let template = state.templates.find(t => t.id == payload.id)
    state.templates.splice(state.templates.indexOf(template), 1)
  },

  creatingState(state, bol) {
    state.status.creating = bol
  },

  mutateTemplate(state, payload) {
    let template = state.templates.find(t => t.id == payload.id)
    Vue.set(state.templates, state.templates.indexOf(template), payload)
  },

  firstLoadState(state, bol) {
    state.status.firstLoad = bol
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * ADD CATEGORY
   *
   * @PARAMS
   * $title
   *----------------------------------------------------------------------------*/
  async addCategory({ commit, dispatch }, category) {
    commit('addingCategoryState', true)
    let user = firebase.auth().currentUser
    let data = {
      name: category.name,
      created: firebase.firestore.Timestamp.now(),
      user: user.uid
    }

    let categoryRef = db.collection('categories')
    .doc('pageTemplates')
    .collection('categories')

    if (category.id) categoryRef = categoryRef.doc(category.id)
    else categoryRef = categoryRef.doc()
    
    await categoryRef
    .set(data)
    .then(() => {
      data.ref = categoryRef
      data.id = categoryRef.id
      commit('addingCategoryState', false)
      commit('addCategoryCollection', data)
      dispatch('showSuccess', `Category ${ category.id ? 'updated' : 'added' }`, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('addingCategoryState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET CATEGORIES
   *----------------------------------------------------------------------------*/
  getCategories({ commit }) {
    commit('gettingCategoriesState', true)
    
    db.collection('categories')
    .doc('pageTemplates')
    .collection('categories')
    .get()
    .then((snapshot) => {
      commit('setCategories', snapshot)
    })
    .catch(error => {
      commit('gettingCategoriesState', false)
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE CATEGORY
   *
   * @PARAMS
   * $category
   *----------------------------------------------------------------------------*/
  async deleteCategory({ commit, dispatch }, category) {
    commit('deletingCategoryState', true)
    
    await category.ref.delete()
    .then(() => {
      commit('removeCategory', category)
      commit('deletingCategoryState', false)
      dispatch('showSuccess', 'Category deleted', { root: true }) 
    })
    .catch(error => {
      console.log(error.message)
      commit('deletingCategoryState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * SAVE PAGE TEMPLATE
   *----------------------------------------------------------------------------*/
  async saveTemplate({ commit, dispatch }, data) {
    commit('savingState', true)
    let user = firebase.auth().currentUser
    let page = data.page

    await page.ref
    .collection('sections')
    .get()
    .then(async (snapshot) => {
      if (snapshot.size) {
        let batch = db.batch()
        let pageRef = db.collection('templates').doc('pages').collection('templates').doc()
        let templateData = {
          name: data.data.name,
          categories: data.data.categories || [],
          user: user.uid,
          created: firebase.firestore.Timestamp.now()
        }

        batch.set(pageRef, templateData)

        let headerRef = pageRef.collection('globals').doc('header')
        batch.set(headerRef, omit(data.header, ['id', 'ref']))
        let footerRef = pageRef.collection('globals').doc('footer')
        batch.set(footerRef, omit(data.footer, ['id', 'ref']))

        snapshot.forEach(doc => {
          let sectionRef = pageRef.collection('sections').doc()
          batch.set(sectionRef, doc.data())
        })

        await batch.commit()
        .then(() => {
          templateData.ref = pageRef
          templateData.id = pageRef.id

          commit('savingState', false)
          commit('addTemplate', templateData)
          dispatch('showSuccess', 'Page template saved', { root: true })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET TEMPLATES
   *----------------------------------------------------------------------------*/
  getTemplates({ commit, dispatch }) {
    commit('gettingState', true)
    
    db.collection('templates')
    .doc('pages')
    .collection('templates')
    .get()
    .then((snapshot) => {
      commit('setTemplates', snapshot)

      if (snapshot.size) {
        snapshot.forEach(doc => {
          dispatch('users/getUserUid', doc.data().user, { root: true })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE TEMPLATE
   *----------------------------------------------------------------------------*/
  async deleteTemplate({ commit, dispatch }, template) {
    commit('deletingState', true)
    
    await template.ref
    .delete()
    .then(() => {
      commit('deletingState', false)
      commit('removeTemplate', template)
      dispatch('showSuccess', 'Page template deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE TEMPLATE NAME
   *----------------------------------------------------------------------------*/
  updateTemplate({ commit, dispatch }, template) {
    template.updated = firebase.firestore.Timestamp.now()
    
    template.ref
    .update(omit(template, ['ref', 'id', 'created']))
    .then(() => {
      dispatch('showSuccess', 'Template updated', { root: true })
      commit('mutateTemplate', template)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },  

  /*------------------------------------------------------------------------------
   * CREATE NEW TEMPLATE
   *----------------------------------------------------------------------------*/
  async createNewTemplate({ commit, dispatch }, data) {
    commit('creatingState', true)
    let user = firebase.auth().currentUser
    let id = null
    
    data.created = firebase.firestore.Timestamp.now()
    data.user = user.uid
    
    await db.collection('templates')
    .doc('pages')
    .collection('templates')
    .add(data)
    .then((docRef) => {
      id = docRef.id
      commit('creatingState', false)

      data.ref = docRef
      data.id = docRef.id
      commit('addTemplate', data)
    })
    .catch(error => {
      console.log(error.message)
      commit('creatingState', false)
      dispatch('showError', error.message, { root: true })
    })

    return id
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
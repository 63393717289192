import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
}

if (process.env.VUE_APP_ENV === 'local') {
  firebaseConfig.storageBucket = 'viva-city-31ec6.appspot.com'
  firebaseConfig.databaseURL = 'http://localhost:9001/?ns=viva-city-31ec6'
}

firebase.initializeApp(firebaseConfig)

if (process.env.VUE_APP_ENV === "local") {
  // firebase.auth().useEmulator('http://localhost:9099')
  firebase.firestore().settings({ host: 'localhost:8081', ssl: false })
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
}

export default firebase.firestore()

import { omit } from 'lodash'
import firebase from 'firebase'
import db from '@/firebase/init'

/*-------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  histories: [],
  status: {
    creating: false
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREAT HISTORY
   *
   * @params
   *  Object
   *    user: String
   *    document: String
   *    action: String
   *    collection: String
   *----------------------------------------------------------------------------*/
  async createHistory({ commit }, data) {
    commit('updateStatus', { creating: true })
    data.created = firebase.firestore.Timestamp.now()
    console.log('create log')
    
    await db.collection('log_histories')
    .doc(data.collection)
    .collection('logs')
    .add(omit(data, ['collections']))
    .then(() => {
      commit('updateStatus', { creating: false })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { creating: false })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
import firebase from 'firebase'
var storageRef = firebase.storage().ref()

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  messages: [],
  attachments: [],
  attached: [],
  status: {
    getting: false,
    creating: false,
    uploading: 0,
    note: false,
    deleting: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setMessages(state, payload) {
    state.messages = []

    if (payload.size) {
      payload.forEach(doc => {
        let message = doc.data()
        message.ref = doc.ref
        message.id = doc.id
        state.messages.push(message)
      })
    }

    state.status.getting = false
  },

  insertMessage(state, payload) {
    if (!state.messages.find(m => m.id == payload.id)) {
      state.messages.push(payload)
    }
  },

  creatingState(state, bol) {
    state.status.creating = bol
  },

  insertAttachments(state, payload) {
    if (!state.attachments.find(f => f == payload)) {
      state.attachments.push(payload)
    }
  },

  removeAttachment(state, i) {
    state.attachments.splice(i, 1)
  },

  uploadingState(state, count) {
    state.status.uploading += count
  },

  addAttached(state, payload) {
    state.attached.push(payload)
  },

  clearAttachments(state) {
    state.attachments = []
    state.attached = []
  },

  noteState(state, bol) {
    state.status.note = bol
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeMessage(state, payload) {
    state.messages.splice(state.mesages.indexOf(payload), 1)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET MESSAGES
   *----------------------------------------------------------------------------*/
  getMessages({ commit, dispatch }, request) {
    commit('gettingState', true)
    
    request.ref.collection('messages')
    .get()
    .then(snapshot => {
      commit('setMessages', snapshot)

      if (snapshot.size) {
        snapshot.forEach(doc => {
          dispatch('users/getUserUid', doc.data().sender, { root: true })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('gettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE MESSAGE
   *----------------------------------------------------------------------------*/
  async createMessage({ state, commit, dispatch }, request) {
    commit('creatingState', true)
    let user = firebase.auth().currentUser
    let data = {
      message: state.data.message,
      sender: user.uid,
      created: Date.now()
    }

    if (state.status.note) data.type = 'note'
   
    await request.ref.collection('messages')
    .add(data)
    .then((docRef) => {
      commit('creatingState', false)
      commit('noteState', false)
      dispatch('showSuccess', 'Successfully created message', { root: true })

      if (state.status.attached.length) {
        state.status.attached.forEach(data => {
          data.messageId = docRef.id
          request.ref.collection('attachments').add(data)
          .then((DocRef) => {
            data.ref = DocRef
            data.id = DocRef.id
            dispatch('request/insertFile', data, { root: true })
          })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('creatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD FILES
   *----------------------------------------------------------------------------*/
  uploadFiles({ state, commit, dispatch }) {
    commit('creatingState', true)
    
    state.attachments.forEach(async (file) => {
      let name = `${Date.now()}_${file.name}`

      var metadata = {
        contentType: file.type
      }

      var uploadTask  = storageRef.child(`requests/${name}`).put(file, metadata)

      await uploadTask.on('state_changed', snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(progress)
      }, error => {
        dispatch('showError', error.message, { root: true })
        console.log(error.message)
      }, () => {
        
        let data = {
          file: name,
          type: file.type,
          size: file.size,
          created: Date.now()
        }
        
        commit('uploadingState', -1)
        commit('addAttached', data)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE MESSAGE
   *----------------------------------------------------------------------------*/
  async deleteMessage({ commit, dispatch }, message) {
    commit('deletingState', true)
    
    await message.ref.delete()
    .then(() => {
      commit('deletingState', false)
      commit('removeMessage', message)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('deletingState', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
import Vue from 'vue'
import _omit from 'lodash/omit'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  status: {
    saving: false,
    getting: false,
    error: null,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  savingState(state, bol) {
    state.status.saving = bol
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  setCopy(state, payload) {
    let data
    
    try {
      data = Vue.prototype.$formatData(payload)
    }
    catch {
      data = payload
    }

    state.data = data
    state.status.getting = false
  },

  setError(state, message) {
    state.status.error = message
  }
}

/*------------------------------------------------------------------------------
 * METHODS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GETTING COPY
   *----------------------------------------------------------------------------*/
  getCopy({ commit, dispatch }, section) {
    commit('gettingState', true)

    section.ref.collection('copy')
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        commit('setCopy', doc)
      }
      else {
        commit('gettingState', false)
        commit('setCopy', {})
      }
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('gettingState', false)
      console.log('copy.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * SAVE COPY
   *----------------------------------------------------------------------------*/
  saveCopy({ state, commit, dispatch }, section) {
    commit('savingState', true)
    if (state.status.error) commit('setError', null)

    if (!state.data.url && !state.data.copy) {
      commit('setError', 'Please provide url or copy.')
      commit('savingState', false)
    }
    else {
      let ref
      let data = state.data
      data.created = Date.now()
      
      if (!data.id) ref = section.ref.collection('copy').doc()
      else ref = section.ref.collection('copy').doc(data.id)

      ref.set(_omit(data, ['ref', 'id']))
      .then(() => {
        commit('savingState', false)
        dispatch('showSuccess', 'Copy successfully updated.',{ root: true })
      })
      .catch(error => {
        console.log(error.message)
        commit('savingState', false)
        dispatch('setError', error.message)
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
import Vue from 'vue'
import moment from 'moment'
import router from '@/router'
import { omit } from 'lodash'
import firebase from 'firebase'
import db from '@/firebase/init'

var storageRef = firebase.storage().ref()

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const initialSearchState = () => {
  return {
    search: null,
    client: null,
    assignee: null,
    orderType: null,
    orderNumber: null,
    companyProfile: null,
  }
}

const initialState = () => {
  return {
    files: [],
    requests: [],
    packages: [],
    response: {},
    pendingRequests: [],
    cannedResponses: [],
    completedRequests: [],
    date: null,
    status: {
      search: {},
      error: null,
      filecount: 0,
      getting: false,
      priority: null,
      updating: false,
      deleting: false,
      uploading: false,
      assigning: false,
      firstLoad: false,
      defaultUser: null,
      submitting: false,
      lastVisible: null,
      addingType: false,
      defaultProfile: null,
      gettingRequest: false,
      updatingStatus: false,
      creatingPackage: false,
      gettingPackages: false,
      deletingPackage: false,
      sortBy: 'requestNumber',
      creatingResponse: false,
      gettingResponses: false,
      pendingFirstLoad: false,
      employeeView: 'assignee',
      firstLoadPackages: false,
      lastPendingReviews: null,
      completedFirstLoad: false,
      lastFollowerVisible: null,
      deletingCannedResponse: false,
      firstLoadPendingReviews: false,
    },
    search: initialSearchState()
  }
}

const state = initialState()

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  haveAccess: (state, getters, rootState) => (request) => {
    let user = rootState.user.user

    if (user && user.role === 'client') {
      let companies = rootState.brand_profile.companies.map(c => c.id)
      return user.userid == request.user || companies.includes(request.company_profile)
    }
    else if (user && request) {
      return user.role == 'admin' || user.userid == request.user || user.organization
    }
    else {
      return false
    }
  },

  request: (state) => (id) => {
    if (state.requests) {
      return state.requests.find(r => r.id == id) || {}
    }
  },

  opens: (state, getters, rootState) => {
    if (rootState.user.user) {
      let user = rootState.user.user
      return state.requests.filter(request => {
        if (user.role == 'admin') return request.status == 'open' && !request.assignee
        else if (user.organization) return request.status == 'open' && request.assignee == user.userid
        else return request.status == 'open' || request.status == 'progress'
      })
    }
    else {
      return []
    }
  },

  delivereds: (state) => {
    return state.requests.filter(request => {
      return request.status == 'delivered'
    })
  },

  pauseds: (state) => {
    return state.requests.filter(request => {
      return request.status == 'paused'
    })
  },

  assigneds: (state) => {
    return state.requests.filter(request => {
      return request.assignee && request.status == 'open'
    })
  },

  inProgress: (state) => {
    return state.requests.filter(request => {
      return request.status == 'progress'
    })
  },

  getAttachments: (state) => (order) => {
    if (state.files) {
      return state.files.filter(file => file.order == order)
    }
  },

  messageAttachments: (state) => (id) => {
    if (state.files) {
      return state.files.filter(file => file.messageId == id)
    }
  },

  canManage: (state, getters, rootState) => {
    if (rootState.user.user) {
      let user = rootState.user.user
      return user.role == 'admin' || (user.organization && user.manageRequests)
    }
    else return false
  },

  canChangeStatus: (state, getters, rootState) => (request) => {
    if (rootState.user.user) {
      let user = rootState.user.user
      return user.role == 'admin' || (user.organization && user.manageRequests && user.manageAllRequests) || request.assignee == user.userid
    }
    else return false
  },

  rPackage: (state) => (id) => {
    let rPackage = state.packages.find(p => p.id == id)
    return rPackage || { types: [] }
  },

  activeRequests: (state) => {
    let user = firebase.auth().currentUser

    let requests = state.requests.filter(request => {
      return request.user == user.uid && !['completed', 'paused'].includes(request.status)
    })

    return requests
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  setRequests(state, payload) {
    if (payload.size) {
      payload.forEach(request => {
        let data = request.data()
        data.id = request.id
        data.ref = request.ref
        if (!state.requests.find(r => r.id == data.id))
          state.requests.push(data)
      })

      state.status.lastVisible = payload.docs[payload.docs.length - 1]
    }

    state.status.getting = false
  },

  setCompletedRequests(state, payload) {
    if (payload.size) {
      payload.forEach(request => {
        let data = request.data()
        data.id = request.id
        data.ref = request.ref
        if (!state.completedRequests.find(r => r.id == data.id))
          state.completedRequests.push(data)
      })

      state.status.lastCompletedVisible = payload.docs[payload.docs.length - 1]
    }

    state.status.getting = false
  },

  setPendingRequests(state, payload) {
    if (payload.size) {
      payload.forEach(request => {
        let data = request.data()
        data.id = request.id
        data.ref = request.ref
        if (!state.pendingRequests.find(r => r.id == data.id))
          state.pendingRequests.push(data)
      })

      state.status.lastPendingReviews = payload.docs[payload.docs.length - 1]
    }

    state.status.getting = false
  },

  insertRequest(state, payload) {
    let request = state.requests.find(r => r.id == payload.id)
    let data

    try {
      data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
    }
    catch {
      data = payload
    }

    if (!request) state.requests.push(data)
    else Vue.set(state.requests, state.requests.indexOf(request), data)
  },

  insertPendingRequest(state, payload) {
    let request = state.pendingRequests.find(r => r.id == payload.id)
    let data

    try {
      data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
    }
    catch {
      data = payload
    }

    if (!request) state.pendingRequests.push(data)
    else Vue.set(state.pendingRequests, state.pendingRequests.indexOf(request), data)
  },

  insertCompletedRequest(state, payload) {
    let request = state.completedRequests.find(r => r.id == payload.id)
    let data

    try {
      data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
    }
    catch {
      data = payload
    }

    if (!request) state.completedRequests.push(data)
    else Vue.set(state.completedRequests, state.completedRequests.indexOf(request), data)
  },

  modifyRequest(state, payload) {
    let request = state.requests.find(r => r.id == payload.id)
    let pendingRequest = state.pendingRequests.find(r => r.id == payload.id)
    let completedRequest = state.completedRequests.find(r => r.id == payload.id)
    let data

    try {
      data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
    }
    catch {
      data = payload
    }

    if (request) Vue.set(state.requests, state.requests.indexOf(request), data)
    if (pendingRequest) Vue.set(state.pendingRequests, state.pendingRequests.indexOf(pendingRequest), data)
    if (completedRequest) Vue.set(state.completedRequests, state.completedRequests.indexOf(completedRequest), data)
  },

  addFileCount(state, count) {
    state.status.filecount += count
  },

  subtractFileCount(state) {
    state.status.filecount -= 1
    if (!state.status.filecount) state.status.uploading = false
  },

  setFiles(state, payload) {
    state.files = []

    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.files.push(data)
      })
    }
  },

  insertFile(state, payload) {
    if (!state.files.find(f => f.id == payload.id)) {
      state.files.push(payload)
    }
  },

  changeStatus(state, payload) {
    let request = payload.request
    let status = payload.status

    let index = state.requests.indexOf(request)
    let newData = Object.assign({}, request)
    newData.status = status
    Vue.set(state.requests, index, newData)
  },

  updateRequestData(state, payload) {
    let index = payload.index
    let newData = payload.newData
    Vue.set(state.requests, index, newData)
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  updateField(state, payload) {
    Vue.set(payload.request, payload.field, payload.value)
  },

  removeRequest(state, payload) {
    let request = state.requests.find(r => r.id == payload.id)
    if (request) state.requests.splice(state.requests.indexOf(request), 1)
    else {
      request = state.pendingRequests.find(r => r.id == payload.id)
      if (request) state.pendingRequests.splice(state.pendingRequests.indexOf(request), 1)
      else {
        request = state.completedRequests.find(r => r.id == payload.id)
        if (request) state.completedRequests.splice(state.completedRequests.indexOf(request), 1)
      }
    }
  },

  clearRequests(state) {
    state.requests = []
    state.completedRequests = []
  },

  resetState(state) {
    Object.assign(state, initialState())
  },

  addPackage(state, payload) {
    let requestPackage = state.packages.find(p => p.id == payload.id)
    if (!requestPackage)
      state.packages.push(payload)
    else
      Vue.set(state.packages, state.packages.indexOf(requestPackage), payload)
  },

  setPackages(state, payload) {
    state.packages = []

    if (payload.size) {
      payload.forEach(doc => {
        let rPackage = doc.data()
        rPackage.id = doc.id
        rPackage.ref = doc.ref

        if (!state.packages.find(p => p.id == rPackage.id))
          state.packages.push(rPackage)
      })
    }

    state.status.gettingPackages = false
  },

  addResponse(state, payload) {
    let response = state.cannedResponses.find(r => r.id == payload.id)

    if (!response)
      state.cannedResponses.push(payload)
    else
      Vue.set(state.cannedResponses, state.cannedResponses.indexOf(response), payload)
  },

  setCannedResponses(state, payload) {
    state.cannedResponses = []

    if (payload.size) {
      payload.forEach(doc => {
        let response = doc.data()
        response.id = doc.id
        response.ref = doc.ref
        state.cannedResponses.push(response)
      })
    }

    state.status.gettingResponses = false
  },

  removeCannedResponse(state, payload) {
    let response = state.cannedResponses.find(r => r.id == payload.id)
    if (response)
      state.cannedResponses.splice(state.cannedResponses.indexOf(response), 1)
  },

  resetSearchState(state) {
    state.search.assignee = null
    state.search.orderType = null
    state.search.orderNumber = null
    state.search.companyProfile = null
  },

  resetRequests(state) {
    state.requests = []
    state.pendingRequests = []
    state.completedRequests = []
    state.status.lastVisible = null
    state.status.lastCompletedVisible = null
    state.status.lastPendingReviews = null
  },

  setSearch(state, value) {
    state.search.search = value
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE REQUEST
   *
   * @params
   *  Object
   *    fields: Array
   *    category: String,
   *    type: String
   *----------------------------------------------------------------------------*/
  createRequest({ state, commit, dispatch, rootState, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      commit('updateStatus', { submitting: true })

      let user = rootState.user.user
      var userPackages = []
      var priorities = []
      let entries = []
      let type = data.type
      let fields = data.fields
      var priority = 'low'
      let category = data.category

      if (user.requestPackages && user.requestPackages.length) {
        userPackages = state.packages.filter(rPackage => user.requestPackages.includes(rPackage.id))
        priorities = userPackages.map(pkg => pkg.priority)
      }

      if (user.requestPackage) {
        let pkg = state.packages.find(p => p.id == user.requestPackage)
        priority = pkg.priority
      }

      let level = {
        low: 0,
        medium: 1,
        high: 2,
      }

      if (priorities.length) {
        for (var prio of priorities) {
          if (level[prio] > level[priority]) {
            priority = prio
          }
        }
      }

      if (state.status.priority) {
        priority = state.status.priority
      }

      // ITERATE FIELD ENTRIES DATA
      fields.forEach(field => {
        // CHECK FOR ATTACHMENTS
        if (field.type == 'attachment') {
          if (field.value && field.value.length) {
            commit('addFileCount', field.value.length)
            commit('updateStatus', { uploading: true })
          }
        }

        entries.push({
          type: field.type,
          order: field.order,
          label: field.label,
          description: field.description || null,
          value: field.type === 'attachment' ? null : (field.value || null),
        })
      })

      db.collection('requests')
        .orderBy('requestNumber', 'desc')
        .limit(1).get()
        .then(snapshot => {
          let number = 1

          if (snapshot.size) {
            let lastRequest = snapshot.docs[0]
            number = lastRequest.data().requestNumber
            number++
          }

          let newData = {
            entries,
            priority,
            requestType: type.id,
            status: 'in_progress',
            requestCategory: category,
            assignee: type.assignee || null,
            requestNumber: parseInt(number),
            followers: type.followers || null,
            created: firebase.firestore.Timestamp.now(),
            user: state.status.defaultUser || user.userid,
            company_profile: state.status.defaultProfile || data.company_profile,
          }

          if (type.expected_delivery && Object.keys(type.expected_delivery).length) {
            let dueDate = moment().add(type.expected_delivery.time, `${type.expected_delivery.type}s`)
            newData.dueDate = firebase.firestore.Timestamp.fromDate(new Date(dueDate))
          }

          db.collection('requests')
            .add(newData)
            .then((docRef) => {
              newData.ref = docRef
              newData.id = docRef.id
              commit('insertRequest', newData)
              if (!state.status.uploading) commit('updateStatus', { submitting: false })
              if (state.status.uploading) dispatch('uploadFiles', { fields, docRef })

              // CREATE LOG HISTORY
              let historyData = {
                collection: 'requests',
                document: docRef.id,
                action: 'created',
                user: user.userid,
              }

              dispatch('log_histories/createHistory', historyData, { root: true })

              // SEND WEBHOOK TO GOOGLE CHAT
              if (type.webhook) {
                let hookData = {
                  text: `*${rootGetters['users/user'](state.status.defaultUser || user.userid).fullName}* submitted a new _${type.name}_ request. View request details ${window.origin}${router.resolve({ name: 'Request', params: { id: docRef.id } }).href}`,
                  url: type.webhook
                }

                dispatch('webhook/googleChat', hookData, { root: true })
              }

              resolve(docRef.id)
            })
        })
        .catch(error => {
          console.log(error.message)
          commit('updateStatus', { submitting: false })
          reject(error.message)
        })
    })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD FILES
   *----------------------------------------------------------------------------*/
  uploadFiles({ commit, dispatch }, data) {
    let fields = data.fields
    let docRef = data.docRef

    fields.forEach(entry => {
      if (entry.type == 'attachment' && entry.value && entry.value.length) {
        entry.value.forEach(async (file) => {
          let name = `${Date.now()}_${file.name}`

          var metadata = {
            contentType: file.type
          }

          var uploadTask = storageRef.child(`requests/${name}`).put(file, metadata)

          await uploadTask.on('state_changed', snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log(progress)
          }, error => {
            dispatch('showError', error.message, { root: true })
            console.log(error.message)
          }, () => {

            docRef.collection('attachments')
              .add({
                file: name,
                order: entry.order,
                type: file.type,
                size: file.size,
                created: Date.now()
              })
              .then(() => {
                commit('subtractFileCount')
              })
              .catch(error => {
                console.log(error.message)
              })
          })
        })
      }
    })
  },

  /*------------------------------------------------------------------------------
   * GET REQUESTS
   *
   * @params
   *  Object
   *    status: Array,
   *    
   *----------------------------------------------------------------------------*/
  getRequests({ state, commit, dispatch, rootState }, type = 'open') {
    if (type == 'open') commit('updateStatus', { firstLoad: true })
    else if (type == 'completed') commit('updateStatus', { completedFirstLoad: true })
    else if (type == 'pending') commit('updateStatus', { pendingFirstLoad: true })

    commit('updateStatus', { getting: true })
    var limit = 15

    let user = rootState.user.user
    let task = db.collection('requests')
    let statuses = ['in_progress', 'checking_quality', 'reviewing_with_client', 'paused', 'escalated']

    if (user && user.role === 'client') {
      statuses.push('pending_review')
    }

    if (user) {
      // IF SEARCH TYPE IS SELECTED
      if (Object.keys(state.status.search).length) {
        if (state.status.search.type == 'due_date') {
          task = task.where('dueDate', '==', firebase.firestore.Timestamp.fromDate(new Date(`${state.status.search.value} 00:00:00`)))
        }
        else if (state.status.search.type == 'follower') {
          task = task.where('followers', 'array-contains', state.status.search.value)
        }
        else {
          task = task.where(`${state.status.search.type}`, '==', state.status.search.value)
        }
      }
      // IF USER IS AN EMPLOYEE BUT NOT ADMIN
      else if (user.organization && user.role !== 'admin' && (!user.manageRequests || (user.manageRequests && !user.manageAllRequests))) {
        if (state.status.employeeView == 'assignee')
          task = task.where('assignee', '==', user.userid)
        else if (state.status.employeeView == 'follower')
          task = task.where('followers', 'array-contains', user.userid)
      }
      // IF IS REGULAR USER
      else if (user.role !== 'admin' && !user.organization) {
        task = task.where('user', '==', user.userid)
      }

      // CHECK IF REQUESTED FOR COMPLETED OR OPEN REQUESTS
      var statusType = statuses
      if (type == 'completed') statusType = ['completed']
      else if (type == 'pending') statusType = ['pending_review']
      task = task.where('status', 'in', statusType)

      // ORDER
      if (state.status.search.type !== 'requestNumber')
        task = task.orderBy(state.status.sortBy, state.status.sortBy == 'requestNumber' ? 'desc' : 'asc')

      // CHECK LAST VISIBLE
      if (type == 'open' && state.status.lastVisible) task = task.startAfter(state.status.lastVisible)
      else if (type == 'completed' && state.status.lastCompletedVisible) task = task.startAfter(state.status.lastCompletedVisible)
      else if (type == 'pending' && state.status.lastPendingReviews) task = task.startAfter(state.status.lastPendingReviews)

      task.limit(limit)
        .onSnapshot(snapshot => {
          if (type == 'open') {
            commit('setRequests', snapshot)
          }
          else if (type == 'completed') {
            commit('setCompletedRequests', snapshot)
          }
          else if (type == 'pending') {
            commit('setPendingRequests', snapshot)
          }

          snapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {
              commit('modifyRequest', change.doc)
            }
            if (change.added === "added") {
              let status = change.doc.data().status

              if (statuses.includes.status(status)) {
                commit('insertRequest', change.doc)
              }
              else if (status === 'pending_review') {
                commit('insertPendingRequest', change.doc)
              }
              else if (status === 'completed') {
                commit('insertCompletedRequest', change.doc)
              }
            }
          })

          if (snapshot.size) {
            snapshot.forEach(doc => {
              dispatch('users/getUserUid', doc.data().user, { root: true })
              dispatch('types/getType', doc.data().requestCategory, { root: true })
              dispatch('types/getProduct', { type: doc.data().requestCategory, product: doc.data().requestType }, { root: true })
              if (doc.data().assignee) dispatch('users/getUserUid', doc.data().assignee, { root: true })
            })
          }
          else {
            commit('updateStatus', { getting: false })
          }
        })
    }

  },

  /*------------------------------------------------------------------------------
   * GET REQUESTS AS FOLLOWER
   *
   * @params
   *  Object
   *    id: String (auth uid)
   *    status: Array
   *----------------------------------------------------------------------------*/
  getRequestsAsFollower({ commit }, data) {
    db.collection('requests')
      .where('followers', 'array-contains', data.id)
      .where('status', 'in', data.status)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let request = doc.data()
            request.id = doc.id
            request.ref = doc.ref
            commit('insertRequest', request)
          })
        }
      })
  },

  /*------------------------------------------------------------------------------
   * GET REQUEST BY ID
   *----------------------------------------------------------------------------*/
  getRequest({ state, commit, dispatch }, id) {
    commit('updateStatus', { gettingRequest: true })
    let request = state.requests.find(r => r.id == id)

    if (!request) {
      db.collection('requests')
        .doc(id).get()
        .then(doc => {
          if (doc.exists) {
            commit('insertRequest', doc)
            dispatch('types/getType', doc.data().requestCategory, { root: true })
            dispatch('types/getProduct', {
              type: doc.data().requestCategory,
              product: doc.data().requestType,
            }, { root: true })
            dispatch('users/getUserUid', doc.data().user, { root: true })
            if (doc.data().assignee) dispatch('users/getUserUid', doc.data().assignee, { root: true })
          }

          dispatch('getFiles', doc.ref)
          commit('updateStatus', { gettingRequest: false })
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
          commit('updateStatus', { gettingRequest: false })
        })
    }
    else {
      commit('updateStatus', { gettingRequest: false })
      dispatch('getFiles', request.ref)
    }
  },

  /*------------------------------------------------------------------------------
   * GET FILES
   *----------------------------------------------------------------------------*/
  getFiles({ commit }, ref) {
    ref.collection('attachments')
      .get()
      .then(snapshot => {
        commit('setFiles', snapshot)
      })
      .catch(error => {
        console.log(error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * INSERT FILE
   *----------------------------------------------------------------------------*/
  insertFile({ commit }, file) {
    commit('insertFile', file)
  },

  /**------------------------------------------------------------------------------
   * UPDATE STATUS
   * @params
   *  data: {
   *    request: Request Object
   *    status: in_progress | completed | pending_review | closed | completed  
   * }
   *----------------------------------------------------------------------------*/
  updateStatus({ commit, dispatch }, data) {
    commit('updateStatus', { updatingStatus: true })
    let status = data.status
    let request = data.request
    let user = firebase.auth().currentUser

    request.ref.update({ status, [status]: Date.now() })
      .then(() => {
        dispatch('showSuccess', 'Status updated', { root: true })
        commit('updateStatus', { updatingStatus: false })
        commit('changeStatus', { request, status })

        let messages = {
          delivered: 'updated status to delivered',
          open: 'reopened the request',
          paused: 'paused the request',
          unpaused: 'unpaused the request',
          in_progress: 'moved status to in progress',
          closed: 'closed the request',
        }

        request.ref.collection('messages')
          .add({
            message: messages[status],
            sender: user.uid,
            type: 'status_change',
            created: firebase.firestore.Timestamp.now(),
          })
      })
      .catch(error => {
        console.log(error)
        dispatch('showError', error.message, { root: true })
        commit('updateStatus', { updatingStatus: false })
      })
  },

  /*------------------------------------------------------------------------------
   * ASSIGN REQUEST
   *----------------------------------------------------------------------------*/
  async assignRequest({ state, commit, dispatch }, data) {
    commit('updateStatus', { assigning: true })
    let request = data.request
    let deadline = data.deadline
    let assignee = data.assignee.userid

    await request.ref.update({
      assignee,
      deadline,
      assigned: Date.now()
    })
      .then(() => {
        commit('updateStatus', { assigning: false })
        let index = state.requests.indexOf(request)
        let newData = Object.assign({}, request)
        newData.assignee = assignee
        newData.deadline = deadline
        newData.assigned = Date.now()
        commit('updateRequestData', { index, newData })
        dispatch('users/getUserUid', assignee, { root: true })
        dispatch('showSuccess', 'Request assigned', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { assigning: false })
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * UPDATE REQUEST FIELD
   * 
   * @params
   *  Object
   *    request: Object
   *    field: String
   *    value: Any
   *    message: String (optional)
   *    silent: Boolean (optional)
   *----------------------------------------------------------------------------*/
  async updateRequestField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })

    await data.request.ref
      .update({
        [data.field]: data.value,
        updated: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        commit('updateField', data)
        commit('updateStatus', { updating: false })
        if (!data.silent) dispatch('showSuccess', data.message || 'Request updated', { root: true })
      })
      .catch(error => {
        commit('updateStatus', { updating: false })
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * DELETE REQUEST
   *
   * @params
   *  request: Object (doc)
   *----------------------------------------------------------------------------*/
  async deleteRequest({ commit, dispatch }, request) {
    commit('updateStatus', { deleting: true })
    let user = firebase.auth().currentUser

    var deleteFn = firebase.functions().httpsCallable('recursiveDelete')

    await deleteFn({ path: request.ref.path })
      .then(() => {
        commit('removeRequest', request)
        commit('updateStatus', { deleting: false })
        dispatch('showSuccess', 'Request deleted', { root: true })

        // LOG ACTION
        dispatch('log_histories/createHistory', {
          document: request.requestNumber,
          collection: 'requests',
          action: 'deleted',
          user: user.uid,
        }, { root: true })
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { deleting: false })
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * CREATE PACKAGE
   *----------------------------------------------------------------------------*/
  async createPackage({ commit, dispatch }, pData) {
    commit('updateStatus', { creatingPackage: true })
    let data = { types: [] }
    data.name = pData.name
    data.hours = pData.hours
    data.created = firebase.firestore.Timestamp.now()
    data.user = firebase.auth().currentUser.uid,

      await db.collection('request_packages')
        .add(data)
        .then((docRef) => {
          data.id = docRef.id
          data.ref = docRef
          commit('addPackage', data)
          commit('updateStatus', { creatingPackage: false })
          dispatch('showSuccess', 'Package successfully created', { root: true })
        })
        .catch(error => {
          console.log(error.message)
          commit('updateStatus', { creatingPackage: false })
          dispatch('showError', error.message, { root: true })
        })
  },

  /*------------------------------------------------------------------------------
   * GET PACKAGES
   *----------------------------------------------------------------------------*/
  getPackages({ commit, dispatch }) {
    commit('updateStatus', { gettingPackages: true })
    commit('updateStatus', { firstLoadPackages: true })

    db.collection('request_packages')
      .get()
      .then(snapshot => {
        commit('setPackages', snapshot)
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('updateStatus', { gettingPackages: false })
      })
  },

  /*------------------------------------------------------------------------------
   * DELETE PACKAGE
   *----------------------------------------------------------------------------*/
  async deletePackage({ state, commit, dispatch }, rPackage) {
    commit('updateStatus', { deletingPackage: true })

    await rPackage.ref.delete()
      .then(() => {
        commit('updateStatus', { deletingPackage: false })
        dispatch('showSuccess', 'Package deleted', { root: true })
        commit('removeObject', { collection: state.packages, object: rPackage }, { root: true })
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { deletingPackage: false })
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * SAVE PACKAGE TYPE
   *
   * @params
   *  Object rPackage
   *----------------------------------------------------------------------------*/
  savePackageType({ dispatch }, rPackage) {
    rPackage.ref.update(omit(rPackage, ['id', 'ref']))
      .then(() => {
        dispatch('showSuccess', 'Request package updated', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * SAVE PACKAGE FIELD
   *
   * @Params
   *  Object
   *   Object rPackage
   *   String field
   *   String value
   *   Boolean silent (optional)
   *----------------------------------------------------------------------------*/
  savePackageField({ commit, dispatch }, data) {
    data.rPackage.ref
      .update({
        [data.field]: data.value,
        updated: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        commit('updateObjectField', {
          object: data.rPackage,
          field: data.field,
          value: data.value
        },
          { root: true })
        if (!data.silent) dispatch('showSuccess', 'Request package updated', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET PACKAGE
   * 
   * @Params
   *  String id
   *----------------------------------------------------------------------------*/
  async getPackage({ commit }, id) {
    await db.collection('request_packages')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          commit('addPackage', data)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * GET REQUESTS AS MEMBER
   *----------------------------------------------------------------------------*/
  async getRequestsAsMember({ rootState, commit }, status = 'open') {
    let statuses = status === 'open' ? ['in_progress', 'checking_quality', 'reviewing_with_client', 'paused', 'escalated', 'pending_review'] : ['completed']
    let companies = rootState.brand_profile.companies.map(c => c.id)
    var promises = []

    for (var company of companies) {
      let promise = await db.collection('requests')
        .where('status', 'in', statuses)
        .where('company_profile', '==', company)
        .get()

      promises.push(promise)
    }

    Promise.all(promises)
      .then(snapshots => {
        if (snapshots.length) {
          snapshots.forEach(snapshot => {
            if (snapshot.size) {
              snapshot.forEach(doc => {
                if (status === 'open') {
                  commit('insertRequest', doc)
                }
                else {
                  commit('insertCompletedRequest', doc)
                }
              })
            }
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
import db from '@/firebase/init'
import firebase from 'firebase'
import pluralize from 'pluralize'
import router from '@/router'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  collaborators: [],
  status: {
    getting: false,
    adding: false,
    deleting: false,
    requesting: false,
    accepting: [],
    acceptingAll: false,
    leaveProjectDialog: false,
    toLeave: null,
    leavingProject: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  hasPendingRequest: (state) => {
    if (state.collaborators) {
      let user = firebase.auth().currentUser
      if (user) return !!state.collaborators.find(collab => collab.user == user.uid && collab.status == 'pending')
      else return false
    }
  },
  
  pendings: (state) => {
    if (state.collaborators) {
      return state.collaborators.filter(collab => collab.status == 'pending')
    }
  },
  
  isCollaborator: (state) => {
    if (state.collaborators) {
      let user = firebase.auth().currentUser
      if (user) return !!state.collaborators.find(collab => collab.user == user.uid)
      else return false
    }
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setCollaborators(state, payload) {
    state.collaborators = []
    
    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.collaborators.push(data)
      })
    }

    state.status.getting = false
  },

  addingState(state, bol) {
    state.status.adding = bol
  },

  insertCollaborator(state, payload) {
    if (!state.collaborators.find(collab => collab.user == payload.user))
    state.collaborators.push(payload)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeCollaborator(state, payload) {
    state.collaborators.splice(state.collaborators.indexOf(payload), 1)
  },

  requestingState(state, bol) {
    state.status.requesting = bol
  },

  acceptRequestState(state, id) {
    if (!state.status.accepting.find(a => a.id == id)) state.status.accepting.push(id)
    else {
      let collab = state.status.accepting.find(a => a.id == id)
      state.status.accepting.splice(state.status.accepting.indexOf(collab), 1)
    }
  },

  setPendingToActive(state, payload) {
    payload.status = 'active'
  },

  acceptingAllState(state, bol) {
    state.status.acceptingAll = bol
  },

  leaveProjectDialogState(state, bol) {
    state.status.leaveProjectDialog = bol
  },

  toLeaveState(state, payload) {
    state.status.toLeave = payload
  },

  leavingProjectState(state, bol) {
    state.status.leavingProject = bol
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET PROJECT COLLABORATORS
   *----------------------------------------------------------------------------*/
  async getCollaborators({ commit, dispatch }, id) {
    commit('gettingState', true)
    
    await db.collection('projects').doc(id)
    .collection('collaborators')
    .onSnapshot(snapshot => {
      commit('setCollaborators', snapshot)
      
      if (snapshot.size) {
        snapshot.forEach(doc => dispatch('users/getUserUid', doc.data().user, { root: true }))
      }
      else commit('gettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * ADD COLLABORATORS
   *----------------------------------------------------------------------------*/
  async addCollaborators({ commit, dispatch, rootState }, collabs) {
    commit('addingState', true)
    let batch = db.batch()
    let project = rootState.project.project

    collabs.forEach(collab => {
      let docRef = project.ref.collection('collaborators').doc()
      let data = {
        user: collab,
        status: 'active',
        created: Date.now()
      }

      batch.set(docRef, data)
      data.ref = docRef
      data.id = docRef.id
      commit('insertCollaborator', data)

      // CREATE NOTIFICATION
      dispatch('notifications/createNotification', {
        recipient: data.user,
        type: 'collaborator_added',
        origin: project.id
      }, { root: true })
    })
    
    await batch.commit()
    .then(() => {
      commit('addingState', false)
      dispatch('showSuccess', `${pluralize('User', collabs.length)} successfully added as collobarator`, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE COLLABORATOR
   *----------------------------------------------------------------------------*/
  async deleteCollaborator({ commit, dispatch }, collab) {
    commit('deletingState', true)
    
    await collab.ref.delete()
    .then(() => {
      commit('deletingState', false)
      commit('removeCollaborator', collab)
      dispatch('showSuccess', 'Collaborator removed', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * REQUEST ACCESS
   *----------------------------------------------------------------------------*/
  requestAccess({ commit, dispatch }, project) {
    commit('requestingState', true)
    let user = firebase.auth().currentUser    
    
    let data = {
      user: user.uid,
      status: 'pending',
      created: Date.now()
    }
    
    project.ref.collection('collaborators')
    .add(data)
    .then((docRef) => {
      data.ref = docRef
      data.id = docRef.id
      commit('insertCollaborator', data)
      commit('requestingState', false)
    })
    .catch(error => {
      console.log(error.message)
      commit('requestingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * ACCEPT REQUEST
   *----------------------------------------------------------------------------*/
  acceptRequest({ commit, dispatch }, collab) {
    commit('acceptRequestState', collab.id)
    
    collab.ref.update({ status: 'active' })
    .then(() => {
      commit('setPendingToActive', collab)
      commit('acceptRequestState', collab.id)
    })
    .catch(error => {
      console.log(error.message)
      commit('acceptRequestState', collab.id)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * ACCEPT ALL REQUESTS
   *----------------------------------------------------------------------------*/
  acceptAll({ state, commit, dispatch }) {
    commit('acceptingAllState', true)
    let batch = db.batch()
    let pendings = state.collaborators.filter(collab => collab.status == 'pending')
    
    pendings.forEach(collab => {
      batch.update(collab.ref, { status: 'active' })
    })
    
    batch.commit()
    .then(() => {
      commit('acceptingAllState', false)
      dispatch('showSuccess', 'Accepted all requests', { root: true })

      pendings.forEach(collab => {
        commit('setPendingToActive', collab)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * LEAVE PROJECT
   *----------------------------------------------------------------------------*/
  leaveProject({ state, commit, dispatch }) {
    commit('leavingProjectState', true)
    let user = firebase.auth().currentUser
    
    state.status.toLeave.ref
    .collection('collaborators')
    .where('user', '==', user.uid)
    .limit(1).get()
    .then((snapshot) => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        doc.ref.delete()
        .then(() => {
          router.push({ name: 'Projects' })
          commit('projects/removeProject', state.status.toLeave, { root: true })
          commit('leaveProjectDialogState', false)
          commit('leavingProjectState', false)
          dispatch('showSuccess', 'You left as collaborator', { root: true })
        })
      }
    })
    .catch(error => {
      commit('leavingProjectState', false)
      console.log(error.message)
    })
  }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
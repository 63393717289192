import Vue from 'vue'
import db from '@/firebase/init'
import firebase from 'firebase'
import _omit from 'lodash/omit'
import router from '@/router'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  projects: [],
  data: {
    folder: 'root'
  },
  status: {
    error: null,
    moving: false,
    getting: false,
    creating: false,
    displayRange: 0,
    deleting: false,
    firstLoad: false,
    selectTemplate: false,
    projectToTemplate: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  isOwner: () => (project) => {
    let user = firebase.auth().currentUser
    return user && project.owner == user.uid
  },

  myProjectsCount: (state, getters) => {
    let projects = state.projects.filter(project => getters.isOwner(project))
    return projects.length
  },
  
  project: (state) => (id) => {
    let project = state.projects.find(p => p.id == id)
    return project || {}
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setProjects(state, payload) {
    state.projects = []

    if (payload.size) {
      payload.forEach(project => {
        let data = project.data()
        data.id = project.id
        data.ref = project.ref
        data.logo = data.logo || null
        state.projects.push(data)
      })
    }
    state.status.getting = false
  },

  creatingState(state, bol) {
    state.status.creating = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  insertProject(state, payload) {
    let user = firebase.auth().currentUser
    
    if (!state.projects.find(project => project.id == payload.id)) {
      let data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
      data.logo = data.logo || null
      if (user && data.owner !== user.uid) data.folder = 'root'
      state.projects.push(data)
    }
  },

  insertCollaborator(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    data.folder = 'root'
    data.collaborator = true
    if(state.projects.length){
      let exist = false
      state.projects.forEach(project => {
        if(project.id == data.id){
          exist = true
        }
      })
      if(!exist){
        state.projects.push(data)    
      }
    }else{
      state.projects.push(data)    
    }
  },

  setData(state, data) {
    state.data = Object.assign({}, data)
  },

  movingState(state, bol) {
    state.status.moving = bol
  },

  updateProjectFolder(state, data) {
    state.projects.forEach(project => {
      if (project.id == data.id) {
        project.folder = data.folder
      }
    })
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  removeProject(state, project) {
    let proj = state.projects.find(p => p.id == project.id)
    state.projects.splice(state.projects.indexOf(proj), 1)
  },

  updateProjectLogo(state, payload) {
    let id = payload.id
    let name = payload.name
    let project = state.projects.find(project => project.id == id)

    if (project) {
      let index = state.projects.indexOf(project)
      project.logo = name
      if (project) Vue.set(state.projects, index, project)
    }
  },  

  updateProject(state, payload) {
    let project = state.projects.find(p => p.id == payload.id)
    let index = state.projects.indexOf(project)
    Vue.set(state.projects, index, payload)
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET PROJECTS
   *----------------------------------------------------------------------------*/
  async getProjects({ commit, dispatch }) {
    commit('gettingState', true)
    commit('setFirstLoad', true)
    let user = firebase.auth().currentUser

    if (!user) return;

    /*------------------------------------------------------------------------------
     * GET PROJECTS OWNED
     *----------------------------------------------------------------------------*/
    await db.collection('projects')
    .where('owner', '==', user.uid)
    .where('template', '!=', true)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setProjects', snapshot)
      }
      else commit('gettingState', false)
    })
    .catch(error => {
      commit('gettingState', false)
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })

    /*------------------------------------------------------------------------------
     * GET PROJECTS AS COLLABORATOR
     *----------------------------------------------------------------------------*/
    await db.collectionGroup('collaborators')
    .where('user', '==',  user.uid)
    .where('status', '==', 'active')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        snapshot.forEach(doc => {
          let projectRef = doc.ref.parent.parent

          projectRef.get()
          .then(doc => {
            if (doc.exists) {
              commit('insertProject', doc)
            }
          })
          .catch(error => {
            console.log(error.message)
          })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE PROJECT
   *----------------------------------------------------------------------------*/
  async create({ state, commit, getters, dispatch, rootState, rootGetters }, template = false) {
    commit('creatingState', true)
    let user = firebase.auth().currentUser
    let hasError = false
    let id = null

    let data = state.data
    data.owner = user ? user.uid : 'guest'
    data.createdAt = Date.now()
    data.template = template
    data.published = false

    if (rootState.user.user && rootState.user.user.role == 'user' && !rootState.user.user.organization && getters.myProjectsCount >= rootGetters['generalSettings/maxProjects']) {
      dispatch('showError', 'Maximum number of projects reached.', { root: true })
    }
    else {
      await db.collection('projects').add(data)
      .then(async (docRef) => {
        data.ref = docRef
        data.id = docRef.id

        if (data.template) commit('allProjects/addToTemplate', data, { root: true })

        // CREATE COOKIE IF GUEST
        if (!user) {
          Vue.$cookies.set('project', docRef.id)
        }
  
        dispatch('getProject', docRef.id)
        dispatch('showSuccess', 'Project successfully created.', { root: true })
        commit('setData', {})
        commit('creatingState', false)

        // CREATE DEFAULT PAGE
        commit('page/setData', { name: 'Home', home: true }, { root: true })

        await Promise.resolve(dispatch('page/create', docRef.id, { root: true }))
        .then(() => {
          if (!state.status.projectToTemplate) router.push(state.status.selectTemplate ? { name: 'Project', params: { id: docRef.id }, query: { template: true } } : { name: 'Project', params: { id: docRef.id } })
          else id = docRef.id
        })

      })
      .catch(error => {
        hasError = true
        commit('creatingState', false)
        commit('setError', error.message)
      })
    }


    return id || hasError
  },

  /*------------------------------------------------------------------------------
   * GET PROJECT
   *----------------------------------------------------------------------------*/
  getProject({ commit, dispatch }, id) {
    db.collection('projects')
    .doc(id).get()
    .then(doc => {
      if (doc.exists) {
        commit('insertProject', doc)
        dispatch('users/getUserUid', doc.data().owner, { root: true })
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE PROJECT
   *----------------------------------------------------------------------------*/
  async update({ state, commit, dispatch }) {
    commit('creatingState', true)
    let hasError = false

    await state.data.ref.update(_omit(state.data, ['id', 'ref']))
    .then(() => {
      commit('creatingState', false)
      commit('updateProject', state.data)
      dispatch('showSuccess', 'Project successfully updated.', { root: true })
    })
    .catch(error => {
      commit('creatingState', false)
      commit('setError', error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * MOVE TO FOLDER
   *----------------------------------------------------------------------------*/
  async updateFolder({ commit, dispatch }, data) {
    commit('movingState', true)
    let hasError = false

    await data.project.ref.update({ folder: data.folder })
    .then(() => {
      commit('movingState', false)
      commit('updateProjectFolder', {
        id: data.project.id,
        folder: data.folder
      })

      dispatch('showSuccess', 'Project moved successfully.', { root: true })
    })
    .catch(error => {
      commit('movingState', false)
      commit('setError', error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * DELETE PROJECT
   *----------------------------------------------------------------------------*/
  async deleteProject({ commit, dispatch }, project) {
    commit('updateStatus', { deleting: true })
    
    await project.ref
    .delete()
    .then(() => {
      commit('updateStatus', { deleting: false })
      commit('removeProject', project)
      commit('allProjects/removeProject', project, { root: true })
      dispatch('showSuccess', 'Project deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deleting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

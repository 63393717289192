<template>
  <v-menu
    transition="slide-y-transition"
    :close-on-content-click="false"
    max-width="300"
    width="300"
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-badge
        :value="!!count"
        content="!"
        color="orange"
        overlap
        bordered
      >
        <v-btn v-on="on" small icon>
          <v-icon size="20">mdi-bell</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-sheet>
      <v-card 
        class="overflow-y-auto"
        max-height="600"
        scrollable
        outlined  
      >

        <div class="pl-5">
          <v-switch
            label="Show opened notifications"
            @change="
              resetData()
              getNotifications()
            "
            v-model="includeOpened"
            color="accent"
            hide-details
            class="mb-0"
            outlined
            dense
            inset
          ></v-switch>
        </div>
        <!-- <v-card-text v-show="!refined.length" outlined>
          You don't have any notifications.
        </v-card-text> -->

        <v-card-text v-if="refined.length" class="pa-2">
          <v-divider class="my-3"></v-divider>

          <v-card
            class="rouned overflow-hidden mb-1"
            v-for="notif in refined"
            :class="{ 'green lighten-5': !notif.viewed }"
            :key="notif.id"
            flat
          >
            <v-list-item @click="doAction(notif)" outlined>
              <v-list-item-content
                class="grey--text text--darken-1"
                :style="{ lineHeight: '1.6 !important', fontSize: '0.8rem' }"
              >
                <template v-if="notif.type === 'collaborator_added'">
                  You are added as collaborator.
              </template>
                <!--  ORDER/REQUEST MESSAGE -->
                <template v-if="['request_message', 'order_message'].includes(notif.type)">
                  <div v-if="notif.version === '2.0'" class="d-flex align-start gap-10">
                    <UserPhoto
                      :size="30"
                      photoSize="thumb"
                      :id="notif.sender"
                      rounded
                    />
                    <div>
                      <div class="message mb-1">
                        <strong class="primary--text">{{ $store.getters["users/user"](notif.sender).fullName }}</strong> 
                        <template v-if="notif.type === 'request_message'"> left a message from request #{{ $store.getters["request/request"](notif.origin).requestNumber }}.</template>
                        <template v-if="notif.type === 'order_message'"> left a message from order #{{ $store.getters["orders/order"](notif.origin).orderNumber }}.</template>
                      </div>
                      <div class="caption grey--text text--lighten-1">
                        {{ notif.created | fromNowComplete }}
                      </div>
                    </div>
                  </div>
                  <div v-else>New request message.</div>
                </template>
                
                <!-- ORDER/REQUEST NOTE -->
                <template v-if="['request_note', 'order_note'].includes(notif.type)">
                  <div v-if="notif.version === '2.0'" class="d-flex align-start gap-10">
                    <UserPhoto
                      :size="30"
                      photoSize="thumb"
                      :id="notif.sender"
                      rounded
                    />
                    <div>
                      <div class="message mb-1">
                        <strong class="primary--text">{{ $store.getters["users/user"](notif.sender).fullName }}</strong>
                        <span v-if="notif.type === 'request_note'"> left a note from request #{{ $store.getters["request/request"](notif.origin).requestNumber }}.</span>
                        <span v-if="notif.type === 'order_note'"> left a note from order #{{ $store.getters["orders/order"](notif.origin).orderNumber }}.</span>
                      </div>
                      <div class="caption grey--text text--lighten-1">
                        {{ notif.created | fromNowComplete }}
                      </div>
                    </div>
                  </div>
                  <div v-else>New request message.</div>
                </template>

                <template v-if="notif.type === 'member_invite'">
                  <MemberInvite :notif="notif" @deleted="removeInvite(notif)" />
                </template>

                <!-- REQUEST MESSAGE MENTIONED -->
                <template v-if="notif.type === 'request_message_mentioned'">
                  <div class="d-flex align-start gap-10">
                    <UserPhoto
                      :size="30"
                      photoSize="thumb"
                      :id="notif.sender"
                      rounded
                    />
                    <div>
                      <div class="message mb-1">
                        You've been mentioned by
                        <strong class="primary--text">{{
                          $store.getters["users/user"](notif.sender).fullName
                        }}</strong>
                        in a message from request #{{
                          $store.getters["request/request"](notif.origin)
                            .requestNumber
                        }}.
                      </div>
                      <div class="caption grey--text text--lighten-1">
                        {{ notif.created | fromNowComplete }}
                      </div>
                    </div>
                  </div>
                </template>
                <!-- REQUEST MESSAGE MENTIONED -->
                <template v-if="notif.type === 'request_note_mentioned'">
                  <div class="d-flex align-start gap-10">
                    <UserPhoto
                      :size="30"
                      photoSize="thumb"
                      :id="notif.sender"
                      rounded
                    />
                    <div>
                      <div class="message mb-1">
                        You've been mentioned by
                        <strong class="primary--text">{{
                          $store.getters["users/user"](notif.sender).fullName
                        }}</strong>
                        in a note from request #{{
                          $store.getters["request/request"](notif.origin)
                            .requestNumber
                        }}.
                      </div>
                      <div class="caption grey--text text--lighten-1">
                        {{ notif.created | fromNowComplete }}
                      </div>
                    </div>
                  </div>
                </template>
              </v-list-item-content>
            </v-list-item>

            <v-btn
              :style="{ position: 'absolute', right: '2px', bottom: '5px' }"
              v-if="notif.version === '2.0' && notif.viewed"
              @click="confirmDelete(notif)"
              color="error"
              icon small
            >
              <v-icon x-small>mdi-delete</v-icon>
            </v-btn>
          </v-card>

          <div class="text-center py-3">
            <v-btn @click="getNotifications()" :loading="getting" small>Load More</v-btn>
          </div>
        </v-card-text>

        <v-card-text v-else>
          <v-alert class="mb-0" type="info" dense>You don't have unopened notification.</v-alert>
        </v-card-text>
      </v-card>
    </v-sheet>

    <!-- CONFIRM DELETE -->
    <confirm-delete 
      :deleting="deleting"
      :show="deleteDialog" 
      @cancel="closeDeleteDialog()"
      message="Delete notification?"
      @confirmed="forceDelete(toDelete)"
   />
  </v-menu>
</template>

<script>
import db from '@/firebase/init'
import firebase from 'firebase'
import { orderBy, uniqBy } from 'lodash'

import MemberInvite from './MemberInvite.vue'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      toDelete: null,
      getting: false,
      deleting: false,
      notifications: [],
      lastVisible: null,
      deleteDialog: false,
      includeOpened: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    MemberInvite
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    '$route': function () {
      this.checkNotifications()
    },
    'notifications': function () {
      this.checkNotifications()
    },
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    refined: function () {
      let notifications = orderBy(this.notifications, (notification) => {
        return notification.created
      }, 'desc')

      notifications = uniqBy(notifications, (notification) => {
        return notification.id
      })

      return notifications
    },

    count: function () {
      var refined = this.refined.filter((notif) => {
        if (notif.version === '2.0') {
          return notif.viewed === false
        }
        else {
          return true
        }
      })

      return refined.length
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    /*------------------------------------------------------------------------------
     * GET NOTIFICATIONS
     *----------------------------------------------------------------------------*/
    getNotifications() {
      let user = firebase.auth().currentUser
      this.getting = true
      
      let query = db.collection('notifications')
        .doc(user.uid)
        .collection('messages')

        if (!this.includeOpened) {
          query = query.where('viewed', '==', false)
        }

        // order
        query = query.orderBy('created', 'desc')

        if (this.lastVisible) {
          query = query.startAfter(this.lastVisible)
        }
        
        query
          .limit(15)
          .get()
          .then(snapshot => {
            if (snapshot.size) {
              this.lastVisible = snapshot.docs[snapshot.size - 1]

              snapshot.forEach(doc => {
                let data = doc.data()
                data.id = doc.id
                data.ref = doc.ref

                if (!this.notifications.find(n => n.id === data.id)) {
                  this.notifications.push(data)
                  
                  if (data.sender) {
                    this.$store.dispatch('users/getUserUid', data.sender)
                  }
  
                  if (['request_message_mentioned', 'request_message'].includes(data.type)) {
                    this.$store.dispatch('request/getRequest', data.id)
                  }
                  
                  if (['order_message_mentioned', 'order_message', 'order_note'].includes(data.type)) {
                    this.$store.dispatch('orders/getOrder', data.origin)
                  }
                }

              })

              query.limit(1)
              .onSnapshot((snapshot) => {
                let change = snapshot.docChanges()[0]
                let data = change.doc.data()
                data.id = change.doc.id
                data.ref = change.doc.ref


                if (!this.notifications.find(doc => doc.id === data.id)) {
                  this.notifications.push(data)
                  
                  if (data.sender) {
                    this.$store.dispatch('users/getUserUid', data.sender)
                  }
    
                  if (['request_message_mentioned', 'request_message'].includes(data.type)) {
                    this.$store.dispatch('request/getRequest', { id: data.origin })
                  }
                  
                  if (['order_message_mentioned', 'order_message', 'order_note'].includes(data.type)) {
                    this.$store.dispatch('orders/getOrder', data.origin)
                  }
                }

              })

            }
          })
          .finally(() => {
            this.getting = false
          })
    },

    /*------------------------------------------------------------------------------
     * GET MEMBER INVITES
     *-----------------------------------------------------------------------------*/
    getMemberInvites() {
      let user = firebase.auth().currentUser

      db.collection('member_invites')
        .where('email', '==', user.email)
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              let data = change.doc.data()
              data.id = change.doc.id
              data.ref = change.doc.ref
              data.type = 'member_invite'

              if (!this.notifications.find(i => i.id == data.id)) {
                this.notifications.push(data)
                this.$store.dispatch('brand_profile/getProfile', data.profile)
              }
            }
            if (change.type === "removed") {
              let notification = this.notifications.find(n => n.id === change.doc.id)

              if (notification) {
                this.notifications.splice(this.notifications.indexOf(notification), 1)
              }
            }
          })
        })
    },

    /*------------------------------------------------------------------------------
     * REMOVE NOTIFICATIONS
     *----------------------------------------------------------------------------*/
    deleteNotification(notification) {
      // IF NOTIFICATION TYPE IS NEW
      if (notification.version === '2.0') {
        notification.ref
          .update({ viewed: true })
          .then(() => {
            notification.viewed = true
          })
          .catch(error => {
            console.log(error.message)
          })
      }
      // IF OLD NOTIFICATION TYPE
      else {
        notification.ref.delete()
          .then(() => {
            let notif = this.notifications.find(n => n.id === notification.id)

            if (notif) {
               this.notifications.splice(this.notifications.indexOf(notif), 1)
            }
          })
          .catch(error => {
            console.log(error.message)
          })
      }
    },

    /*------------------------------------------------------------------------------
     * FORCE DELETE
     *----------------------------------------------------------------------------*/
   forceDelete(notif) {
      this.deleting = true

      notif.ref.delete()
         .then(() => {
            this.$store.dispatch('showSuccess', 'Notification deleted')
            let notification = this.notifications.find(n => n.id === notif.id)

            if (notification) {
               this.notifications.splice(this.notifications.indexOf(notification), 1)
            }
         })
         .catch(error => {
            console.log(error.message)
            this.$store.dispatch('showError', error.message)
         })
         .finally(() => {
            this.closeDeleteDialog()
         })
   },

    /*------------------------------------------------------------------------------
     * DO ACTION
     *----------------------------------------------------------------------------*/
    doAction(notif) {
      switch (notif.type) {
        case 'collaborator_added':
          this.$router.push({ name: 'Project', params: { id: notif.origin } })
        break;
        case 'order_note':
        case 'order_message':
        case 'order_note_mentioned':
        case 'order_message_mentioned':
          this.$router.push({ name: 'OrderDetails', params: { id: notif.origin }, query: { view: 2 } })
        break;
        case 'request_note':
        case 'request_message':
        case 'request_note_mentioned':
        case 'request_message_mentioned':
          this.$router.push({ name: 'Request', params: { id: notif.origin }, query: { v: 2 } })
        break;
      }
    },

    /*------------------------------------------------------------------------------
     * CHECK NOTIFICATIONS
     *----------------------------------------------------------------------------*/
    checkNotifications() {
      if (['Project', 'OrderDetails', 'Request'].includes(this.$route.name)) {
        let id = this.$route.params.id

        if (this.notifications.length) {
          let notifications = this.notifications.filter(n => n.origin === id)

          if (this.notifications.length) {
            notifications.forEach(notif => {
              if (!notif.urlParams) {
                this.deleteNotification(notif)
              }
              else {
                notif.urlParams.forEach(param => {
                  let key = Object.keys(param)[0]
                  let value = Object.values(param)[0]

                  if (this.$route.query[key] && this.$route.query[key] == value) {
                    this.deleteNotification(notif)
                  }
                })
              }
            })
          }
        }
      }
    },

    /*------------------------------------------------------------------------------
     * REMOVE INVITE
     *----------------------------------------------------------------------------*/
    removeInvite(notif) {
      this.notifications.splice(this.notifications.indexOf(notif), 1)
    },

    /*------------------------------------------------------------------------------
     * CONFIRM DELETE?
     *----------------------------------------------------------------------------*/
    confirmDelete(notif) {
      this.toDelete = notif
      this.deleteDialog = true
    },

    /*------------------------------------------------------------------------------
     * CLOSE DELETE DIALOG
     *----------------------------------------------------------------------------*/
   closeDeleteDialog() {
      this.deleteDialog = false
      this.toDelete = null
      this.deleting = false
   },

   /**------------------------------------------------------------------------------
    * RESET ALL
    *----------------------------------------------------------------------------*/
   resetData() {
    this.notifications = []
    this.lastVisible = null
   }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.notifications = []
    this.getNotifications()
    this.getMemberInvites()
  }
}
</script>

<style>
.message {
  line-height: 1.4;
}
</style>
<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.content"
    no-click-animation
    persistent
  >
    <v-card class="py-5">
      <v-card-title class="justify-center pb-2">
        <h5 class="font-weight-bold text-h5 text-center" v-if="pages.length">{{ data.id ? 'Update Page' : 'Add New Page' }}</h5>
        <h5 class="font-weight-bold text-h5 text-center" v-if="!pages.length">Let’s build your first page!</h5>

        <close-btn v-if="pages.length" @click="closeDialog()" depressed />
      </v-card-title>

      <v-card-text>
        <div class="mb-5 body-1 grey--text text-center">
          What should we call it?
        </div>

        <v-alert v-if="status.error" type="error" border="left" transition="fade">
          {{ status.error }}
        </v-alert>

        <v-form ref="pageForm" @submit.prevent="validateForm()">
          <v-text-field
            v-model="data.name"
            label="Page Name"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <v-select
            v-if="pages.length && !data.home && !data.nonNavigation"
            v-model="data.parent"
            label="Select Parent Page"
            :items="filtered"
            item-text="name"
            item-value="id"
            clearable
            outlined
          ></v-select>

          <v-checkbox 
            v-model="data.nonNavigation" 
            label="Click here if you do not wish this page to be shown in the website navigation menu"
            class="mt-0 pt-0"
            dense
          ></v-checkbox>

          <v-row dense>
            <v-col>
              <v-btn
                @click="validateForm()"
                :loading="status.creating"
                color="accent"
                class="gradient"
                block
              >Build From Scratch</v-btn>
            </v-col>
            <v-col v-if="$store.getters['user/isEmployee'] || $store.getters['user/isAdmin']">
              <v-btn
                v-if="$store.getters['user/isEmployee'] || $store.getters['user/isAdmin']"
                @click="validateForm(true)"
                :loading="status.creating"
                color="primary"
                block
              >Select A Template</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import { mapState, mapActions } from 'vuex'
import _omit from 'lodash/omit'

export default {
  props: {
    show: Boolean
  },

  data() {
    return {
      rules,
    }
  },

  computed: {
    ...mapState({
      status: state => state.page.status,
      data: state => state.page.data,
      id: state => state.page.toUpdateID,
      pages: state => state.page.pages,
    }),

    filtered: function () {
      let pages = []

      if (this.pages.length) {
        this.pages.forEach(page => {
          if (page.id !== this.id) {
            pages.push(_omit(page, ['header']))
          }
        })
      }

      return pages
    },
  },

  methods: {
    ...mapActions('page', [
      'create',
      'update'
    ]),

    closeDialog() {
      this.$emit('close')
      this.$store.commit('page/setData', {})
      this.$refs.pageForm.resetValidation()
    },

    validateForm(template = false) {
      if (this.$refs.pageForm.validate()) {
        let task

        if (!this.data.id) task = Promise.all([this.create(this.$route.params.id)])
        else task = Promise.all([this.update(this.$route.params.id)])

        task.then(response => {
          let resp = response[0]

          if (!resp.hasError) {
            if (template) {
              this.$root.$emit('showSelectPageTemplateDialog')
            }
            
            this.$router.push({ query: { p: resp.pageId } })
            this.closeDialog()
          }
        })
      }
    }
  },
}

</script>

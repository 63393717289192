<template>
  <div>
    <div class="d-flex">
      <v-sheet class="pa-5 pb-0" color="transparent">
        <back
          v-if="$store.state.user.user && project && !backToAllProjects && !project.template"
          :route="project.folder == 'root' || $store.getters['collaborators/isCollaborator'] ? 'Projects': { name: 'Folder', params: { id: project.folder } }" 
          text="Back to projects" 
        />
        
        <back
          v-else-if="isPageTemplate"
          :route="{ name: 'PageTemplates' }" 
          text="Back to page templates" 
        />
        
        <back
          v-else-if="project && project.template"
          :route="{ name: 'ProjectTemplates' }" 
          text="Back to project templates" 
        />
        
        <back
          v-else-if="$store.state.user.user && project && backToAllProjects"
          route="AllProjects" 
          text="Back to all projects" 
        />
      </v-sheet>

      <v-spacer></v-spacer>

      <v-menu 
        v-if="projects.length && !isPageTemplate && false" 
        transition="slide-y-transition" 
        offset-y 
        left
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="info" class="ma-1" outlined icon x-small>
            <v-icon dark small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list width="280" dense>
          <v-list-item 
            v-for="proj in projects" 
            :key="proj.id"
            :to="{ name: 'Project', params: { id: proj.id } }"
            dense
          >
            <v-list-item-title>{{ proj.name }}</v-list-item-title>
            <v-list-item-icon class="justify-end">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <preloader v-if="status.getting" message="Please wait..." />
    
    <template v-else-if="project">
      <div class="pa-7 pt-2">
        <h5 class="font-weight-bold white--text subtitle-1 mb-2 mt-2">
          <span 
            @blur="getProjectName"
            contenteditable="true"
            class="project-name"
            spellcheck="false" 
            ref="projectName"
          >{{ project.name }}</span>

          <v-tooltip right>
            <template v-slot:activator="{ on }">    
              <button
                v-clipboard:copy="getRoute()"
                v-clipboard:success="copied"
                class="ml-2"
                v-on="on"
                icon
                small
              >
                <v-icon color="white" small>mdi-link-variant</v-icon>
              </button>
            </template>
            <span class="caption">Copy project URL</span>
          </v-tooltip>
        </h5>
        
        <div class="caption anchor--text primary--text text--lighten-2">
          Created {{ project.createdAt | formatDate }}
        </div>

        <div class="caption mb-2 anchor--text primary--text text--lighten-2">
          by <owner :id="project.owner" />
        </div>

        <v-tooltip v-if="$store.getters['user/isEmployee'] || $store.getters['user/isAdmin']" bottom>
          <template v-slot:activator="{ on }">
            <v-chip
              style="cursor: pointer"
              v-clipboard:copy="project.id"
              v-clipboard:success="copied"
              color="primary lighten-1"
              v-on="on"
              label
              small
            >
              <span class="font-weight-bold mr-2 text--disabled">
                Project ID:
              </span> {{ project.id }}
            </v-chip>
          </template>
          <span class="caption">Copy Project ID</span>
        </v-tooltip>
      </div>

      <v-expansion-panels
        class="project-panel"
        mandatory
        accordion
        disabled
        flat
        tile
        dark
      >
        <v-expansion-panel readonly>
          <v-expansion-panel-header 
            :color="$colors.panelHeader" 
            class="font-weight-bold" 
            hide-actions
          >Pages</v-expansion-panel-header>
          <v-expansion-panel-content :color="$colors.panelContent" class="py-5">
            <!-- <preloader v-if="pageStatus.getting" message="loading pages..." /> -->

            <v-alert
              v-if="!pageStatus.getting && !$store.getters['page/pageMenu'].length"
              class="mb-0"
              type="info"
              dense
            >
              No page found
            </v-alert>

            <children-menu
              v-if="selected"
              :selected="selected"
              :pages="$store.getters['page/pageMenu']"
            />
            
            <v-card v-if="$store.getters['page/pageMenuNonNav'].length" color="transparent" flat>
              <v-card-text class="body-lg font-weight-bold info--text ">
                --- Non-Navigation ---
              </v-card-text>

              <children-menu
                v-if="selected"
                :selected="selected"
                :pages="$store.getters['page/pageMenuNonNav']"
                parentOnly
              />
            </v-card>


            <v-alert 
              :value="!$store.state.user.user"
              color="primary lighten-2"
              type="info"
              class="mt-5"
              outlined
              dense
            >
              Sign up for a free account to create more pages.
            </v-alert>

            <v-btn
              v-if="$store.state.user.user"
              @click="showAddDialog()"
              color="accent gradient"
              class="mt-7 font-weight-dark justify-space-between"
              data-title="New Page"
              v-intro="'Click here to add a new page'"
              v-intro-step="4"
              block
              right
              dark
            >
              <span>Add A Page</span>
              <v-icon left size="20">mdi-plus</v-icon>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="false">
          <v-expansion-panel-header expand-icon="mdi-menu-down" :color="$colors.panelHeader" class="font-weight-bold" ripple>Collaborators</v-expansion-panel-header>
          <v-expansion-panel-content :color="$colors.panelContent" class="py-5">
            collabs
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <!-- ADD DIALOG -->
    <AddPageDialog :show="pageStatus.showAdd" @close="closeAddDialog()" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import AddPageDialog from '@/components/AddPageDialog'
import ChildrenMenu from '@/components/ChildrenMenu'

export default {
  data() {
    return {
      children: [],
      showAdd: false,
      ownerData: null,
    }
  },

  components: {
    AddPageDialog,
    ChildrenMenu,
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
      status : state => state.project.status,
      pageStatus: state => state.page.status,
      selected: state => state.page.selected,
      project : state => state.project.project,
      projects : state => state.projects.projects,
      isPageTemplate: state => state.page.isPageTemplate,
    }),

    backToAllProjects: function () {
      let backtoprojects = false
      
      if (this.user.role == 'admin' && this.user.userid !== this.project.owner) {
        backtoprojects = true
      }

      return backtoprojects
    },

    owner: function () {
      return this.ownerData
    }
  },

  watch: {
    '$route': function () {
      this.getPages(this.$route.params.id)
    },

    'pageStatus.getting': function (val) {
      if (!val && !this.$store.getters['page/pageMenu'].length && !this.isPageTemplate) {
        this.showAddDialog()
      }
    },
  },

  methods: {
    ...mapActions('page', [
      'getPages',
      'updatePageOrders'
    ]),
    
    ...mapActions('project', [
      'updateProjectName',
    ]),

    showAddDialog() {
      this.$store.commit('page/showAddState', true)
    },

    closeAddDialog() {
      this.$store.commit('page/showAddState', false)
    },

    sortChildren(children) {
      console.log(children)
    },

    copied() {
      this.$store.dispatch('showSuccess', 'Copied to clipboard.')
    },

    getRoute() {
      let base = window.location.origin
      let path = this.$router.currentRoute.fullPath
      let fullpath = `${base}${path}`
      
      return fullpath
    },

    getProjectName(e) {
      if (e.target.innerHTML !== this.project.name)
        this.updateProjectName(e.target.innerHTML)
    }
  },

  mounted() {
    if (!this.$store.state.projects.status.firstLoad) this.$store.dispatch('projects/getProjects')
    if (this.$route.query.p) this.$store.commit('page/initialState', this.$route.query.p)
    this.getPages(this.$route.params.id)
  }
}

</script>

<style lang="scss">

.children {
  padding-left: 20px;
}

.project-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

</style>

import db from '@/firebase/init'
import firebase from 'firebase'
import { omit, isUndefined } from 'lodash'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  profiles: [],
  companies: [],
  company_profiles: [],
  status: {
    saving: false,
    getting: false,
    creating: false,
    deleting: false,
    updating: false,
    inviting: false,
    firstLoad: false,
    uploading: false,
    lastVisible: null,
    addingColor: false,
    companiesFirstLoad: false,
    gettingAllProfiles: false,
    firstLoadAllProfiles: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *-----------------------------------------------------------------------------*/
const getters = {
  profile: (state) => (id) => {
    if (state.profiles) {
      let profile = state.profiles.find(p => p.id == id)

      if (profile) {
        if (!profile.locations) Vue.set(profile, 'locations', [{ location: '' }])
        if (!profile.websites) Vue.set(profile, 'websites', [{ url: '' }])
        if (!profile.links) Vue.set(profile, 'links', [{ url: '' }])
        if (!profile.socials) Vue.set(profile, 'socials', [{ url: '' }])
      }
      return profile || {}
    }
  },

  haveAccess: (state, getters, rootState) => (profile) => {
    if (rootState.user.user && profile) {
      let user = rootState.user.user
      return user.role == 'admin' || user.userid == profile.user || user.organization
    }
    else {
      return false
    }
  },

  isOwner: (state, getters, rootState) => (userid) => {
    let user = rootState.user.user

    if (user && user.userid == userid) return true
    else return false
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  resetData(state) {
    state.data = {}
  },

  setData(state, payload) {
    Object.assign(state.data, payload)
  },

  addProfile(state, payload) {
    let profile = state.profiles.find(p => p.id == payload.id)
    if (!profile) state.profiles.push(payload)
    else Vue.set(state.profiles, state.profiles.indexOf(profile), payload)
  },

  setProfiles(state, payload) {
    state.profiles = []

    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        if (!state.profiles.find(p => p.id == data.id))
          state.profiles.push(data)
      })
    }

    state.status.getting = false
  },

  removeProfile(state, payload) {
    let profile = state.profiles.find(p => p.id == payload.id)
    state.profiles.splice(state.profiles.indexOf(profile), 1)
  },

  addLocation(state, payload) {
    payload.locations.push({ location: '' })
  },

  addLink(state, payload) {
    payload.links.push({ url: '' })
  },

  addSocialLink(state, payload) {
    payload.socials.push({ url: '' })
  },

  addUrl(state, payload) {
    payload.websites.push({ url: '' })
  },

  removeLocation(state, payload) {
    payload.profile.locations.splice(payload.index, 1)
  },

  removeLink(state, payload) {
    payload.profile.links.splice(payload.index, 1)
  },

  removeSocialLink(state, payload) {
    payload.profile.socials.splice(payload.index, 1)
  },

  removeUrl(state, payload) {
    payload.profile.websites.splice(payload.index, 1)
  },

  removeColor(state, payload) {
    let color = payload.profile.brand_colors.find(c => c == payload.color)
    payload.profile.brand_colors.splice(payload.profile.brand_colors.indexOf(color), 1)
  },

  updateProfileData(state, payload) {
    Vue.set(payload.profile, Object.keys(payload)[1], Object.values(payload)[1])
  },

  updateFields(state, payload) {
    Object.keys(payload.fields).forEach(key => {
      Vue.set(payload.profile, key, payload.fields[key])
    })
  },

  setCompanies(state, payload) {
    payload.forEach(doc => {
      let company = doc.data()
      company.id = doc.id
      company.ref = doc.ref

      if (!state.companies.find(c => c.id == company.id)) {
        state.companies.push(company)
      }
    })
  },

  setAllProfiles(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        let profile = doc.data()
        profile.id = doc.id
        profile.ref = doc.ref

        if (!state.company_profiles.find(p => p.id === profile.id)) {
          state.company_profiles.push(profile)
        }
      })
    }
  },

  clearProfiles(state) {
    state.profiles = []
    state.companies = []
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE PROFILE
   *----------------------------------------------------------------------------*/
  async createProfile({ state, commit, dispatch }) {
    commit('updateStatus', { creating: true })
    let user = firebase.auth().currentUser
    let hasError = false
    let data = {}
    let docRef

    Object.assign(data, state.data)

    if (data.id) {
      data.updated = firebase.firestore.Timestamp.now()
    }
    else {
      data.user = user.uid
      data.created = firebase.firestore.Timestamp.now()
    }

    if (data.id) docRef = db.collection('brand_profiles').doc(data.id)
    else docRef = db.collection('brand_profiles').doc()

    await docRef
      .set(omit(data, ['id', 'ref', isUndefined]), { merge: true })
      .then(() => {
        data.ref = docRef
        data.id = docRef.id
        commit('addProfile', data)
        commit('updateStatus', { creating: false })
        dispatch('showSuccess', `Profile ${state.data.id ? 'updated' : 'created'}`, { root: true })
      })
      .catch(error => {
        hasError = true
        console.log(error.message)
        commit('updateStatus', { creating: false })
        dispatch('showError', error.message, { root: true })
      })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * GET COMPANY PROFILES
   *----------------------------------------------------------------------------*/
  getBrandProfiles({ commit, dispatch }, userid = null) {
    commit('updateStatus', { getting: true })
    commit('updateStatus', { firstLoad: true })
    let user = firebase.auth().currentUser

    db.collection('brand_profiles')
      .where('user', '==', userid || user.uid)
      .get()
      .then(snapshot => {
        commit('setProfiles', snapshot)
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { getting: false })
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * DELETE BRAND PROFILE
   *----------------------------------------------------------------------------*/
  async deleteBrandProfile({ commit, dispatch }, profile) {
    commit('updateStatus', { deleting: true })

    await profile.ref.delete()
      .then(() => {
        commit('removeProfile', profile)
        commit('updateStatus', { deleting: false })
        dispatch('showSuccess', 'Brand profile deleted', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { deleting: false })
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET PROFILE
   *----------------------------------------------------------------------------*/
  async getProfile({ state, commit, dispatch }, id) {
    if (!state.profiles.find(p => p.id == id)) {
      commit('updateStatus', { getting: true })

      db.collection('brand_profiles')
        .doc(id).get()
        .then(doc => {
          if (doc.exists) {
            let profile = doc.data()
            profile.id = doc.id
            profile.ref = doc.ref
            commit('addProfile', profile)

            if (profile.members && profile.members.length) {
              profile.members.forEach(member => {
                dispatch('users/getUserUid', member, { root: true })
              })
            }
          }

          commit('updateStatus', { getting: false })
        })
        .catch(error => {
          console.log(error.message)
          commit('updateStatus', { getting: false })
        })
    }

  },

  /*------------------------------------------------------------------------------
   * ADD COLOR
   *----------------------------------------------------------------------------*/
  async addBrandColor({ commit, dispatch }, data) {
    console.log(data)

    commit('updateStatus', { addingColor: true })
    let colors = data.profile.brand_colors || []
    if (!data.editing) colors.push(data.data)

    await data.profile.ref
      .update({ brand_colors: colors })
      .then(() => {
        commit('updateStatus', { addingColor: false })
        dispatch('showSuccess', 'Brand color added', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { addingColor: false })
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * DELETE BRAND COLOR
   *----------------------------------------------------------------------------*/
  deleteColor({ dispatch }, data) {
    let colors = JSON.parse(JSON.stringify(data.profile.brand_colors))
    let color = colors.find(c => c == data.color)
    colors.splice(colors.indexOf(color), 1)

    data.profile.ref
      .update({ brand_colors: colors })
      .then(() => {
        data.profile.brand_colors = colors
        dispatch('showSuccess', 'Brand color deleted', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })

  },

  /*------------------------------------------------------------------------------
   * UPLOAD LOGO
   *----------------------------------------------------------------------------*/
  async uploadLogo({ commit, dispatch }, data) {
    commit('updateStatus', { uploading: true })
    let profile = data.profile

    let fileData = {
      file: data.file,
      folder: 'brand_profiles'
    }

    let newFile = await dispatch('file_upload/uploadFile', fileData, { root: true })
    commit('updateStatus', { uploading: false })

    let logo = {
      file: newFile.file,
      folder: 'brand_profiles',
      size: data.file.size,
      date: firebase.firestore.Timestamp.now()
    }

    await profile.ref
      .update({ logo })
      .then(() => {
        dispatch('showSuccess', 'Logo uploaded', { root: true })
        commit('updateProfileData', { profile, logo })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD BRAND GUIDE
   *----------------------------------------------------------------------------*/
  async uploadBrandGuide({ commit, dispatch }, data) {
    commit('updateStatus', { uploading: true })
    let profile = data.profile

    let fileData = {
      file: data.file,
      folder: 'brand_profiles'
    }

    let newFile = await dispatch('file_upload/uploadFile', fileData, { root: true })
    commit('updateStatus', { uploading: false })

    let brand_guide = {
      file: newFile.file,
      folder: 'brand_profiles',
      size: data.file.size,
      date: firebase.firestore.Timestamp.now()
    }

    await profile.ref
      .update({ brand_guide })
      .then(() => {
        dispatch('showSuccess', 'Brand guide uploaded', { root: true })
        commit('updateProfileData', { profile, brand_guide })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD FONTS
   *----------------------------------------------------------------------------*/
  async uploadFonts({ commit, dispatch }, data) {
    commit('updateStatus', { uploading: true })
    let profile = data.profile
    let fonts = profile.fonts || []
    let files = data.files

    for (var file of files) {
      let fileData = {
        file,
        folder: 'brand_profiles'
      }

      let newFile = await dispatch('file_upload/uploadFile', fileData, { root: true })

      let font = {
        file: newFile.file,
        folder: 'brand_profiles',
        size: file.size,
        date: firebase.firestore.Timestamp.now()
      }

      fonts.push(font)
    }

    commit('updateStatus', { uploading: false })

    await profile.ref
      .update({ fonts })
      .then(() => {
        dispatch('showSuccess', 'Brand guide uploaded', { root: true })
        commit('updateProfileData', { profile, fonts })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD PHOTOS AND VIDEOS
   *----------------------------------------------------------------------------*/
  async uploadMedias({ commit, dispatch }, data) {
    commit('updateStatus', { uploading: true })
    let profile = data.profile
    let photos_videos = profile.photos_videos || []
    let files = data.files

    for (var file of files) {
      let fileData = {
        file,
        folder: 'brand_profiles'
      }

      let newFile = await dispatch('file_upload/uploadFile', fileData, { root: true })

      let media = {
        file: newFile.file,
        folder: 'brand_profiles',
        size: file.size,
        type: file.type,
        date: firebase.firestore.Timestamp.now()
      }

      photos_videos.push(media)
    }

    commit('updateStatus', { uploading: false })

    await profile.ref
      .update({ photos_videos })
      .then(() => {
        dispatch('showSuccess', 'Brand guide uploaded', { root: true })
        commit('updateProfileData', { profile, photos_videos })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD EXTRA ASSETS
   *----------------------------------------------------------------------------*/
  async uploadAssets({ commit, dispatch }, data) {
    commit('updateStatus', { uploading: true })
    let profile = data.profile
    let extra_assets = profile.extra_assets || []
    let files = data.files

    for (var file of files) {
      let fileData = {
        file,
        folder: 'brand_profiles'
      }

      let newFile = await dispatch('file_upload/uploadFile', fileData, { root: true })

      let media = {
        file: newFile.file,
        folder: 'brand_profiles',
        size: file.size,
        type: file.type,
        date: firebase.firestore.Timestamp.now()
      }

      extra_assets.push(media)
    }

    commit('updateStatus', { uploading: false })

    await profile.ref
      .update({ extra_assets })
      .then(() => {
        dispatch('showSuccess', 'Brand guide uploaded', { root: true })
        commit('updateProfileData', { profile, extra_assets })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * SAVE LINKS
   *----------------------------------------------------------------------------*/
  saveLinks({ dispatch }, profile) {
    let links = profile.links

    profile.ref
      .update({ links })
      .then(() => {
        dispatch('showSuccess', 'Links updated', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * SAVE SOCIAL MEDIA LINKS
   *----------------------------------------------------------------------------*/
  saveSocialLinks({ dispatch }, profile) {
    let socials = profile.socials

    profile.ref
      .update({ socials })
      .then(() => {
        dispatch('showSuccess', 'Social media links updated', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * SAVE LOGIN DETAILS
   *----------------------------------------------------------------------------*/
  async saveLogin({ commit, dispatch }, loginData) {
    return new Promise((resolve, reject) => {
      commit('updateStatus', { saving: true })
      let profile = loginData.profile
      let data = loginData.data
      data.created = firebase.firestore.Timestamp.now()

      let docRef = profile.ref.collection('logins')
      docRef = data.id ? docRef.doc(data.id) : docRef.doc()

      docRef.set(omit(data, ['password', 'id', 'ref']))
        .then(() => {
          data.ref = docRef
          data.id = docRef.id

          db.collection('passwords')
            .doc(data.id)
            .set({ password: data.password })
            .then(() => {
              commit('updateStatus', { saving: false })
              dispatch('showSuccess', 'Login saved', { root: true })
              resolve(omit(data, ['password']))
            })
        })
        .catch(error => {
          console.log(error.message)
          commit('updateStatus', { saving: false })
          dispatch('showError', error.message, { root: true })
          reject(error.message)
        })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE LOGIN
   *----------------------------------------------------------------------------*/
  async deleteLogin({ commit, dispatch }, login) {
    commit('updateStatus', { deleting: true })

    await Promise.all([
      login.ref.delete(),
      db.collection('passwords').doc(login.id).delete()
    ])
      .then(() => {
        commit('updateStatus', { deleting: false })
        dispatch('showSuccess', 'Login detail deleted', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { deleting: false })
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * UPDATE REQUEST FIELD
   * 
   * @params
   *  Object
   *    request: Object
   *    fields: String
   *    message: String (optional)
   *    silent: Boolean (optional)
   *----------------------------------------------------------------------------*/
  async updateBrandFields({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })

    let newData = {
      ...data.fields,
      ...{ updated: firebase.firestore.Timestamp.now() }
    }

    await data.profile.ref
      .update(newData)
      .then(() => {
        commit('updateFields', data)
        if (!data.silent) dispatch('showSuccess', data.message || 'Company profile updated', { root: true })
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
      .finally(() => {
        commit('updateStatus', { updating: false })
      })
  },

  /*------------------------------------------------------------------------------
   * GET MY COMPANIES
   *----------------------------------------------------------------------------*/
  async getMyCompanies({ commit }, id = null) {
    let user = firebase.auth().currentUser
    commit('updateStatus', { companiesFirstLoad: true })

    await db.collection('brand_profiles')
      .where('members', 'array-contains', id || user.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          commit('setCompanies', snapshot)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * GET ALL PROFILES
   *----------------------------------------------------------------------------*/
  async getAllProfiles({ commit }) {
    commit('updateStatus', { gettingAllProfiles: true })
    commit('updateStatus', { firstLoadAllProfiles: true })

    await db.collection('brand_profiles')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('setAllProfiles', snapshot)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
      .finally(() => {
        commit('updateStatus', { gettingAllProfiles: false })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
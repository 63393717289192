import Vue from 'vue'
import moment from 'moment'
import { capitalize, kebabCase, startCase } from 'lodash'

Vue.filter('sliceText', text => {
  let txt = text.split("")
  return `${txt[0]}.`
})

Vue.filter('formatDate', date => {
  return moment((typeof date == 'object') ? date.toDate() : date).format('MMM D, YYYY')
})

Vue.filter('messageDate', date => {
  return moment((typeof date == 'object') ? date.toDate() : date).format('MMM D, YYYY kk:mm')
})

Vue.filter('fromNow', date => {
  return moment((typeof date == 'object') ? date.toDate() : date).fromNow(true)
})

Vue.filter('fromNowComplete', date => {
  return moment((typeof date == 'object') ? date.toDate() : date).fromNow()
})

Vue.filter('capitalize', text => {
  return capitalize(text)
})

Vue.filter('kebab', text => {
  return kebabCase(text)
})

Vue.filter('startCase', text => {
  return startCase(text)
})

Vue.filter('numberFormat', number => {
  return new Intl.NumberFormat().format(number)
})

Vue.filter('fileSize', size => {
  let convert = size / 1000
  let type = 'KB'
  
  if (convert > 1000) {
    convert = convert / 1000
    type = 'MB'

    if (convert > 1000) {
      convert = convert / 1000
      type = 'GB'
    }
  }

  convert = new Intl.NumberFormat().format(convert)

  return `${Math.round(convert).toFixed(0)} ${type}`
})

Vue.filter('linkify', text => {
  var urlRegex = /(https?:\/\/[^\s]+)/g
  
  return text.replace(urlRegex, function(url) {
      return `<span class="d-inline"><a href="${url}" target="_blank" class="text-decoration-none">${url}</a></span>`
  })
})

Vue.filter('fileName', text => {
  let filename = text.split('_')
  filename.splice(0,1)
  return filename.join('')
})

Vue.filter('amountNoneDecimal', value => {
  let amount = Math.round(parseFloat(value/100))
  amount = new Intl.NumberFormat().format(amount)
  return amount
})
import algoliasearch from "algoliasearch"

const algolia = algoliasearch(
  process.env.VUE_APP_ALGOLIA_APP_ID,
  process.env.VUE_APP_ALGOLIA_API_KEY
)

const userIndex = algolia.initIndex('Users')
const projectIndex = algolia.initIndex('Projects')

export {
  userIndex,
  projectIndex
}

export default userIndex
import Vue from 'vue'
import db from '@/firebase/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  notes: [],
  users: [],
  status: {
    getting: false,
    creating: false,
    deleting: false,
    original: null,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setNotes(state, payload) {
    state.notes = []
    
    payload.forEach(doc => {
      let data = Vue.prototype.$formatData(doc)
      state.notes.push(data)
    })
    
    state.status.getting = false
  },

  clearNotes(state)  {
    state.notes = []
  },

  creatingState(state, bol) {
    state.status.creating = bol
  },

  insertNote(state, payload) {
    if (!state.notes.find(note => note.id == payload.id)) {
      state.notes.push(payload)
    }
  },

  insertUser(state, payload) {
    if (!state.users.find(user => user.id == payload.id)) {
      state.users.push(payload)
    }
  },

  setData(state, data) {
    state.data = data
    state.status.original = Object.assign({}, data)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeNote(state, note) {
    state.notes.splice(state.notes.indexOf(note), 1)
  },

  updateNote(state, payload) {
    let note = state.notes.find(n => n.id == payload.id)
    Vue.set(state.notes, state.notes.indexOf(note), payload)
    state.data = {}
  },

  cancelEdit(state) {
    Vue.set(state.notes, state.notes.indexOf(state.data), state.status.original)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET NOTES
   *----------------------------------------------------------------------------*/
  getNotes({ commit, dispatch }, section) {
    commit('gettingState', true)
    commit('clearNotes')

    section.ref
    .collection('notes')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        snapshot.forEach(doc => {
          let note = Vue.prototype.$formatData(doc)
          dispatch('getUserData', note.user)
          commit('insertNote', note)
        })

        commit('gettingState', false)
      }
      else {
        commit('gettingState', false)
      }
    })
    .catch(error => {
      commit('gettingState', false)
      console.log('notes.js', error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * ADD NOTE
   *----------------------------------------------------------------------------*/
  async createNote({ state, commit, dispatch }, section) {
    commit('creatingState', true)
    let user = firebase.auth().currentUser
    
    let data = state.data
    data.user = user.uid
    data.created = Date.now()

    await section.ref.collection('notes')
    .add(data)
    .then((docRef) => {
      data.ref = docRef
      data.id = docRef.id
      commit('insertNote', data)
      commit('creatingState', false)
      dispatch('showSuccess', 'Note successfully added.', { root: true })
      dispatch('getUserData', data.user)
      commit('setData', {})
    })
    .catch(error => {
      console.log('notes.js', error.message)
      dispatch('showError', error.message, { root: true })
      commit('creatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * ADD NOTE
   *----------------------------------------------------------------------------*/
  async updateNote({ state, commit, dispatch }) {
    commit('creatingState', true)
    let data = state.data
    data.edited = Date.now()

    await state.data.ref
    .update(data)
    .then(() => {
      commit('updateNote', data)
      commit('creatingState', false)
      dispatch('showSuccess', 'Note successfully updated.', { root: true })
    })
    .catch(error => {
      console.log('notes.js', error.message)
      dispatch('showError', error.message, { root: true })
      commit('creatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET USER DATA
   *----------------------------------------------------------------------------*/
  getUserData({ state, commit, dispatch }, id) {
    if (!state.users.find(user => user.userid == id)) {
      db.collection('users')
      .where('userid', '==', id)
      .limit(1)
      .get()
      .then(snapshot => {
        if(snapshot.size) {
          let doc = snapshot.docs[0]
          let data = Vue.prototype.$formatData(doc)
          commit('insertUser', data)
        }
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        console.log('notes.js', error.message)
      })
    }
    
  },

  /*------------------------------------------------------------------------------
   * DELETE NOTE
   *----------------------------------------------------------------------------*/
  async deleteNote({ commit, dispatch }, note) {
    commit('deletingState', true)
    
    await note.ref.delete()
    .then(() => {
      commit('deletingState', false)
      commit('removeNote', note)
    })
    .catch(error => {
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
      console.log('notes.js', error.message)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

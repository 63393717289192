import firebase from 'firebase'
import Vue from 'vue'

var storageRef = firebase.storage().ref()

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  files: [],
  progress: [],
  status: {
    uploading: null,
    getting: false,
    deleting: false
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  uploadingState(state, bol) {
    state.status.uploading = bol
  },

  setProgress(state, payload) {
    let file = state.progress.find(f => f.name == payload.name)

    if (file) Vue.set(state.progress, state.progress.indexOf(file), payload)
    else state.progress.push(payload)
  },

  removeProgress(state, name) {
    let file = state.progress.find(f => f.name == name)
    if (file) {
      state.progress.splice(state.progress.indexOf(file), 1)
      state.data.file.splice(state.progress.indexOf(file), 1)
    }


    if (!state.progress.length) {
      state.data = {}
      state.status.uploading = false
    }
  },

  clearProgress(state) {
    state.progress = []
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  setFiles(state, payload) {
    state.files = []
    
    payload.forEach(doc => {
      let file = Vue.prototype.$formatData(doc)
      state.files.push(file)
    })

    state.status.getting = false
  },

  insertFile(state, payload) {
    if (!state.files.find(f => f.id == payload.id))
      state.files.push(payload)
  },

  clearFiles(state) {
    state.files = []
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeFile(state, file) {
    state.files.splice(state.files.indexOf(file), 1)
  }
}

/*------------------------------------------------------------------------------
 * METHODS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * UPLOAD ATTACHMENT
   *----------------------------------------------------------------------------*/
  async uploadAttachments({ state, commit, dispatch, rootState }) {
    commit('uploadingState', true)
    let section = rootState.sections.selected

    await state.data.file.forEach(async (file) => {
      let name = `${Date.now()}_${section.id}_${file.name}`

      var metadata = {
        contentType: file.type
      }

      var uploadTask  = storageRef.child(`attachments/${name}`).put(file, metadata)

      await uploadTask.on('state_changed', snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        commit('setProgress', {progress, name: file.name, size: file.size})
      }, error => {
        dispatch('showError', error.message, { root: true })
        console.log('attachmentsJS', error.message)
      }, () => {

        setTimeout(() => {
          commit('removeProgress', file.name)
          
          let data = {
            name,
            originalName: file.name,
            size: file.size,
            created: Date.now()
          }
  
          section.ref.collection('attachments')
          .add(data)
          .then((docRef) => {
            data.ref = docRef
            data.id = docRef.id
            commit('insertFile', data)
          })
          .catch(error => {
            dispatch('showError', error.message, { root: true })
            console.log('attachmentsJS', error.message)
          })
        }, 3000)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * GET ATTACHMENTS
   *----------------------------------------------------------------------------*/
  getAttachments({ commit, dispatch }, section) {
    commit('gettingState', true)

    section.ref.collection('attachments')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setFiles', snapshot)
      }
      else {
        commit('gettingState', false)
        commit('clearFiles')
      }
    })
    .catch(error => {
      console.log('attachments.js', error.message)
      dispatch('showError', error.message, { root: true })
      commit('gettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE FILE
   *----------------------------------------------------------------------------*/
  async deleteAttachment({ commit, dispatch }, attachment) {
    commit('deletingState', true)

    await storageRef.child(`attachments/${attachment.name}`)
    .delete()
    .then(async () => {
      storageRef.child(`attachments/thumb_${attachment.name}`)
      .delete().catch(error => {
        console.log(error.message)
      })

      storageRef.child(`attachments/medium_${attachment.name}`)
      .delete().catch(error => {
        console.log(error.message)
      })
      
      storageRef.child(`attachments/large_${attachment.name}`)
      .delete().catch(error => {
        console.log(error.message)
      })

      await attachment.ref.delete()
      .then(() => {
        commit('removeFile', attachment)
        commit('deletingState', false)
        dispatch('showSuccess', 'Attachment successfully deleted.', { root: true })
      })
    })
    .catch(error => {
      console.log('attachments.js', error.message)
      dispatch('showError', error.message, { root: true })
      commit('deletingState', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


import firebase from 'firebase'
var storageRef = firebase.storage().ref()
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  uploads: [],
  status: {
    uploading: false,
    deleting: false,
    progress: 0,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  addUpload(state, payload) {
    let upload = state.uploads.find(u => u.filename == payload.filename)
    if (!upload) state.uploads.push(payload)
    else Vue.set(state.uploads, state.uploads.indexOf(upload), payload)

    if (payload.progress == 100) {
      setTimeout(() => {
        let upload = state.uploads.find(u => u.filename == payload.filename)
        state.uploads.splice(state.uploads.indexOf(upload), 1)
      }, 3000)
    }
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  /*------------------------------------------------------------------------------
   * UPLOAD FILE
   * @params
   *  Object
   *    file: Object
   *    folder: String
   *----------------------------------------------------------------------------*/
  async uploadFile({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      commit('updateStatus', { uploading: true })
      let file = data.file
      let folder = data.folder
      let filename = `${Date.now()}_${file.name}`
  
      var metadata = {
        contentType: file.type
      }
  
      var uploadTask = storageRef.child(`${folder}/${filename}`).put(file, metadata)
  
      uploadTask.on('state_changed',
        (snapshot) => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          commit('addUpload', { filename, progress })
        }, 
        (error) => {
          reject(error.message)
          dispatch('showError', error.message, { root: true })
        }, 
        () => {
          resolve({
            file: filename,
            type: file.type,
            size: file.size,
            created: firebase.firestore.Timestamp.now()
          })
          
          setTimeout(() => {
            commit('updateStatus', { progress: 0 })
          }, 3000)
        }
      )
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE FILE
   * @params
   * - folder_name
   * - file_name
   *----------------------------------------------------------------------------*/
  async deleteFile({ commit, dispatch }, data) {
    commit('updateStatus', { deleting: true })
    let folder = data.folder
    let file = data.file
    let fileRef = storageRef.child(`${folder}/${file}`)
    
    await fileRef.delete()
    .then(() => {
      commit('updateStatus', { deleting: false })
      dispatch('showSuccess', 'File deleted', { root: true })

      storageRef.child(`${folder}/large_${file}`).delete()
      storageRef.child(`${folder}/medium_${file}`).delete()
      storageRef.child(`${folder}/thumb_${file}`).delete()
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deleting: false })
      dispatch('showError', error.message, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
import db from '@/firebase/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  status: {
    sending: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  sendingState(state, bol) {
    state.status.sending = bol
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * SEND NOTIFICATION
   *----------------------------------------------------------------------------*/
  sendNotification({ commit, dispatch }) {
    commit('sendingState', true)

    let message = {
      to: 'rollygalon@gmail.com',
      template: {
        name: "notification",
        data: {
          subject: 'Testing only',
          message: `
            Hi,<br><br>
            You are invited to create an account on Vivacity App.<br>
            <a href="${window.location.origin}/signup?email=larynill@sitesatscale.com" target="_blank">Click Here To Signup</a>
            <br><br>
            All The Best,<br>
            Vivacity Team
          `,
        },
      },
    }

    db.collection('mail')
      .add(message)
      .then(() => {
        commit('sendingState', false)
        dispatch('showSuccess', 'Message sent', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * CREATE NOTIFICATION
   *
   * @PARAMS
   * $recipient - uid of the user to be notified
   * $type - type of notification e.g. collaborator_added
   * $origin - document id of the source
   * $urlParams - additional url parameters
   * 
   * @TYPES
   * collaborator_added
   * order_message
   *----------------------------------------------------------------------------*/
  async createNotification({ dispatch }, data) {
    data.created = firebase.firestore.Timestamp.now()

    if (data.recipient) {
      await db.collection('notifications')
        .doc(data.recipient)
        .collection('messages')
        .add(data)
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
        })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
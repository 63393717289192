import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  profiles: [],
  blocks: [],
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  getProfileUrl: (state) => (data) => {
    if (state.profiles) {
      let profile = state.profiles.find(p => p.id == data.id)
      return profile ? profile[data.size] : null
    }
    else return null
  },

  getBlockSvg: (state) => (file) => {
    if (state.blocks) {
      let block = state.blocks.find(b => b.file == file)
      return block ? block.svg : null
    }
    else return null
  },
  
  getBlockUrl: (state) => (file) => {
    if (state.blocks) {
      let block = state.blocks.find(b => b.file == file)
      return block ? block.url : null
    }
    else return null
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  /*------------------------------------------------------------------------------
   * ADD PROFILE URL
   *
   * @PARAMS
   * $id
   * $size
   * $url 
   *----------------------------------------------------------------------------*/
  addProfileUrl(state, payload) {
    let profile = state.profiles.find(p => p.id == payload.id)
    
    if (profile) {
      let data = { id: payload.id }
      data[payload.size] = payload.url
      Vue.set(state.profiles, state.profiles.indexOf(profile), data)
    }
    else {
      let data = { id: payload.id }
      data[payload.size] = payload.url
      state.profiles.push(data)
    }
  },

  /*------------------------------------------------------------------------------
   * ADD BLOCK DATA
   *
   * @PARAMS
   * $file
   * $svg
   *----------------------------------------------------------------------------*/
  addBlock(state, payload) {
    let block = state.blocks.find(b => b.file == payload.file)
    if (!block) state.blocks.push(payload)
    else Vue.set(state.blocks, state.blocks.indexOf(block), payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
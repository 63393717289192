<template>
  <draggable v-model="menus" @end="orderPages(menus)">
    <div v-for="menu in menus" :key="menu.id">
      <v-list-item
        :input-value="selected.id == menu.id"
        :to="{ name: 'Project', params: { id: $route.params.id }, query: { p: menu.id } }"
        color="#2296eb"
        selectable
        exact
        dense
      >
        <v-list-item-title>
          <v-icon small left :color="selected.id == menu.id ? '#2296eb' : 'white'">mdi-{{ menu.icon }}</v-icon>
          <span>{{ menu.name }}</span>
        </v-list-item-title>
      </v-list-item>
      <children-menu
        v-if="menu.children.length && !parentOnly"
        :pages="menu.children"
        :selected="selected"
        class="children"
      />
      </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import _uniq from 'lodash/uniq'
import ChildrenMenu from '@/components/ChildrenMenu'

export default {
  name: 'ChildrenMenu',

  props: {
    selected: Object,
    pages: Array,
    parentOnly: Boolean,
  },

  components: {
    draggable,
    ChildrenMenu
  },

  computed: {
    menus: {
      get: function () {
        return _uniq(this.pages)
      },

      set: function (newValue) {
        this.$store.commit('page/reorderPages', newValue)
      }
    },
  },

  watch: {
    '$route': function () {
      this.selectPage()
    }
  },

  methods: {
    orderPages(val) {
      this.$store.dispatch('page/updatePageOrders', val)
    },

    selectPage() {
      // let menu = this.pages.find(m => m.id == this.$route.query.p)
      this.$store.commit('page/setSelected', this.$route.query.p)
    }
  },
}

</script>

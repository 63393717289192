import db from '@/firebase/init'
import _omit from 'lodash/omit'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  fields: [],
  product: null,
  status: {
    getting: false,
    adding: false,
    updating: false,
    deleting: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  addFields(state, payload) {
    state.fields = []

    payload.forEach(doc => {
      let data = doc.data()
      if (data.type == 'checkbox') data.value = []
      data.id = doc.id
      data.ref = doc.ref
      state.fields.push(data)
    })

    state.status.getting = false
  },

  addingState(state, bol) {
    state.status.adding = bol
  },

  setProduct(state, payload) {
    state.product = payload
  },

  insertField(state, payload) {
    if (payload.type == 'checkbox') payload.value = []
    state.fields.push(payload)
  },

  updatingState(state, bol) {
    state.status.updating = bol
  },

  clearFields(state) {
    state.fields = []
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeField(state, payload) {
    state.fields.splice(state.fields.indexOf(payload), 1)
  },

  updateFields(state, payload) {
    state.fields = payload

    state.fields.forEach((field, i) => {
      let data = field
      data.order = i
      Vue.set(state.fields, i, data)
    })
  },

}

/*------------------------------------------------------------------------------
 * METHODS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET PRODUCT FIELDS
   *----------------------------------------------------------------------------*/
  getFields({ commit, dispatch }, data) {
    commit('gettingState', true)

    db.collection('types')
      .doc(data.type)
      .collection('products')
      .doc(data.product)
      .collection('fields')
      .orderBy('order', 'asc')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('addFields', snapshot)
        }
        else {
          commit('clearFields')
          commit('gettingState', false)
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('gettingState', false)
      })
  },

  /*------------------------------------------------------------------------------
   * ADD FIELD
   *----------------------------------------------------------------------------*/
  addField({ state, commit, dispatch }, type) {
    commit('addingState', true)

    let data = {
      type,
      order: state.fields.length,
      label: null,
      description: null,
      options: null,
      required: true,
      conditionalLogic: null,
      conditionalArray: {
        field: null,
        logic: null,
        value: null,
      },
      created: Date.now()
    }

    state.product.ref.collection('fields')
      .add(data)
      .then((docRef) => {
        data.ref = docRef
        data.id = docRef.id
        commit('insertField', data)
        commit('addingState', false)
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('addingState', false)
      })
  },

  /*------------------------------------------------------------------------------
   * UPDATE FIELDS
   *----------------------------------------------------------------------------*/
  updateFields({ state, commit, dispatch }) {
    commit('updatingState', true)
    let batch = db.batch()

    state.fields.forEach(field => {
      batch.set(field.ref, _omit(field, ['id', 'ref', 'value']))
    })

    batch.commit()
      .then(() => {
        commit('updatingState', false)
        dispatch('showSuccess', 'Fields updated', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('updatingState', false)
      })
  },

  /*------------------------------------------------------------------------------
   * DELETE FIELD
   *----------------------------------------------------------------------------*/
  async deleteField({ commit, dispatch }, field) {
    commit('deletingState', true)
    console.log(field)

    await field.ref.delete()
      .then(() => {
        commit('deletingState', false)
        commit('removeField', field)
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('deletingState', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}